import { fetchNext } from "../utils/request";
import requestTypes from "../constants/requestTypes";
import type { alarm } from "./models/alarm";

export default class AlarmApi {
  static getAlarms = (status?: string): Promise<any> => {
    const url = `/alarms${status ? `?status=${status}` : ""}`;

    return fetchNext(
      requestTypes.GET,
      url,
      undefined,
      String(process.env.REACT_APP_ALARMS_APP_API_URL)
    );
  };

  static addAlarm = (
    name: string,
    uniqueInstrumentKey: string,
    value: number,
    rangeValue: number,
    alarmType: string,
    condition: string,
    expirationDate: string
  ): Promise<{ data: alarm }> => {
    const reqBody = {
      name,
      uniqueInstrumentKey,
      value,
      rangeValue,
      alarmType,
      condition,
      expirationDate
    };

    return fetchNext(
      requestTypes.POST,
      `/alarms`,
      reqBody,
      String(process.env.REACT_APP_ALARMS_APP_API_URL)
    );
  };

  static deleteAlarm = (alarmId: number): Promise<{ data: alarm }> => {
    return fetchNext(
      requestTypes.DELETE,
      `/alarms/${alarmId}`,
      undefined,
      String(process.env.REACT_APP_ALARMS_APP_API_URL)
    );
  };

  static deleteAlarmBatch = (ids: number[]): Promise<{ data: alarm }> => {
    const reqBody = {
      alarmIds: ids
    };
    return fetchNext(
      requestTypes.POST,
      `/alarms:batchDelete`,
      reqBody,
      String(process.env.REACT_APP_ALARMS_APP_API_URL)
    );
  };

  static markAsSeenAlarm = (alarmId: number): Promise<{ data: alarm }> =>
    fetchNext(
      requestTypes.PUT,
      `/alarms/${alarmId}/mark_as_seen`,
      {},
      String(process.env.REACT_APP_ALARMS_APP_API_URL)
    );

  static updateAlarm = (
    alarmId: string,
    name: string,
    uniqueInstrumentKey: string,
    value: number,
    rangeValue: number,
    alarmType: string,
    condition: string,
    expirationDate: string
  ) => {
    const reqBody = {
      name,
      uniqueInstrumentKey,
      value,
      rangeValue,
      alarmType,
      condition,
      expirationDate
    };

    return fetchNext(
      requestTypes.PUT,
      `/alarms/${alarmId}`,
      reqBody,
      String(process.env.REACT_APP_ALARMS_APP_API_URL)
    );
  };

  static updateStatusAlarm = (alarmId: number, activate: boolean) => {
    let apiUrl = `/alarms/${alarmId}/deactivate`;
    if (activate) {
      apiUrl = `/alarms/${alarmId}/activate`;
    }
    return fetchNext(
      requestTypes.PUT,
      apiUrl,
      undefined,
      String(process.env.REACT_APP_ALARMS_APP_API_URL)
    );
  };
}
