// @flow

import ACTION_TYPES from "./actionTypes";
import type { WorkspacesReducer } from "./types";

const initialState = {
  workspaces: [],
  activeWorkspace: {
    workspaceId: "",
    layout: [],
    widgets: []
  },
  activeWorkspaceWidgets: [],
  newCard: null,
  deleteWidget: null,
  newLayout: null,
  message: "",
  typeModal: "",
  undoConfiguration: null,
  markets: [],
  predefinedTableIds: []
};

const reducer: WorkspacesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_WORKSPACES_SUCCESS:
      return {
        ...state,
        workspaces: action.workspaces
      };
    case ACTION_TYPES.SET_WORKSPACE_SUCCESS:
      return {
        ...state,
        activeWorkspace: action.activeWorkspace,
        activeWorkspaceWidgets: action.activeWorkspaceWidgets,
        message: action.message,
        typeModal: action.typeModal
      };
    case ACTION_TYPES.SET_NEW_CARD:
      return {
        ...state,
        newCard: action.newCard
      };
    case ACTION_TYPES.SET_DELETE_CARD_ID:
      return {
        ...state,
        deleteWidget: action.deleteWidget,
        newLayout: action.newLayout
      };
    case ACTION_TYPES.SET_ACTIVE_WORKSPACE_WIDGETS:
      return {
        ...state,
        activeWorkspaceWidgets: action.activeWorkspaceWidgets
      };
    case ACTION_TYPES.SET_NEW_WORKSPACE:
      return {
        ...state,
        workspaces: action.workspaces
      };
    case ACTION_TYPES.SET_UNDO_CONFIGURATION:
      return {
        ...state,
        undoConfiguration: action.undoConfiguration
      };
    case ACTION_TYPES.GET_MARKETS:
      return {
        ...state,
        markets: action.markets
      };
    case ACTION_TYPES.GET_PREDEFINED_TABLES:
      return {
        ...state,
        predefinedTableIds: action.predefinedTables
      };
    default:
      return state;
  }
};

export default reducer;
