import React, { createContext, useContext, useState, useEffect } from "react";
import AlarmApi from "apis/alarmApi";
import { ALARMS_STATUS } from "constants/globals";

type Props = {
  instrumentId: Object,
  filters: Array<string>,
  children: any
};

// $FlowFixMe
const AlarmsContext = createContext({
  allAlarms: [],
  refreshAlarms: () => {},
  updateAlarm: () => {},
  filteralarms: []
});

export const AlarmsProvider = (props: Props) => {
  const [allAlarms, setAllAlarms] = useState(null);
  const { instrumentId, filters } = props;

  const getAlarms = () => {
    AlarmApi.getAlarms().then(data => {
      setAllAlarms(data);
    });
  };

  useEffect(() => {
    if (!allAlarms) getAlarms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateAlarm = (alarmId: Number, status: string) => {
    if (allAlarms) {
      const newAlarms = [...allAlarms];
      newAlarms.forEach((alarm, index) => {
        if (alarm.alarmId === alarmId) {
          newAlarms[index].status = status;
        }
      });
      setAllAlarms(newAlarms);
    }
  };

  const filterAlarms =
    allAlarms && instrumentId && filters.length > 0
      ? // $FlowFixMe
        allAlarms.filter(
          alarm =>
            alarm.uniqueInstrumentKey === instrumentId &&
            filters.includes(alarm.status)
        )
      : allAlarms;

  const activeAlarms =
    allAlarms && allAlarms.length > 0
      ? // $FlowFixMe
        allAlarms
          .filter(alarm => alarm.status === ALARMS_STATUS.ACTIVE)
          .map(alarm => alarm.uniqueInstrumentKey)
      : [];

  return (
    <AlarmsContext.Provider
      value={{
        allAlarms,
        refreshAlarms: getAlarms,
        filterAlarms,
        updateAlarm,
        activeAlarms
      }}
    >
      {props.children}
    </AlarmsContext.Provider>
  );
};

const useAlarms = () => useContext(AlarmsContext);

export default useAlarms;
