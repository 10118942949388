import TickerApi from "apis/tickerApi";
import { HttpStatusCode, tickerSmall } from "constants/globals";
import ACTION_TYPES from "./actionTypes";
import type {
  getTickerActionFunc,
  getTickerSuccessAction,
  getTickerFailedAction,
  updateInstrumentTickerFunc
} from "./types";

const getTickerSuccess = (
  tickerId,
  size,
  hide,
  instruments
): getTickerSuccessAction => ({
  type: ACTION_TYPES.GET_TICKER_SUCCESS,
  tickerId,
  size,
  hide,
  instruments
});

const getTickerFailed = (error: string): getTickerFailedAction => ({
  type: ACTION_TYPES.GET_TICKER_FAILED,
  error
});

export const getTicker: getTickerActionFunc = () => (
  dispatch,
  getState: Function
) => {
  const ticker = getState().ticker.tickerId;
  if (ticker === "") {
    TickerApi.getTicker()
      .then(({ data }) => {
        const { tickerId, instruments } = data;
        dispatch(getTickerSuccess(tickerId, tickerSmall, false, instruments));
      })
      .catch(response => {
        if (response && response.reason && response.message) {
          if (response.status === HttpStatusCode.UnprocessableEntity) {
            TickerApi.addTicker()
              .then(({ data }) => {
                const { tickerId, size, hide, instruments } = data;
                dispatch(getTickerSuccess(tickerId, size, hide, instruments));
              })
              .catch(({ error }) => {
                if (error && response.error && response.error.message) {
                  dispatch(getTickerFailed(error.error.message));
                }
              });
          } else {
            dispatch(getTickerFailed(response.error.message));
          }
        }
      });
  }
};

export const updateInstrumentsTicker: updateInstrumentTickerFunc = (
  selectedInstruments: Array<Object>
) => async (dispatch, getState: Function) => {
  const { tickerId, size, hide } = getState().ticker;
  const newInstrumentsIds = selectedInstruments.map(
    instrument => instrument.symbolId || instrument.instrumentId
  );

  // $FlowFixMe
  const response = await TickerApi.updateInstruments(newInstrumentsIds)
    .then(({ data }) => {
      return data;
    })
    .catch(errors => {
      if (errors && errors.message) {
        dispatch(getTickerFailed(errors.message));
      }
    });
  if (response && response.length > 0) {
    dispatch(getTickerSuccess(tickerId, size, hide, response));
  }
};
