import React from "react";

import ScrollbarStyleContainer from "components/Common/ScrollbarStyleContainer";

import LayoutContainers from "./components/LayoutContainer";
import AppRouter from "./components/AppRouter";
import Header from "./components/Header";

const Layout = () => {
  return (
    <LayoutContainers>
      <Header>
        <ScrollbarStyleContainer />
        <AppRouter />
      </Header>
    </LayoutContainers>
  );
};

export default Layout;
