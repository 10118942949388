import type { ComponentType } from "react";
import styled from "styled-components";

export const Container: ComponentType<{ isModal: boolean }> = styled.div`
  background-color: ${props =>
    props.isModal
      ? props.theme.palette.WHITE_SHARK
      : props.theme.palette.SHARK_70_WHITE};
  border: ${props => (props.isModal ? "none" : "1px solid #FFFFFF")};
  width: ${props => (props.isModal ? "500px" : "466px")};
  height: ${props => (props.isModal ? "auto" : "458px")};
  z-index: 5;
  border-radius: 20px;
  position: ${props => (props.isModal ? "fixed" : "relative")};
  display: ${props => (props.isModal ? "block" : "flex")};
  left: 50%;
  top: ${props => (props.isModal ? "10px" : "-88px")};
  transform: translate(-50%, 0);
  outline: none;
  text-align: justify;
`;

export const LoaderContainer: ComponentType<{
  showLoader: boolean,
  enableResponsive?: boolean
}> = styled.div`
  background-color: ${props => props.theme.palette.SHARK_70_WHITE};
  border: ${props =>
    props.showLoader ? "none" : `1px solid ${props.theme.palette.BLACK_WHITE}`};
  width: 100%;
  z-index: 5;
  border-radius: 20px;
  position: relative;
  display: flex;
  top: 0px;
  outline: none;
  text-align: justify;
  ${props =>
    props.enableResponsive
      ? `@media only screen and (max-width: 960px) {
    width: 100%;
    top: 0px;
  }`
      : ""}
`;

export const Text: ComponentType<{
  enableResponsive?: boolean
}> = styled.p`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: ${props => props.theme.palette.SILVER_WHITE_SMOKE};
  letter-spacing: 0.25px;
  padding: 1px 18px;
  ${props =>
    props.enableResponsive
      ? `@media only screen and (max-width: 960px) {
        font-size: 11px;
        padding: 0px 18px;
        line-height: 13px;
      }`
      : ``}
`;

export const SubContainer: ComponentType<any> = styled.div`
  display: flex;
  flex-flow: row-reverse;
  height: 30px;
  & svg {
    color: ${props => props.theme.palette.BLACK_PATTENS_BLUE};
  }
`;

export const BodyContainer: ComponentType<{ showIcon: boolean }> = styled.div`
  display: grid;
  grid-template-columns: ${props => (props.showIcon ? "7% 93%" : "100%")};
`;

export const Location: ComponentType<any> = styled.img`
  display: block;
  margin: 18px 10px;
`;

export const HeaderText: ComponentType<{
  enableResponsive?: boolean
}> = styled.p`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.15px;
  color: ${props => props.theme.palette.SHARK_WHITE};
  padding-left: 18px;
  padding-right: 18px;
  ${props =>
    props.enableResponsive
      ? `@media only screen and (max-width: 960px) {
        font-size: 14px;
        width: auto;
      }`
      : ``}
`;

export const PositionUnavailableContainer: ComponentType<any> = styled.div`
  display: grid;
  grid-template-columns: 7% 84% 9%;
`;
