import React, { lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

const TerminalContainer = lazy(() => import("components/Terminal"));
const AnalisisContainer = lazy(() => import("components/Analisis"));
const PortafolioContainer = lazy(() => import("components/Portafolio"));

const ROUTERS = [
  {
    id: 1,
    exact: true,
    path: "/",
    component: TerminalContainer
  },
  {
    id: 2,
    exact: true,
    path: "/analisis",
    component: AnalisisContainer
  },
  {
    id: 3,
    exact: true,
    path: "/portafolio",
    component: PortafolioContainer
  }
];

const AppRouter = () => {
  return (
    <Switch>
      {ROUTERS.map(({ id, exact, path, component }) => (
        <Route key={`route-${id}`} {...{ exact, path, component }} />
      ))}
      <Redirect from="/terminal" to="/" />
    </Switch>
  );
};

export default AppRouter;
