import { getSelectedContract } from "utils/helpers";
import { getMarketData, fetchNext } from "../utils/request";
import type { ContractList } from "./models/contract";
import type { ContractServiceList } from "./models/contractService";
import requestTypes from "../constants/requestTypes";

export default class ContractApi {
  static getContracts = (): Promise<{ data: ContractList }> =>
    getMarketData("/contracts");

  static getContractServices = (): Promise<{ data: ContractServiceList }> =>
    getMarketData(`/contracts/${getSelectedContract()}/services`);

  static getCash = (): Promise<{ data: { cash: number } }> =>
    getMarketData(`/contracts/${getSelectedContract()}/cash`);

  static updateContract = (
    contractNumber: string,
    contractAlias: string,
    hexColor: string
  ) => {
    const reqBody = {
      contractAlias,
      hexColor
    };

    return fetchNext(
      requestTypes.PUT,
      `/contracts/${contractNumber}`,
      reqBody,
      process.env.REACT_APP_MARKET_APP_API_URL
    );
  };
}
