/* eslint-disable */
import React, { useState, useEffect, lazy, createContext, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import i18n from "i18n/index";
import type { Dispatch } from "redux";
import type { ComponentType } from "react";
import type { ReduxState } from "ducks/types";
import { getSelectedContract } from "utils/helpers";
import {
  updateConnectionStatus,
  getEquitieFee as getEquitieFeeAction,
  setCloudFlareServer as setCloudFlareServerAction,
  getCloudFlareServer as getCloudFlareServerAction,
  setImageInfo as setImageInfoAction,
  validateBusinessDay as validateBusinessDayAction
} from "ducks/account/actions";
import { getTicker as getTickerAction } from "ducks/ticker/action";
import {
  getContratedServices as getContratedServicesAction,
  getPortafolioPerformance as getPortafolioPerformanceAction,
  getPortafolioDetail as getPortafolioDetailAction,
  getContracts as getContractsAction
} from "ducks/trading/actions";
import {
  CONNECTION_STATUS,
  keyStorage,
  ErrorMessage,
  snackbarTypes,
  snackbarPositions,
  CloudFlareServers,
  lsStatus
} from "constants/globals";
import lsClient from "apis/lightstreamer/LsClient";
import history from "utils/browserHistory";
import AccountApi from "apis/accountApi";
import { getCurrentUser } from "utils/user";
import { SNACKBAR_CONNECTION_STATUS } from "constants/tests";
import GeolocationLoader from "components/Common/GeolocationLoader";
import Geolocalization from "components/Geolocalization/";
import type { ContractList } from "apis/models/contract";

const Header = lazy(() => import("components/Header"));
const Ticker = lazy(() => import("components/Ticker"));
const Notification = lazy(() =>
  import("components/Common/Modals/Notification")
);
const Snackbar = lazy(() => import("components/Common/Modals/Snackbar"));
const Alarms = lazy(() => import("components/Alarms"));
const Sidebar = lazy(() => import("components/Sidebar"));

export const ContextHeader: Object = createContext(null);

type Props = {
  connectionStatus: string,
  contractList: ContractList,
  contractError: boolean,
  contractLoading: boolean,
  getContratedServices: Function,
  getEquitieFee: Function,
  getPortafolioPerformance: Function,
  getPortafolioDetail: Function,
  instrumentSelectedOperation: Object,
  instrumentSelectedOperationType: string,
  setCloudFlareServer: Function,
  setImageInfo: Function,
  imageInfo: Object,
  getContracts: Function,
  getCloudFlareServer: Function,
  geolocalizationError: boolean,
  showGeolocationLoader: boolean,
  instrumentSelectedAlarm: string,
  instrumentSelectedDescription: string,
  validateBusinessDay: Function,
  getTicker: Function,
  sidebarSelected: string,
  children: any
};

function HeaderContainer({
  connectionStatus: statusState,
  contractList,
  contractError,
  contractLoading,
  getContratedServices,
  getEquitieFee,
  getPortafolioPerformance,
  getPortafolioDetail,
  instrumentSelectedOperation,
  instrumentSelectedOperationType,
  setCloudFlareServer,
  setImageInfo,
  imageInfo,
  getContracts,
  getCloudFlareServer,
  geolocalizationError,
  showGeolocationLoader,
  instrumentSelectedAlarm,
  instrumentSelectedDescription,
  getTicker,
  validateBusinessDay,
  sidebarSelected,
  children
}: Props) {
  const [connectionStatus, setConnectionStatus] = useState(statusState);
  const [snackbarType, setSnackbarType] = useState(snackbarTypes.ERROR);
  const [statusMessage, setStatusMessage] = useState("");
  const [selectedContract, setSelectedContract] = useState(() =>
    getSelectedContract()
  );
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [enableManu, setEnableManu] = useState(false);
  const intervalID = useRef(null);
  const timeoutID = useRef(null);
  const enableMenu = useRef(null);
  const intervalHAID = useRef(null);

  const checkHAService = () => {
    if (process.env.REACT_APP_ENVIRONMENT === "Prod") {
      AccountApi.checkHAService()
        .then(data => {
          if (getCloudFlareServer() === CloudFlareServers.interlomas) {
            // $FlowFixMe
            if (!data.data.enabled) {
              window.location.reload();
            }
          }
          if (getCloudFlareServer() === CloudFlareServers.monterrey) {
            // $FlowFixMe
            if (data.data.enabled) {
              window.location.reload();
            }
          }
        })
        .catch(() => {});
    }
  };

  const refreshToken = () => {
    AccountApi.refreshToken()
      .then(() => {})
      .catch(() => {
        history.push("/logout");
      });
  };

  useEffect(() => {
    let user = getCurrentUser();
    if (process.env.REACT_APP_ENVIRONMENT === "Prod") {
      AccountApi.checkHAService()
        .then(data => {
          // $FlowFixMe
          if (data.data.enabled) {
            setCloudFlareServer(CloudFlareServers.interlomas);
          } else {
            setCloudFlareServer(CloudFlareServers.monterrey);
          }
        })
        .catch(() => {});
    }

    if (!user) {
      if (!window.location.href.includes("logout")) {
        history.push("/login");
      }
      return;
    }

    if (!imageInfo) {
      AccountApi.getImage()
        .then(({ data }) => {
          if (data && data.length > 0) {
            const ImageInfoSplit = data.split(".");
            const newImageInfo = {
              BT_IMAGE: ImageInfoSplit[0],
              STYLE_PATH: ImageInfoSplit[1],
              LOGO_URL: ImageInfoSplit[2]
            };
            setImageInfo(newImageInfo);
          }
        })
        .catch(() => {});
    }

    getContracts(() => {
      getContratedServices();
      getEquitieFee();
    });

    getTicker();
    validateBusinessDay();

    const client = lsClient();
    if (client) {
      client.addListener({
        onStatusChange: function onStatusChange(status) {
          switch (status) {
            case lsStatus.CONNECTED_WS_STREAMING:
            case lsStatus.CONNECTED_STREAM_SENSING:
              setSnackbarType(snackbarTypes.ERROR);
              setStatusMessage(i18n.t(""));
              setConnectionStatus(CONNECTION_STATUS.ONLINE);
              updateConnectionStatus(CONNECTION_STATUS.ONLINE);
              break;
            case lsStatus.DISCONNECTED:
              setSnackbarType(snackbarTypes.ERROR);
              setStatusMessage(i18n.t("noConnection"));
              setConnectionStatus(CONNECTION_STATUS.OFFLINE);
              user = getCurrentUser();
              if (user) {
                client.connectionDetails.setPassword(user.accessToken);
                client.connect();
              }
              updateConnectionStatus(CONNECTION_STATUS.OFFLINE);
              break;
            case lsStatus.DISCONNECTED_WILL_RETRY:
              setSnackbarType(snackbarTypes.ERROR);
              setStatusMessage(i18n.t("noConnection"));
              setConnectionStatus(CONNECTION_STATUS.OFFLINE);
              updateConnectionStatus(CONNECTION_STATUS.OFFLINE);
              break;
            default:
              break;
          }
        }
      });
    }

    timeoutID.current = setTimeout(() => {
      refreshToken();
    }, 10000);

    intervalID.current = setInterval(() => {
      refreshToken();
    }, parseFloat(process.env.REACT_APP_TIME_REFRESH_TOKEN));

    enableMenu.current = setTimeout(() => {
      setEnableManu(true);
    }, 2000);

    intervalHAID.current = setInterval(() => {
      checkHAService();
    }, parseFloat(process.env.REACT_APP_CLOUDFLARE_TIME));

    /* eslint-disable-line */
    return () => {
      if (intervalHAID.current) {
        clearInterval(intervalHAID.current);
      }
      if (timeoutID.current) {
        clearTimeout(timeoutID.current);
      }
      if (intervalID.current) {
        clearInterval(intervalID.current);
      }
      if (enableMenu.current) {
        clearTimeout(enableMenu.current);
      }
    };
    /* eslint-disable-line */
  }, []);

  const updateDetail = () => {
    getPortafolioDetail(true);
  };

  const selectContract = (contractNumber: string) => {
    if (selectedContract !== contractNumber) {
      setSelectedContract(contractNumber);
      sessionStorage.setItem(keyStorage.selectedContract, contractNumber);

      // only portafolio
      if (window.location.href.includes("portafolio")) {
        updateDetail();
        getPortafolioPerformance(true);
      }
      // only portafolio

      getContratedServices(true);
      getEquitieFee(true);
    }
  };

  const handleNotification = (message: string, type: string) => {
    if (message === ErrorMessage.NoConection || type === snackbarTypes.ERROR) {
      setNotification({
        message,
        type: snackbarTypes.ERROR
      });
      return;
    }
    setNotification({ message, type });
  };

  const user = getCurrentUser();

  return (
    <ContextHeader.Provider value={{ handleNotification }}>
      <Header
        connectionStatus={connectionStatus}
        contractList={contractList}
        contractError={contractError}
        contractLoading={contractLoading}
        selectContract={selectContract}
        selectedContract={selectedContract}
        changeMenuComposicion={() => {}}
        instrumentSelectedOperation={instrumentSelectedOperation}
        instrumentSelectedOperationType={instrumentSelectedOperationType}
        handleNotification={handleNotification}
        enableManu={enableManu}
      />
      <Sidebar
        sidebarType={sidebarSelected}
        changeMenuComposicion={() => {}}
        instrumentSelectedOperation={instrumentSelectedOperation}
        instrumentSelectedOperationType={instrumentSelectedOperationType}
        contractError={contractError}
        contractLoading={contractLoading}
        handleNotification={handleNotification}
        setStatusMessage={setStatusMessage}
        setSnackbarType={setSnackbarType}
      />
      <Snackbar
        type={snackbarType}
        message={statusMessage}
        handleClose={() => setStatusMessage("")}
        data-testid={SNACKBAR_CONNECTION_STATUS}
      />
      <Notification
        type={notification.type}
        message={notification.message}
        handleClose={() => setNotification({ message: "", type: "" })}
        widthMessage={100}
        position={snackbarPositions.TOP}
        hideUndo={true}
        handleUnDo={() => setNotification({ message: "", type: "" })}
      />
      <Geolocalization user={user} />
      {instrumentSelectedAlarm && (
        <Alarms
          instrumentId={instrumentSelectedAlarm}
          description={instrumentSelectedDescription}
          handleNotification={handleNotification}
        />
      )}
      <Ticker handleNotification={handleNotification} />
      {showGeolocationLoader && (
        <GeolocationLoader
          isGetGeolocation={geolocalizationError}
          isModal={true}
        />
      )}
      {children}
    </ContextHeader.Provider>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  connectionStatus: state.account.connectionStatus,
  contractList: state.trading.contractList,
  contractError: state.trading.contractError,
  contractLoading: state.trading.contractLoading,
  instrumentSelectedOperation:
    state.instrumentDetails.instrumentSelectedOperation,
  instrumentSelectedOperationType:
    state.instrumentDetails.instrumentSelectedOperationType,
  imageInfo: state.account.imageInfo,
  showGeolocationLoader: state.account.showGeolocationLoader,
  geolocalizationError: state.account.geolocalizationError,
  instrumentSelectedAlarm: state.instrumentDetails.instrumentSelectedAlarm,
  instrumentSelectedDescription:
    state.instrumentDetails.instrumentSelectedDescription,
  sidebarSelected: state.account.sidebarSelected
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return bindActionCreators(
    {
      getTicker: getTickerAction,
      validateBusinessDay: validateBusinessDayAction,
      updateConnectionStatus,
      getContratedServices: getContratedServicesAction,
      getEquitieFee: getEquitieFeeAction,
      getPortafolioPerformance: getPortafolioPerformanceAction,
      getPortafolioDetail: getPortafolioDetailAction,
      setCloudFlareServer: setCloudFlareServerAction,
      setImageInfo: setImageInfoAction,
      getContracts: getContractsAction,
      getCloudFlareServer: getCloudFlareServerAction
    },
    dispatch
  );
};

const HeaderConnected: ComponentType<*> = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderContainer);

export default HeaderConnected;
