// @flow

import ACTION_TYPES from "./actionTypes";
import type { NewsDetailsReducer } from "./types";

const initialState = {
  isLoading: false,
  selectedNews: 0,
  newsDetails: {},
  newsFile: {},
  error: ""
};

const reducer: NewsDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.START_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case ACTION_TYPES.STOP_LOADING:
      return {
        ...state,
        isLoading: false
      };
    case ACTION_TYPES.SET_NEWS_DETAILS_SUCCESS:
      return {
        ...state,
        selectedNews: action.selectedNews
      };
    case ACTION_TYPES.GET_NEWS_DETAILS_SUCCESS:
      return {
        ...state,
        newsDetails: action.newsDetails,
        newsFile: action.newsFile
      };
    case ACTION_TYPES.GET_NEWS_DETAILS_FAILED:
      return {
        ...state,
        error: action.errors
      };
    default:
      return state;
  }
};

export default reducer;
