import React, { createContext, useContext, useEffect, useState } from "react";
import { defaultSettings } from "constants/globals";

type Props = {
  settings: Object,
  children: any
};

// $FlowFixMe
const SettingsContext = createContext({
  settings: defaultSettings,
  saveSettings: () => {}
});

export const SettingsProvider = (props: Props) => {
  const [settings, setSettings] = useState(props.settings || defaultSettings);

  const saveSettings = (newSettings: Object) => {
    localStorage.setItem("settings", JSON.stringify(newSettings));
    setSettings(newSettings);
  };

  const getSettings = () => {
    const currentSettings = localStorage.getItem("settings");
    if (currentSettings) return JSON.parse(currentSettings);
    return currentSettings;
  };

  useEffect(() => {
    const currentSettings = getSettings();
    if (currentSettings) setSettings(currentSettings);
  }, []);

  return (
    <SettingsContext.Provider
      value={{
        settings,
        saveSettings
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
};

const useSettings = () => useContext(SettingsContext);

export default useSettings;
