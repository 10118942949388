import ACTION_TYPES from "./actionTypes";
import type { TickerReducer } from "./types";

const initialState = {
  tickerId: "",
  size: "",
  hide: false,
  instruments: [],
  error: ""
};

const reducer: TickerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_TICKER_SUCCESS:
      return {
        ...state,
        tickerId: action.tickerId,
        size: action.size,
        hide: action.hide,
        instruments: action.instruments
      };
    case ACTION_TYPES.GET_TICKER_FAILED:
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export default reducer;
