import { getSelectedContract } from "utils/helpers";
import { fetchNext, getManagementData } from "../utils/request";
import requestTypes from "../constants/requestTypes";

export default class ContractApi {
  static getEquitiesFee = () => {
    return fetchNext(
      requestTypes.GET,
      `/order-management/contracts/${getSelectedContract()}/equities-fee`,
      {},
      String(process.env.REACT_APP_MANAGEMENT_APP_API_URL)
    );
  };

  static sendOrder = (payload: string) => {
    const reqBody = {
      payload
    };
    return fetchNext(
      requestTypes.POST,
      `/order-management/contracts/${getSelectedContract()}/orders`,
      reqBody,
      String(process.env.REACT_APP_MANAGEMENT_APP_API_URL)
    );
  };

  static sendFundOrder = (payload: string) => {
    const reqBody = {
      payload
    };
    return fetchNext(
      requestTypes.POST,
      `/order-management/contracts/${getSelectedContract()}/funds`,
      reqBody,
      String(process.env.REACT_APP_MANAGEMENT_APP_API_URL)
    );
  };

  static getFundListForSell = () => {
    return fetchNext(
      requestTypes.GET,
      `/order-management/contracts/${getSelectedContract()}/funds`,
      {},
      String(process.env.REACT_APP_MANAGEMENT_APP_API_URL)
    );
  };

  static getFundList = (Amount: number) => {
    return fetchNext(
      requestTypes.GET,
      `/order-management/contracts/${getSelectedContract()}/dynamic-funds?amount=${Amount}`,
      {},
      String(process.env.REACT_APP_MANAGEMENT_APP_API_URL)
    );
  };

  static getBusinessDays = (
    startDate: string,
    endDate: string
  ): Promise<{ data: Array<any> }> =>
    getManagementData(
      requestTypes.GET,
      `/order-management/non-working-days?startDate=${startDate}&endDate=${endDate}`
    );

  static validateBusinessDay = (): Promise<{
    data: { businessDay: boolean }
  }> => getManagementData(requestTypes.GET, `/order-management/business-day`);

  static getGeolocalization = () =>
    getManagementData(requestTypes.GET, `/order-management/geolocation/terms`);

  static confirmGeolocalization = (
    latitude: number,
    longitude: number
  ): Promise<{
    data: { response: boolean }
  }> => {
    const reqBody = {
      data: {
        device: "WEB",
        indGeolocalizado: true,
        gmsCoordinates: {
          latitude,
          longitude
        }
      }
    };
    return fetchNext(
      requestTypes.POST,
      `/order-management/geolocation/terms`,
      reqBody,
      String(process.env.REACT_APP_MANAGEMENT_APP_API_URL)
    );
  };
}
