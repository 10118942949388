import i18n from "i18n/index";

import SortDesIcon from "assets/icons/sortDes.svg";
import SortAscIcon from "assets/icons/sortAsc.svg";
import SortEmpty from "assets/icons/sortEmpty.svg";
import {
  ANZAC,
  GREEN_LIGHT,
  PINK_LINEAR_GRADIENT,
  PRAIRIE_SAND,
  TRADEWIND
} from "./colors";

export const LOGO_TEXT = "Infosel";
export const LOGO_HUB_TEXT = "HUB";

export const WIDTH_QUICK_MENU = 300;

export const WELCOME_PAGE_TEXT = "Infosel";

export const twentyMinutes = 1200000;

export const cardTypes = {
  TABLE: 1,
  CHART: 2,
  NEWS: 3,
  PREDEFINED_TABLE: 4,
  GEO_HEATMAP: 5,
  TREEMAP: 6,
  ECONOMIC_CALENDAR: 7
};

export const HttpStatusCode = {
  OK: 200,
  MultipleChoices: 300,
  BadRequest: 400,
  Unauthorized: 401,
  NotFound: 404,
  InternalServerError: 500,
  UnprocessableEntity: 422
};

export const HttpReadyState = {
  UNSENT: 0,
  OPENED: 1,
  HEADERS_RECEIVED: 2,
  LOADING: 3,
  DONE: 4
};

export const ErrorMessage = {
  Default: "Error al procesar. Inténtalo de nuevo.",
  [HttpStatusCode.NotFound]: "[404] Esta no es la página web que busca.",
  [HttpStatusCode.InternalServerError]:
    "[500] Perdón, algo salió mal. Vuelve a intentarlo o actualiza la página.",
  ParsingFailed: "Error al analizar.",
  TokenExpired: "El token ha caducado.",
  NoConection: "Sin conexión"
};

export const LsSubscriptionMode = {
  MERGE: "MERGE",
  DISTINCT: "DISTINCT",
  RAW: "RAW",
  COMMAND: "COMMAND"
};

export const EncodedCharacters = {
  ASTERISK: "K__42__",
  AMPERSAND: "K__38__",
  DASH: "K__45__",
  SPACE: "K__32__"
};

export const LsSubscriptionTableFields = {
  CURRENT_PRICE: "PrecioAcc",
  PREVIOUS_PRICE: "PrecioUHA",
  CHANGE_PROCENTAGE: "VariacionPorcentualAcc",
  CHANGE_UNIT: "VariacionUnitaria",
  VOLUMEN_TOTAL: "VolumenTotal",
  VOLUMEN_DONE: "Volumen",
  PURCHASE_VALUE: "CompraPostura",
  PURCHASE: "Compra",
  PURCHASE_VOLUME: "VolCompraPostura",
  SALE_VALUE: "VentaPostura",
  SALE: "Venta",
  SALE_VOLUME: "VolVentaPostura",
  HOUR: "Hora",
  MAXIMUM: "PrecioMaxDia",
  MINIMUM: "PrecioMinDia",
  TOTAL_AMOUNT: "ImporteTotal",
  TOTAL_AMOUNT_INDEX: "ImporteTotal",
  HOUR_INDEX: "Hora",
  DATE_INDEX: "Fecha",
  PREVIOUS_DATE_INDEX: "FechaIndiceAnterior",
  PREVIOUS_PRICE_INDEX: "Cierre",
  OPENING_PRICE: "PrecioApertura",
  SAMPLE_ID: "Muestra",
  DATE: "Fecha",
  DATESTRING: "Stringfecha",
  CURRENT: "Actual",
  PREVIOUS: "Anterior",
  CHANGE: "Variacion",
  PERIODICITY: "Periodicidad",
  PRICE: "Precio",
  CHANGE_PERCENTAGE: "VariacionPorcentual",
  DATE_UHA: "FechaPrecioUHA",
  AMOUNT: "Importe",
  NUMBER_OPERATION: "NumOperaciones",
  SERIES_TRANSMITER: "EmisoraSerie",
  NUM_OF_OPERATIONS: "NumOperaciones",
  PEAK_AMOUNT: "Importe",
  SELLER: "Vendedor",
  BUYER: "Comprador",
  LAST_TIME: "HoraUltimo",
  LAST_DATE: "FechaUltimo",
  FIXED_INCOME_TYPE_VALUE: "DeudaTipoValor",
  FIXED_INCOME_KEY: "DeudaClaveCotizacion",
  FIXED_INCOME_SERIE: "DeudaSerie",
  FIXED_INCOME_DATE: "DeudaFechaEmision",
  FIXED_INCOME_CLEAN_PRICE: "DeudaPrecioLimpio",
  FIXED_INCOME_DIRTY_PRICE: "DeudaPrecioSucio",
  FIXED_INCOME_DAYS_TO_WIN: "DeudaDiasPorVencer",
  FIXED_INCOME_HOUR: "DeudaHoraUltimoHecho",
  FIXED_INCOME_EXPIRATION_DATE: "DeudaFechaVencimiento",
  FIXED_INCOME_PREVIOUS_PRICE: "DeudaCierreAnterior",
  FIXED_INCOME_LAST_DONE: "DeudaUltimoHecho",
  FIXED_INCOME_PORCENTUAL_VARIATION: "DeudaVariacionPorcentual",
  FIXED_INCOME_UNIT_VARIATION: "DeudaVariacionUnitaria",
  FIXED_INCOME_INTEREST_24: "DeudaInteres24H",
  FIXED_INCOME_SCORE_FITCH: "DeudaCalificacionFitch",
  FIXED_INCOME_SCORE_MOODY: "DeudaCalificacionMoody",
  FIXED_INCOME_SCORE_SP: "DeudaCalificacionSP",
  FIXED_INCOME_SCORE_HR: "DeudaCalificacionHR",
  FIXED_INCOME_DAY_MAX: "DeudaMaxDia",
  FIXED_INCOME_DAY_MIN: "DeudaMinDia",
  FIXED_INCOME_PURCHASE_PRICE: "DeudaPrecioCompra",
  FIXED_INCOME_SALE_PRICE: "DeudaPrecioVenta",
  FIXED_INCOME_OPENING_PRICE: "DeudaPrecioApertura",
  FIXED_INCOME_YIELD_RATE: "DeudaTasaRendimiento",
  ISIN: "isin",
  DAYS_TO_EXPIRE: "fixedIncomeDaysToExpire",
  RELEASE_DATE: "fixedIncomeReleaseDate",
  CLEAN_PRICE: "fixedIncomeCleanPrice",
  MD_DIRTY_PRICE: "fixedIncomeMDDirtyPrice",
  MD_CLEAN_PRICE: "fixedIncomeMDCleanPrice",
  DIRTY_PRICE: "fixedIncomeMDDirtyPrice",
  SURTAX_PIP: "fixedIncomeSurtax",
  NYSE_PURCHASE_VALUE: "PosturaCompra_Precio",
  NYSE_PURCHASE_VOLUME: "PosturaCompra_Volumen",
  NYSE_SALE_VALUE: "PosturaVenta_Precio",
  NYSE_SALE_VOLUME: "PosturaVenta_Volumen",
  NYSE_SALE_DATE: "PosturaVenta_Fecha",
  NYSE_SALE_TIME: "PosturaVenta_Fecha",
  NYSE_PURCHASE_DATE: "PosturaCompra_Fecha",
  NYSE_PURCHASE_TIME: "PosturaCompra_Hora",
  NYSE_PURCHASE_STOCK: "PosturaCompra_Bolsa",
  NYSE_SALE_STOCK: "PosturaCompra_Hora",
  NYSE_STOCK_VALUE: "Hecho_Fecha",
  NYSE_MIN_PRICE: "PrecioMinimo",
  NYSE_MAX_PRICE: "PrecioMaximo",
  ACTUAL_VALUE_PPP: "ValorActualPPP",
  IS_USED_FOR_GRAPH: "Graficar",
  FULL_HOUR: "HoraCompleta",
  TIPO_PAQUETE: "TipoPaquete",
  PICO_LOTE: "PicoLote",
  MOVIMIENTO: "Movimiento",
  FUENTE_ULTIMO: "FuenteUltimo",
  LIQUIDATION: "Liquidacion",
  FOLIOBMV: "NumOperaciones",
  LAST_VOLUMEN: "VolumenUltimo",
  SPREAD: "Spread",
  SPREAD_PERCENTAGE: "SpreadPorcentaje"
};

export const LsSubscriptionStocksFields = [
  LsSubscriptionTableFields.CURRENT_PRICE,
  LsSubscriptionTableFields.CHANGE_PROCENTAGE,
  LsSubscriptionTableFields.CHANGE_UNIT,
  LsSubscriptionTableFields.VOLUMEN_DONE,
  LsSubscriptionTableFields.PURCHASE_VALUE,
  LsSubscriptionTableFields.PURCHASE_VOLUME,
  LsSubscriptionTableFields.SALE_VALUE,
  LsSubscriptionTableFields.SALE_VOLUME,
  LsSubscriptionTableFields.HOUR,
  LsSubscriptionTableFields.IS_USED_FOR_GRAPH,
  LsSubscriptionTableFields.DATE,
  LsSubscriptionTableFields.FULL_HOUR,
  LsSubscriptionTableFields.OPENING_PRICE,
  LsSubscriptionTableFields.PREVIOUS_PRICE,
  LsSubscriptionTableFields.PICO_LOTE,
  LsSubscriptionTableFields.TIPO_PAQUETE,
  LsSubscriptionTableFields.MOVIMIENTO,
  LsSubscriptionTableFields.NUM_OF_OPERATIONS,
  LsSubscriptionTableFields.MAXIMUM,
  LsSubscriptionTableFields.MINIMUM,
  LsSubscriptionTableFields.MINIMUM,
  LsSubscriptionTableFields.FUENTE_ULTIMO,
  LsSubscriptionTableFields.SPREAD,
  LsSubscriptionTableFields.SPREAD_PERCENTAGE
];

export const LsSubscriptionCurrenciesFields = [
  LsSubscriptionTableFields.CURRENT_PRICE,
  LsSubscriptionTableFields.CHANGE_PROCENTAGE,
  LsSubscriptionTableFields.CHANGE_UNIT,
  LsSubscriptionTableFields.PURCHASE,
  LsSubscriptionTableFields.SALE,
  LsSubscriptionTableFields.HOUR,
  LsSubscriptionTableFields.DATE,
  LsSubscriptionTableFields.MINIMUM,
  LsSubscriptionTableFields.MAXIMUM,
  LsSubscriptionTableFields.IS_USED_FOR_GRAPH
];

export const LsSubscriptionIndexesFields = [
  LsSubscriptionTableFields.CURRENT_PRICE,
  LsSubscriptionTableFields.CHANGE_PROCENTAGE,
  LsSubscriptionTableFields.VOLUMEN_DONE,
  LsSubscriptionTableFields.VOLUMEN_TOTAL,
  LsSubscriptionTableFields.HOUR,
  LsSubscriptionTableFields.MINIMUM,
  LsSubscriptionTableFields.MAXIMUM,
  LsSubscriptionTableFields.PREVIOUS_PRICE_INDEX,
  LsSubscriptionTableFields.TOTAL_AMOUNT,
  LsSubscriptionTableFields.DATE_INDEX,
  LsSubscriptionTableFields.HOUR_INDEX,
  LsSubscriptionTableFields.PREVIOUS_DATE_INDEX,
  LsSubscriptionTableFields.OPENING_PRICE,
  LsSubscriptionTableFields.CHANGE_UNIT,
  LsSubscriptionTableFields.IS_USED_FOR_GRAPH
];

export const LsSubscriptionGeneralIndicatorsFields = [
  LsSubscriptionTableFields.SAMPLE_ID,
  LsSubscriptionTableFields.DATE,
  LsSubscriptionTableFields.HOUR,
  LsSubscriptionTableFields.CURRENT,
  LsSubscriptionTableFields.PREVIOUS,
  LsSubscriptionTableFields.CHANGE,
  LsSubscriptionTableFields.PERIODICITY,
  LsSubscriptionTableFields.CHANGE_PROCENTAGE,
  LsSubscriptionTableFields.IS_USED_FOR_GRAPH
];

export const LsSubscriptionEquityBivaFields = [
  LsSubscriptionTableFields.PREVIOUS_PRICE,
  LsSubscriptionTableFields.PRICE,
  LsSubscriptionTableFields.VOLUMEN_DONE,
  LsSubscriptionTableFields.CHANGE_UNIT,
  LsSubscriptionTableFields.CHANGE_PERCENTAGE,
  LsSubscriptionTableFields.HOUR,
  LsSubscriptionTableFields.DATE,
  LsSubscriptionTableFields.DATE_UHA,
  LsSubscriptionTableFields.PURCHASE_VALUE,
  LsSubscriptionTableFields.PURCHASE_VOLUME,
  LsSubscriptionTableFields.SALE_VALUE,
  LsSubscriptionTableFields.SALE_VOLUME,
  LsSubscriptionTableFields.IS_USED_FOR_GRAPH,
  LsSubscriptionTableFields.FULL_HOUR,
  LsSubscriptionTableFields.OPENING_PRICE,
  LsSubscriptionTableFields.PREVIOUS_PRICE,
  LsSubscriptionTableFields.PICO_LOTE
];

export const LsSubscriptionBmvEquitySicFields = [
  LsSubscriptionTableFields.CURRENT_PRICE,
  LsSubscriptionTableFields.VOLUMEN_DONE,
  LsSubscriptionTableFields.CHANGE_UNIT,
  LsSubscriptionTableFields.CHANGE_PROCENTAGE,
  LsSubscriptionTableFields.HOUR,
  LsSubscriptionTableFields.PURCHASE_VALUE,
  LsSubscriptionTableFields.PURCHASE_VOLUME,
  LsSubscriptionTableFields.SALE_VALUE,
  LsSubscriptionTableFields.SALE_VOLUME,
  LsSubscriptionTableFields.IS_USED_FOR_GRAPH,
  LsSubscriptionTableFields.DATE,
  LsSubscriptionTableFields.FULL_HOUR,
  LsSubscriptionTableFields.OPENING_PRICE,
  LsSubscriptionTableFields.PREVIOUS_PRICE,
  LsSubscriptionTableFields.PICO_LOTE,
  LsSubscriptionTableFields.MAXIMUM,
  LsSubscriptionTableFields.MINIMUM,
  LsSubscriptionTableFields.SPREAD,
  LsSubscriptionTableFields.SPREAD_PERCENTAGE
];

export const LsSubscriptionSICBIVAFields = [
  LsSubscriptionTableFields.PREVIOUS_PRICE,
  LsSubscriptionTableFields.PRICE,
  LsSubscriptionTableFields.VOLUMEN_DONE,
  LsSubscriptionTableFields.CHANGE_UNIT,
  LsSubscriptionTableFields.CHANGE_PERCENTAGE,
  LsSubscriptionTableFields.VOLUMEN_TOTAL,
  LsSubscriptionTableFields.HOUR,
  LsSubscriptionTableFields.DATE,
  LsSubscriptionTableFields.DATE_UHA,
  LsSubscriptionTableFields.AMOUNT,
  LsSubscriptionTableFields.NUMBER_OPERATION,
  LsSubscriptionTableFields.PURCHASE_VALUE,
  LsSubscriptionTableFields.PURCHASE_VOLUME,
  LsSubscriptionTableFields.SALE_VALUE,
  LsSubscriptionTableFields.SALE_VOLUME,
  LsSubscriptionTableFields.IS_USED_FOR_GRAPH,
  LsSubscriptionTableFields.FULL_HOUR,
  LsSubscriptionTableFields.OPENING_PRICE,
  LsSubscriptionTableFields.PREVIOUS_PRICE,
  LsSubscriptionTableFields.PICO_LOTE
];

export const LsSubscriptionFixedIncomeFields = [
  LsSubscriptionTableFields.IS_USED_FOR_GRAPH,
  LsSubscriptionTableFields.CURRENT_PRICE,
  LsSubscriptionTableFields.PRICE,
  LsSubscriptionTableFields.AMOUNT,
  LsSubscriptionTableFields.CHANGE_UNIT,
  LsSubscriptionTableFields.ACTUAL_VALUE_PPP,
  LsSubscriptionTableFields.MAXIMUM,
  LsSubscriptionTableFields.NUMBER_OPERATION,
  LsSubscriptionTableFields.SAMPLE_ID,
  LsSubscriptionTableFields.HOUR,
  LsSubscriptionTableFields.LAST_TIME,
  LsSubscriptionTableFields.LAST_DATE,
  LsSubscriptionTableFields.PREVIOUS_PRICE,
  LsSubscriptionTableFields.TOTAL_AMOUNT,
  LsSubscriptionTableFields.PICO_LOTE,
  LsSubscriptionTableFields.BUYER,
  LsSubscriptionTableFields.OPENING_PRICE,
  LsSubscriptionTableFields.MINIMUM,
  LsSubscriptionTableFields.VOLUMEN_DONE,
  LsSubscriptionTableFields.DATE,
  LsSubscriptionTableFields.SELLER,
  LsSubscriptionTableFields.CHANGE_PERCENTAGE,
  LsSubscriptionTableFields.VOLUMEN_TOTAL,
  LsSubscriptionTableFields.FULL_HOUR,
  LsSubscriptionTableFields.CHANGE_PROCENTAGE
];

export const LsSubscriptionNyseNasdaqFields = [
  LsSubscriptionTableFields.PREVIOUS_PRICE,
  LsSubscriptionTableFields.OPENING_PRICE,
  LsSubscriptionTableFields.PRICE,
  LsSubscriptionTableFields.CHANGE_UNIT,
  LsSubscriptionTableFields.CHANGE_PERCENTAGE,
  LsSubscriptionTableFields.VOLUMEN_TOTAL,
  LsSubscriptionTableFields.HOUR,
  LsSubscriptionTableFields.NYSE_PURCHASE_VALUE,
  LsSubscriptionTableFields.NYSE_PURCHASE_VOLUME,
  LsSubscriptionTableFields.NYSE_SALE_VALUE,
  LsSubscriptionTableFields.NYSE_SALE_VOLUME,
  LsSubscriptionTableFields.NYSE_SALE_DATE,
  LsSubscriptionTableFields.NYSE_SALE_TIME,
  LsSubscriptionTableFields.NYSE_PURCHASE_DATE,
  LsSubscriptionTableFields.NYSE_PURCHASE_STOCK,
  LsSubscriptionTableFields.NYSE_STOCK_VALUE,
  LsSubscriptionTableFields.NYSE_MIN_PRICE,
  LsSubscriptionTableFields.NYSE_MAX_PRICE,
  LsSubscriptionTableFields.HOUR,
  LsSubscriptionTableFields.IS_USED_FOR_GRAPH
];

export const LsSubscriptionDetailBmvFields = [
  LsSubscriptionTableFields.CURRENT_PRICE,
  LsSubscriptionTableFields.VOLUMEN_TOTAL,
  LsSubscriptionTableFields.CHANGE_PROCENTAGE,
  LsSubscriptionTableFields.CHANGE_UNIT,
  LsSubscriptionTableFields.IS_USED_FOR_GRAPH
];

export const LsSubscriptionFavoriteFields = [
  LsSubscriptionTableFields.CURRENT_PRICE,
  LsSubscriptionTableFields.CHANGE_PROCENTAGE,
  LsSubscriptionTableFields.CHANGE_UNIT,
  LsSubscriptionTableFields.IS_USED_FOR_GRAPH
];

export const LsSubscriptionIntradayFields = [
  LsSubscriptionTableFields.LAST_TIME,
  LsSubscriptionTableFields.MINIMUM,
  LsSubscriptionTableFields.MAXIMUM,
  LsSubscriptionTableFields.SELLER,
  LsSubscriptionTableFields.BUYER,
  LsSubscriptionTableFields.CURRENT_PRICE,
  LsSubscriptionTableFields.LIQUIDATION,
  LsSubscriptionTableFields.FOLIOBMV,
  LsSubscriptionTableFields.MOVIMIENTO,
  LsSubscriptionTableFields.PICO_LOTE,
  LsSubscriptionTableFields.NUMBER_OPERATION,
  LsSubscriptionTableFields.LAST_DATE,
  LsSubscriptionTableFields.VOLUMEN_DONE,
  LsSubscriptionTableFields.HOUR,
  LsSubscriptionTableFields.FULL_HOUR
];

export const LsSubscriptionChartFields = {
  PercentageChange: "VariacionPorcentualAcc",
  AbsoluteChange: "VariacionUnitaria",
  Time: "Hora",
  PriceAcc: "PrecioAcc", // Close
  Volume: "Volumen",
  Open: "PrecioUHA",
  High: "PrecioMaxDia",
  Low: "PrecioMinDia",
  TotalVolume: "VolumenTotal",
  IsUsedForGraph: "Graficar"
};

export const LsSubscriptionNewsFields = {
  Headline: "Encabezado", // or EncabezadoLargo
  Priority: "Prioridad",
  Time: "Hora",
  NewsId: "NumNota",
  ServiceId: "Servicio",
  Date: "Fecha"
};

export const LsSubscriptionTickerFields = {
  CURRENT_PRICE: "PrecioAcc",
  CHANGE_PROCENTAGE: "VariacionPorcentualAcc",
  CHANGE: "VariacionUnitaria",
  PICO_LOTE: "PicoLote"
};

export const NewsFields = {
  header: "header",
  priority: "priority",
  time: "time",
  newsId: "newsId",
  serviceId: "serviceId"
};

export const PriceChangeApiFields = {
  price: "precioActual",
  procentageChange: "porcentaje",
  absoluteChange: "variacion",
  description: "descripcionInstrumento"
};

export const ComparisonApiFields = {
  price: "precioActual",
  procentageChange: "porcentaje",
  descripcion: "descripcion"
};

export const InstrumentPrefixes = {
  BmvStockInstrumentPrefixForApi: "4/12576/2/",
  BmvStockInstrumentPrefixForLS: "4.12576.2.",
  BmvEquitySicInstrumentPrefixForApi: "1/12609/0/",
  BmvEquitySicInstrumentPrefixForLS: "1.12609.0.",
  CurrencyInstrumentPrefixForApi: "4096/50/25/",
  CurrencyInstrumentPrefixForLS: "4096.50.25.",
  SICBIVAInstrumentPrefixForLS: "2.20.1.",
  SICBIVAInstrumentPrefixForApi: "2/20/1/",
  GeneralIndicatorsInstrumentPrefixForLS: "32768.1.1000.",
  IndexInstrumentPrefixForApi: "64/1/0/",
  IndexInstrumentPrefixForLS: "64.1.0.",
  NyseNasdaqInstrumentPrefixForApi: "32/2/21/",
  NyseNasdaqInstrumentPrefixForLS: "32.2.21.",
  InstrumentPrefixForLSPositionFields: "P."
};

export const IntervalConstants = {
  day: "D",
  week: "W",
  month: "M",
  quarter: "Q",
  year: "Y"
};

export const MEXICAN_CURRENCY = "MXN";
export const CDT_TIMEZONE = "CDT";
export const MEXICAN_LOCALE = "es-MX";
export const USA_LOCALE = "en-US";
export const DATE_FORMAT = "DD/MM/YYYY";
export const DATE_FORMAT_PICKER = "dd/MM/yyyy";
export const DATE_FORMAT_INITIAL = "DD-MM-YYYY";
export const DATE_FORMAT_INITIAL_ALARM = "YYYY-MM-DD";
export const HOUR_FORMAT = "HH:mm:ss";
export const TICKER_SPEED = 8;
// WORKSPACE_OFFSET and SIDEBAR_WIDTH (height/width of header and footer elements)
// are subtracted from terminal height/width to get actual size of workspace
export const SIDEBAR_WIDTH = 400;
export const WORKSPACE_HEIGHT_OFFSET = 173;
export const WORKSPACE_HEIGHT_IPAD_OFFSET = 238;
export const WORKSPACE_HEIGHT_TABLET_OFFSET = 191;
export const WORKSPACE_HEIGHT_TABLET_IPAD_OFFSET = 250;
export const CHART_FOOTER_HEIGHT = 40;

export const MarketTypesIds = {
  BMV: 1,
  BIVA: 2,
  NYSE: 32,
  INDEX: 64,
  CURRENCY: 4096,
  FIXED_INCOME_1: 32780,
  FIXED_INCOME_2: 32781,
  FIXED_INCOME_3: 32782,
  DERIVATIVES: 1024,
  GENERAL_INDICATORS: 32768
};

export const MarketTypes = {
  BMV: { id: 1, name: i18n.t("bmvStocks") },
  BMV_SIC: { id: 2, name: i18n.t("bmvSic") },
  BIVA: { id: 3, name: i18n.t("bivaStocks") },
  BIVA_SIC: { id: 4, name: i18n.t("bivaSic") },
  NYSE_ONLY: { id: 5, name: i18n.t("nyse") },
  AMEX: { id: 6, name: i18n.t("amex") },
  NASDAQ: { id: 7, name: i18n.t("nasdaq") },
  INDEX: { id: 8, name: i18n.t("indices") },
  CURRENCY: { id: 9, name: i18n.t("currencies") },
  DERIVATIVES: { id: 10, name: i18n.t("derivatives") },
  GENERAL_INDICATORS: { id: 11, name: i18n.t("generalIndicators") },
  CONSOLIDADOS_BMV: { id: 12, name: i18n.t("consolidatedBMV") },
  CONSOLIDADOS_SIC: { id: 13, name: i18n.t("consolidatedSIC") },
  BANORTE: { id: 14, name: i18n.t("predefinedBanorte") },
  FIXED_INCOME: { id: 15, name: i18n.t("predefinedBanorte") },
  GOVERNMENT_DEBT: { id: 16, name: i18n.t("guvernmentDebt") },
  CONSOLIDADOS_BMV_SIC: { id: 18, name: i18n.t("consolidatedBMVSIC") },
  COMMODITIES: { id: 19, name: i18n.t("Commodities") },
  PETROLEUM: { id: 50, name: i18n.t("petroleum") },
  NATURAL_GAS: { id: 51, name: i18n.t("naturalGasName") },
  PRECIOUS_METALS: { id: 52, name: i18n.t("preciousMetals") },
  INDUSTRIAL_METALS: { id: 53, name: i18n.t("industrialMetals") },
  FINANCIAL_INDICATORS: { id: 54, name: i18n.t("financialIndicators") },
  FINANCE_CURRENCY: { id: 55, name: i18n.t("financeCurrency") },
  PRICES_WAGES: { id: 56, name: i18n.t("pricesWages") },
  INTEREST_CHANGES: { id: 57, name: i18n.t("interestChanges") },
  PUBLIC_ACCOUNTS: { id: 58, name: i18n.t("publicAccounts") },
  EMPLOYMENT_PRODUCTION: { id: 59, name: i18n.t("employmentProduction") },
  EXTERNAL_ACCOUNTS: { id: 60, name: i18n.t("externalAccounts") },
  CETES_PRIMARY_MARKET: { id: 61, name: i18n.t("cetesPrimaryMarket") },
  PRIMARY_MARKET_BONDS: { id: 62, name: i18n.t("primaryMarketBonds") },
  BONDES_PRIMARY_MARKET: { id: 63, name: i18n.t("bondesPrimaryMarket") },
  US_BONDS: { id: 64, name: i18n.t("usBonds") }
};

export const MarketTypesArray = [
  MarketTypes.BMV,
  MarketTypes.BIVA,
  MarketTypes.BMV_SIC,
  MarketTypes.BIVA_SIC,
  MarketTypes.INDEX,
  MarketTypes.CURRENCY,
  MarketTypes.DERIVATIVES,
  MarketTypes.FIXED_INCOME,
  MarketTypes.GENERAL_INDICATORS,
  MarketTypes.NYSE_ONLY,
  MarketTypes.NASDAQ,
  MarketTypes.AMEX,
  MarketTypes.CONSOLIDADOS_BMV,
  MarketTypes.CONSOLIDADOS_SIC,
  MarketTypes.BANORTE,
  MarketTypes.GOVERNMENT_DEBT,
  MarketTypes.CONSOLIDADOS_BMV_SIC
];

export const MarketTypeCompositions = [
  MarketTypes.CONSOLIDADOS_BMV.id,
  MarketTypes.CONSOLIDADOS_SIC.id,
  MarketTypes.FIXED_INCOME.id
];
export const ChartTypes = {
  historical: "historical",
  intraday: "intraday"
};

export const BasePeriodTypes = {
  minute: "minute",
  day: "day",
  week: "week",
  month: "month",
  year: "year"
};

export const ServerTypes = {
  Api: "API",
  LS: "Lightstreamer"
};

export const InfoselApiPeriodTypes = {
  day: "D",
  week: "W",
  month: "M",
  year: "Y"
};

export const InfoselApiIntervals = {
  intraDay: "1",
  historical: "D"
};

export const InfoselIntradayPeriods = {
  oneDay: 1,
  fiveDays: 5,
  oneMonth: 1,
  sixMonths: 6,
  twelveMonths: 12,
  fiveYears: 60,
  tenYears: 120
};

export const NewsTypes = {
  debt: 600,
  stockMarket: 604,
  forex: 608,
  commodities: 614,
  business: 615,
  economy: 618,
  general: 620
};

export const AllNewsTypes = [
  `NN.${NewsTypes.debt}`,
  `NN.${NewsTypes.stockMarket}`,
  `NN.${NewsTypes.forex}`,
  `NN.${NewsTypes.commodities}`,
  `NN.${NewsTypes.business}`,
  `NN.${NewsTypes.economy}`,
  `NN.${NewsTypes.general}`
];

export const SIDEBAR_TYPES = {
  CLOSE: "CLOSE",
  INSTRUMENT_DETAIL: "INSTRUMENT_DETAIL",
  NEWS: "NEWS",
  OPERATE: "OPERATE"
};

export const getActiveWorkspace = () =>
  sessionStorage.getItem("activeWorkspace");

export const enterKeyText = "Enter";
export const escapeKeyText = "Escape";
export const enterKeyValue = 13;
export const escapeKeyValue = 27;
export const C_KEY_VALUE = 67;
export const DELETE_KEY_VALUE = 46;

// Modal types
export const MODAL_WARNING = "warning";
export const MODAL_SUCCESS = "success";
export const MODAL_UNDO_ACTION = "undo_action";

// Action for cards
export const MOVE_CARD = "move";
export const DUPLICATE_CARD = "duplicate";

export const SNACKBAR_DURATION_MS = 1000;
export const widgetType = Object.freeze({
  bmvStocks: "1",
  chart: "2",
  news: "3",
  bivaSic: "4",
  ticker: "5",
  bmvEquity: "100",
  bmvEquitySIC: "103",
  equityBiva: "110",
  SICBIVA: "111",
  indexes: "120",
  nyseNasdaq: "130",
  generalIndicators: "140",
  currencies: "150",
  fixedIncome: "170",
  derivatives: "180"
});

export const serachStatusType = Object.freeze({
  searchNotStarted: 0,
  noResults: 1,
  oneResult: 1,
  moreResults: 2
});

// Constant below is used to limit number of rows shown in the table.
// In the docs it is specified to be 40, but since we have a table of only 40 rows in the current version
// for testing purposes, it is set to 10.

export const DEFAULT_TABLE_ROW_LENGHT = 40;
export const MAX_INSTRUMENTS_API = 120;
export const tableTypes = {
  STOCKS: "stocks",
  CURRENCIES: "currencies"
};

export const lsStatus = {
  CONNECTING: "CONNECTING",
  CONNECTED_STREAM_SENSING: "CONNECTED:STREAM-SENSING",
  DISCONNECTED: "DISCONNECTED",
  DISCONNECTED_WILL_RETRY: "DISCONNECTED:WILL-RETRY",
  CONNECTED_WS_STREAMING: "CONNECTED:WS-STREAMING"
};

export const CONNECTION_STATUS = {
  ONLINE: "Conectado",
  OFFLINE: "Desconectado",
  CONNECTING: "Conectando",
  DISCONNECTED: "Desconectado"
};

export const keyStorage = {
  user: "user",
  userLS: "userLS",
  userApi: "userApi",
  instrumentAnalitycs: "instrumentAnalitycs",
  selectedContract: "selectedContract",
  activity: "activity",
  disabled: "disabled",
  item: "item",
  confirmationGeolocalization: "confirmationGeolocalization",
  geolocalization: "geolocalization"
};

export const CloudFlareServers = {
  interlomas: 1,
  monterrey: 2
};

export const DEFAULT_CHART_SPAN = {
  base: BasePeriodTypes.month,
  multiplier: 6,
  periodicity: {
    interval: 1,
    period: 1,
    timeUnit: BasePeriodTypes.day
  }
};

export const DEFAULT_CHART_HISTORICAL_SPAN = {
  base: BasePeriodTypes.month,
  multiplier: 6,
  periodicity: {
    interval: 1,
    period: 1,
    timeUnit: BasePeriodTypes.day
  }
};

export const defaultTypeChart = "mountain";
export const defaultMarkeyTypeChart = "mountain";

export const SCHEDULE = {
  open: "8:30",
  close: "15:00"
};

export const BMVMARKETHOURS = {
  market_tz: "America/Mexico_City",
  name: "Mexico Stock Exchange",
  hour_aligned: false,
  rules: [
    {
      dayofweek: 1,
      open: SCHEDULE.open,
      close: SCHEDULE.close
    },
    {
      dayofweek: 2,
      open: SCHEDULE.open,
      close: SCHEDULE.close
    },
    {
      dayofweek: 3,
      open: SCHEDULE.open,
      close: SCHEDULE.close
    },
    {
      dayofweek: 4,
      open: SCHEDULE.open,
      close: SCHEDULE.close
    },
    {
      dayofweek: 5,
      open: SCHEDULE.open,
      close: SCHEDULE.close
    }
  ]
};

export const priceUpdateAnimationDuration = "500ms";

export const sortTypes = {
  ASCENDING: "ascending",
  DESCENDING: "descending"
};

export const snackbarTypes = {
  ERROR: "error",
  SUCCESS: "success",
  CONFIRMATION: "confirmation",
  DELETE: "delete",
  BLOCK: "block",
  BLOCK_NO_OPERABLE: "blockNoOperable"
};

export const snackbarPositions = {
  TOP: "top",
  BOTTOM: "bottom"
};

export const MAX_NUMBER_OF_WORKSPACES = 10;

export const shortcuts = {
  DELETE: "Del",
  COPY: "CTRL + C (⌘ + C)"
};

export const MAX_WORKSPACE_NAME_LENGTH = 20;

export const SPECIAL_CHARS_REGEX = /[^\w\sáéíóú]/gi;
export const SPECIAL_ALARMS_REGEX = /[^\w\sáéíóú.-]/gi;
export const REGEX_FOR_SEARCHBAR = /[^\w\sáéíóúñ*]/gi;

export const defaultNewCard = {
  CHART: {
    id: 1,
    type: cardTypes.CHART,
    options: {
      symbol: "AC*",
      widgetTypeId: widgetType.chart,
      instrumentId: "1/12576/0/AC*"
    },
    maximized: 0,
    cs: 0,
    ce: 0,
    rs: 0,
    re: 0,
    elementId: "chart"
  },
  TABLE: {
    id: 1,
    type: cardTypes.TABLE,
    options: {
      widgetTypeId: widgetType.bmvStocks
    },
    maximized: 0,
    cs: 0,
    ce: 0,
    rs: 0,
    re: 0,
    elementId: "table"
  },
  PREDEFINED_TABLE: {
    id: 1,
    type: cardTypes.PREDEFINED_TABLE,
    options: {
      widgetTypeId: widgetType.bmvStocks
    },
    maximized: 0,
    cs: 0,
    ce: 0,
    rs: 0,
    re: 0,
    elementId: "predefinedTable"
  },
  NEWS: {
    id: 1,
    type: cardTypes.NEWS,
    options: {
      widgetTypeId: widgetType.news
    },
    maximized: 0,
    cs: 0,
    ce: 0,
    rs: 0,
    re: 0,
    elementId: "news"
  },
  GEO_HEATMAP: {
    id: 1,
    type: cardTypes.GEO_HEATMAP,
    options: {
      widgetTypeId: 5
    },
    maximized: 0,
    cs: 0,
    ce: 0,
    rs: 0,
    re: 0,
    elementId: "geoHeatmap"
  },
  TREEMAP: {
    id: 1,
    type: cardTypes.TREEMAP,
    options: {
      widgetTypeId: 6
    },
    maximized: 0,
    cs: 0,
    ce: 0,
    rs: 0,
    re: 0,
    elementId: "treemap"
  },
  ECONOMIC_CALENDAR: {
    id: 1,
    type: cardTypes.ECONOMIC_CALENDAR,
    options: {
      widgetTypeId: 7
    },
    maximized: 0,
    cs: 0,
    ce: 0,
    rs: 0,
    re: 0,
    elementId: "economicCalendar"
  }
};
export const charsTabName = 13;

export const updateType = {
  ADD: "add",
  DELETE: "delete"
};

export const marketsIds = {
  bmvId: 1
};

export const specialCharacters = {
  COLON: ":",
  HYPHEN_MINUS: "-"
};

export const moveDirection = {
  UP: "up",
  DOWN: "down"
};

export const maxAttempt = 5;

export const compareColors = [
  `#E36460`,
  `#007E76`,
  `#66CAC4`,
  `#C9D641`,
  `#FAE771`,
  `#9DF882`,
  `#35D9C4`,
  `#FC9692`,
  `#62BFDF`,
  `#FE8A1E`
];

export const CARD_TITLE_MAX_CHARS = 20;
export const STUDY_MAX_CHARS = 20;

export const CONTRACT_NAME_MAX_CHARS = 13;

export const MARKETS_IDS_ENABLE = `${MarketTypes.BMV.id}, ${MarketTypes.BMV_SIC.id}, ${MarketTypes.BIVA_SIC.id}, ${MarketTypes.BIVA.id}, ${MarketTypes.NYSE_ONLY.id}, ${MarketTypes.NASDAQ.id}, ${MarketTypes.AMEX.id}, ${MarketTypes.CURRENCY.id}, ${MarketTypes.DERIVATIVES.id}, ${MarketTypes.INDEX.id}, ${MarketTypes.GENERAL_INDICATORS.id},`;

export const SIDEBAR_OPTIONS = [
  {
    id: 0,
    value: "Posturas",
    isSelected: false,
    uri: "depth"
  },
  {
    id: 1,
    value: "Intradía",
    isSelected: false,
    uri: "intraday"
  }
];

export const SHEET_TAB_ELEMENTS = [
  {
    id: 0,
    value: "Resumen",
    uri: ""
  },
  {
    id: 1,
    value: "Perfil",
    uri: ""
  },
  {
    id: 2,
    value: "Análisis",
    uri: "sectorial"
  }
];
export const tickerSmall = "small";
export const tickerLarge = "large";

export const NewsCategories = {
  ACTUALITY: { id: 1, name: "Actualidad" },
  AP: { id: 2, name: "AP Español" },
  STOCK: { id: 3, name: "Bolsa" },
  FEATURED: { id: 4, name: "Destacadas" },
  FIXED_INCOME: { id: 5, name: "Deuda" },
  CURRENCY: { id: 6, name: "Divisas" },
  ECONOMY: { id: 7, name: "Economía" },
  BUSINESS: { id: 8, name: "Empresas" },
  LATAM: { id: 9, name: "LATAM" },
  MERCANCY: { id: 10, name: "Mercancías" },
  PR_NEWS_WIRE: { id: 11, name: "PR news Wire" }
};

export const NewsCategoriesArray = [
  NewsCategories.ACTUALITY,
  NewsCategories.AP,
  NewsCategories.STOCK,
  NewsCategories.FEATURED,
  NewsCategories.FIXED_INCOME,
  NewsCategories.CURRENCY,
  NewsCategories.ECONOMY,
  NewsCategories.BUSINESS,
  NewsCategories.LATAM,
  NewsCategories.MERCANCY,
  NewsCategories.PR_NEWS_WIRE
];

export const PADDING_LEFT = 24;

export const PADDING_RIGHT = 24;

export const MARKETS_ANALITYCS_ENABLE = [
  MarketTypes.BMV.id,
  MarketTypes.BMV_SIC.id
];

export const MARKETS_TICKER_ENABLE = `${MarketTypes.BMV.id},${MarketTypes.CURRENCY.id},${MarketTypes.INDEX.id}`;

export const instrumentDefaultForAnalitycs = {
  exchangeId: 0,
  instrumentId: 5,
  market: { marketName: "BMV" },
  marketTypeId: 1,
  name: "ARCA CONTINENTAL, S.A.B. DE C.V.",
  symbol: "AC*",
  uniqueKey: "1/12576/0/AC*",
  valueTypeId: 12576
};

export const optionsOfMenuFounds = [
  { text: "Persona física", value: "PERSONA FÍSICA", id: 1 },
  { text: "Persona moral", value: "PERSONA MORAL", id: 2 }
];

export const OPTIONS_OF_ORDERS = [
  { text: "Asignada", value: "Asignada", id: 1 },
  { text: "Asignada Parcialmente", value: "Asignada parcialmente", id: 2 },
  { text: "Pendiente", value: "Pendiente", id: 3 },
  { text: "Cancelada", value: "Cancelada", id: 4 },
  { text: "Cancelada Parcialmente", value: "Cancelada parcialmente", id: 5 }
];

export const optionsOfMenuPort = [
  { text: "Acción", value: "ACCION", id: 1 },
  { text: "ETF", value: "ETF", id: 2 },
  { text: "Fibra", value: "FIBRA", id: 3 },
  { text: "Fondos", value: "FONDOS", id: 4 },
  { text: "CKD", value: "CKD", id: 5 },
  { text: "Deuda", value: "Deuda", id: 6 }
];

export const optionsOfNews = [
  { value: "Energía", id: 10 },
  { value: "Materiales", id: 15 },
  { value: "Industrial", id: 20 },
  { value: "Consumo Discrecional", id: 25 },
  {
    value: "Productos de Primera Necesidad",
    id: 30
  },
  { value: "Salud", id: 35 },
  { value: "Finanzas", id: 40 },
  {
    value: "Tecnologias de la Informacion",
    id: 45
  },
  {
    value: "Servicios de Comunicacion",
    id: 50
  },
  {
    value: "Servicios de Utilidad Pública",
    id: 55
  },
  { value: "Bienes Inmobiliarios", id: 60 }
];

export const arrayOptionsOfMenuPosition = [
  { text: "Plusvalía", value: "Plusvalia", id: 1 },
  { text: "Minusvalía", value: "Minusvalia", id: 2 }
];

export const arrayOptionsOfMenuMinPlusPercentage = [
  { text: "Plusvalía %", value: "PlusvaliaPercentage", id: 1 },
  { text: "Minusvalía %", value: "MinusvaliaPercentage", id: 2 }
];

export const arrayOptionsOfMenuMarket = [
  { text: "Capitales", value: "MC", id: 1 },
  { text: "Fondos", value: "SI", id: 2 },
  { text: "Efectivo", value: "EF", id: 3 },
  { text: "Dinero", value: "MD", id: 4 }
];

export const optionsOfMenuPosition = {
  plusvalia: "Plusvalia",
  minusvalia: "Minusvalia"
};

export const API_MARKETS_TYPES = [
  {
    marketId: 9,
    name: "Divisas"
  },
  {
    marketId: 8,
    name: "Indices"
  },
  {
    marketId: 11,
    name: "Indicadores"
  },
  {
    marketId: 12,
    name: "Acciones Consolidadas"
  },
  {
    marketId: 13,
    name: "SIC Consolidadas"
  }
];

export const INSTRUMENT_WITH_DELAY_ENCODES = [
  "2048%2F50%2F44%2FINX",
  "2048%2F50%2F7%2FDJI"
];

export const INSTRUMENT_WITH_DELAY = ["2048/50/44/INX", "2048/50/7/DJI"];

export const ASSIGNED = "Asignada";
export const PARTIALLY_ASSIGNED = "Asignada parcialmente";
export const PENDING = "Pendiente";
export const CANCELLED = "Cancelada";
export const PARTIALLY_CANCELED = "Cancelada parcialmente";

export const CONDITIONALS = {
  LESS: { name: "Menor que", value: "less", text: "Menor que" },
  LESS_OR_EQUAL: {
    name: "Menor o igual",
    value: "less or equal",
    text: "Menor o igual que"
  },
  EQUAL: { name: "Igual a", value: "equal", text: "Igual a" },
  GREATER: { name: "Mayor que", value: "greater", text: "Mayor que" },
  GREATER_OR_EQUAL: {
    name: "Mayor o igual",
    value: "greater or equal",
    text: "Mayor o igual que"
  },
  BETWEEN: {
    name: "Rango(entre 2 valores)",
    value: "between",
    text: "Rango entre"
  }
};

export const ALARM_TYPES = {
  PRICE: "price",
  UNITARY: "unitary",
  PERCENTAGE: "percentage"
};

export const ALARMS_STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive"
};

export const decimalsDefaultAlarms = 2;

export const COUNTER_TYPES = {
  STUDIES: "Estudios",
  DRAWINGS: "Dibujo"
};

export const SESSION_BASE_SECOND = 1000;
export const SESSION_TIMEOUT = SESSION_BASE_SECOND * 60 * 20; // 20 min
export const SESSION_TIMEOUT_PROMPT = SESSION_BASE_SECOND * 60 * 5; // 5 min
export const SESSION_TIMEOUT_REMAINING_SEG = 30; // 30 seg

export const MAX_COMPARISON = 4;
export const CALENDAR_TYPES = {
  SINCE: "since",
  UNTIL: "until"
};

export const DAYS = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 0
};

export const CHART_TYPES = {
  kagi: { type: "kagi", value: "kagi" },
  linebreak: { type: "linebreak", value: "priceLines" },
  rangebars: { type: "rangebars", value: "range" },
  renko: { type: "renko", value: "renko" }
};

export const DAYS_STRING = [
  i18n.t("sunday"),
  i18n.t("monday"),
  i18n.t("tuesday"),
  i18n.t("wednesday"),
  i18n.t("thursday"),
  i18n.t("friday"),
  i18n.t("saturday")
];

export const MONTH_STRING = [
  i18n.t("january"),
  i18n.t("february"),
  i18n.t("march"),
  i18n.t("april"),
  i18n.t("may"),
  i18n.t("june"),
  i18n.t("july"),
  i18n.t("august"),
  i18n.t("september"),
  i18n.t("october"),
  i18n.t("november"),
  i18n.t("december")
];
export const testUserEmails = ["testlightstreamer@infosel.com"];

export const MarketTypesIdsForIndexes = [2048, 64];

export const ExcludeSection = "Analisis Tecnico";

export const defaultSettings = {
  theme: "dark",
  letterSize: "small",
  spacing: "standar"
};

export const FontSizes = {
  small: 12,
  large: 12
};

export const FontSizesPerformanceChart = {
  small: 10,
  large: 12
};

export const themes = {
  dark: "dark",
  light: "light"
};

export const MARKET_TYPES = {
  NATIONAL: "Mercado Nacional",
  SIC: "SIC"
};
export const uniqueKeysFundsToLS = [
  "1.13617.0.",
  "1.13618.0.",
  "1.13619.0.",
  "1.13620.0.",
  "32.3.38."
];

export const DUPLICATE_OPTION = {
  THIS_SPACE: 1,
  ANOTHER_SPACE: 2,
  NEW_SPACE: 3
};
export const handleIcon = (
  field: string,
  columnSort: string,
  orderType: string
) => {
  if (field !== columnSort) {
    return SortEmpty;
  }
  if (orderType === sortTypes.DESCENDING) {
    return SortDesIcon;
  }
  return SortAscIcon;
};

export const uniqueKeysInternationalBags = [
  "32.2.21.",
  "32.101.16.",
  "32.102.1."
];

export const ROWS_INTRADAY_PER_PAGE = 100;

export const INTERNATIONAL_INDEX_MARKET_TYPE = 2048;

export const CCL1_INSTRUMENT_NAME = "CCL";

export const TV_PERIODS = [
  { text: "1d", resolution: "1", description: "1 día", title: "1D" },
  { text: "5d", resolution: "5", description: "5 días", title: "5D" },
  { text: "1M", resolution: "D", description: "1 mes", title: "1M" },
  { text: "3M", resolution: "D", description: "3 meses", title: "3M" },
  { text: "6M", resolution: "W", description: "6 meses", title: "6M" },
  { text: "12M", resolution: "M", description: "1 año", title: "1A" },
  { text: "60M", resolution: "M", description: "5 años", title: "5A" },
  { text: "120M", resolution: "M", description: "10 años", title: "10A" }
];

export const HISTORIC_RESOLUTION = "1D";

export const RESOLUTIONS = [
  "1",
  "5",
  "10",
  "15",
  "30",
  "60",
  "240",
  "1D",
  "1W",
  "1M",
  "3M",
  "12M"
];
export const TIME_FRAME_DEFAULT = "6M";

export const ORDER_STATUS = [
  { color: TRADEWIND, text: ASSIGNED },
  { color: GREEN_LIGHT, text: PARTIALLY_ASSIGNED },
  { color: ANZAC, text: PENDING },
  { color: PRAIRIE_SAND, text: CANCELLED },
  { color: PINK_LINEAR_GRADIENT, text: PARTIALLY_CANCELED }
];

export const TV_ENABLED_FEATURES = ["study_templates"];

export const TV_DISABLED_FEATURES = [
  "right_toolbar",
  "trading_account_manager",
  "use_localstorage_for_settings",
  "go_to_date",
  "header_screenshot",
  "header_fullscreen_button",
  "header_layouttoggle",
  "header_settings",
  "study_templates",
  "header_compare",
  "header_symbol_search",
  "symbol_search_hot_key",
  "header_saveload",
  "header_undo_redo"
];

export const TV_MARKETS_DISABLED_FEATURES = [
  "header_indicators",
  "header_undo_redo",
  "left_toolbar"
];

export const TV_DRAWING_ACCESS = {
  type: "black",
  tools: [
    { name: "Patron XABCD" },
    { name: "Patrón Cypher" },
    { name: "Patrón ABCD" },
    { name: "Patrón de triángulo" },
    { name: "Patrón Three Drives" },
    { name: "Cabeza y hombros" },
    { name: "Onda de impulso de Elliott (12345)" },
    { name: "Onda triangular de Elliott (ABCDE)" },
    { name: "Onda de Elliott de triple combinación (WXYXZ)" },
    { name: "Onda correctiva de Elliott (ABC)" },
    { name: "Onda de Elliott de doble combinación (WXY)" },
    { name: "Lineas cíclicas" },
    { name: "Ciclos de tiempo" },
    { name: "Línea del seno" }
  ]
};

export const TV_CHART_TYPES = {
  bar: 0,
  candle: 1,
  line: 2,
  mountain: 3,
  renko: 4,
  kagi: 5,
  punto: 6,
  salto: 7,
  heikin: 8,
  hollow_candle: 9,
  referenceLine: 10,
  colored_bar: 0
};

export const LOW_INITIAL_VALUE = 99999999.99;

export const LOCAL_STORAGE_STUDY_TEMPLATE_NAME = "infosel-tv-studyTemplateData";

export const LOCAL_STORAGE_STUDY_TEMPLATE_SELECTED = "studyTemplate-selected";
