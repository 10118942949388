// @flow
// $FlowFixMe
import { LightstreamerClient } from "lightstreamer-client-web";
import { getCurrentUser, getLSUrl } from "utils/user";
import type { User } from "constants/types";

let instance;

function createInstance() {
  const user: User | null = getCurrentUser();
  if (!user) {
    return instance;
  }
  const LSUrl = getLSUrl();
  const adapter = String(process.env.REACT_APP_LS_ADAPTER);

  const client = new LightstreamerClient(LSUrl, adapter);

  client.connectionOptions.setRetryDelay(30000);

  if (user != null) {
    const userName = `${user.username}-${user.userId}`;
    client.connectionDetails.setUser(userName);
    client.connectionDetails.setPassword(user.accessToken);
  }

  client.connect();

  return client;
}

const lsClient = () => {
  if (!instance) {
    instance = createInstance();
  }
  return instance;
};

export default lsClient;
