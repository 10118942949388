// @flow

import AccountApi from "apis/accountApi";
import history from "utils/browserHistory";
import Token from "utils/token";
import ContractApi from "apis/contractApi";
import { getSelectedContract } from "utils/helpers";
import TradingApi from "apis/tradingApi";
import { keyStorage } from "constants/globals";
import { setCurrentUser } from "utils/user";
import i18n from "i18n/index";
import ACTION_TYPES from "./actionTypes";
import type {
  StartLoadingAction,
  LoginFailedAction,
  LoginSuccessAction,
  ValidateBusinessDayAction,
  LoginActionFunc,
  AccessTokenActionFunc,
  LoginSuccessActionFunc,
  ValidateBusinessDayFunc,
  getEquityFeeFunc,
  setEquityFeeAction,
  getCloudFlareServerFunc,
  setCloudFlareServerAction,
  setSidebarTypeFunc,
  setTickerOperationFunc,
  setShowDeepPosturesFunc
} from "./types";

const startLoading = (): StartLoadingAction => ({
  type: ACTION_TYPES.START_LOADING,
  error: ""
});
const loginSuccess = (imageInfo: Object): LoginSuccessAction => ({
  type: ACTION_TYPES.LOGIN_SUCCESS,
  error: "",
  imageInfo
});
const loginFailed = (error: string): LoginFailedAction => ({
  type: ACTION_TYPES.LOGIN_FAILED,
  error
});

const setBusinessDay = (
  isBusinessDay: boolean,
  businessDayError: boolean,
  nonWorkingDays: Array<any>
): ValidateBusinessDayAction => ({
  type: ACTION_TYPES.SET_BUSINESS_DAY,
  isBusinessDay,
  businessDayError,
  nonWorkingDays
});

export const updateConnectionStatus = (connectionStatus: string) => ({
  type: ACTION_TYPES.UPDATE_CONNECTION_STATUS,
  connectionStatus
});

export const setImageInfo = (imageInfo: Object) => ({
  type: ACTION_TYPES.UPDATE_IMAGE_INFO,
  imageInfo
});

export const setShowVersion = () => ({
  type: ACTION_TYPES.SET_SHOW_VERSION
});

export const setGeolocalizationError = (geolocalizationError: boolean) => ({
  type: ACTION_TYPES.SET_GEOLOCALIZATION_ERROR,
  geolocalizationError
});

export const setGeolocationLoader = (showGeolocationLoader: boolean) => ({
  type: ACTION_TYPES.SET_SHOW_GELOCATION_LOADER,
  showGeolocationLoader
});

export const setEquityFeeRedux = (equityFee: Object): setEquityFeeAction => ({
  type: ACTION_TYPES.SET_EQUITY_FEE,
  equityFee
});

export const setCloudFlareServer = (
  cloudFlareServer: number
): setCloudFlareServerAction => ({
  type: ACTION_TYPES.SET_CLOUDFLARE_SERVER,
  cloudFlareServer
});

export const login: LoginActionFunc = credentials => dispatch => {
  dispatch(startLoading());
  return AccountApi.obtainToken(credentials)
    .then(res => {
      const { data } = res;

      const accessTokenSplit = data.token && data.token.split(".");

      const ImageInfoSplit = accessTokenSplit.slice(-3);
      const imageInfo = {
        BT_IMAGE: ImageInfoSplit[0],
        STYLE_PATH: ImageInfoSplit[1],
        LOGO_URL: ImageInfoSplit[2]
      };

      data.token =
        accessTokenSplit &&
        accessTokenSplit.slice(0, accessTokenSplit.length - 3).join(".");
      const accessToken = data.token;
      const token = new Token().setToken(data.token, data.refreshToken);

      const exp = Number(data.expirationDate);

      const obj = {
        ...data,
        accessToken,
        tickerVisible: false,
        exp
      };
      setCurrentUser(obj, token);
      dispatch(loginSuccess(imageInfo));
    })
    .then(() => {
      history.push("/");
    })
    .catch(err => {
      if (err && err.message) {
        const error = {
          ...err
        };
        error.message = i18n.t("errorLogin");
        dispatch(loginFailed(error.message));
      }
      return Promise.resolve(err);
    });
};

const getCurrentContracts = () => {
  ContractApi.getContracts()
    // eslint-disable-next-line
    .then(({ data }) => {
      if (data) {
        if (data && data.length > 0) {
          sessionStorage.setItem(
            keyStorage.selectedContract,
            data[0].contractNumber
          );
        } else {
          sessionStorage.removeItem(keyStorage.selectedContract);
        }
      }
    });
};

export const accessTokenLogin: AccessTokenActionFunc = token => dispatch => {
  dispatch(startLoading());
  return AccountApi.accessToken(token)
    .then(res => {
      const { data } = res;

      const accessTokenSplit = data.token && data.token.split(".");

      const ImageInfoSplit = accessTokenSplit.slice(-3);
      const imageInfo = {
        BT_IMAGE: ImageInfoSplit[0],
        STYLE_PATH: ImageInfoSplit[1],
        LOGO_URL: ImageInfoSplit[2]
      };

      data.token =
        accessTokenSplit &&
        accessTokenSplit.slice(0, accessTokenSplit.length - 3).join(".");

      const newtoken = new Token().setToken(data.token, data.refreshToken);

      const exp = Number(data.expirationDate);
      const accessToken = data.token;

      const obj = {
        ...data,
        accessToken,
        tickerVisible: false,
        exp
      };
      setCurrentUser(obj, newtoken);

      if (!getSelectedContract()) {
        getCurrentContracts();
      }
      dispatch(loginSuccess(imageInfo));
    })
    .then(() => {
      history.push("/");
    })
    .catch(err => {
      return Promise.reject(err);
    });
};

export const clearBackendValidation: LoginSuccessActionFunc = () => dispatch => {
  dispatch(loginSuccess());
};

const getCurrentDate = date => {
  const utc = date.getTime() + date.getTimezoneOffset() * 60000;
  const threeMonthsmilisecods = 1000 * 60 * 60 * 24 * 30 * 3;
  const convert = new Date(utc + 3600000 * -6);
  convert.setTime(convert.getTime() - threeMonthsmilisecods);
  const month = `0${convert.getMonth() + 1}`.slice(-2);
  const currentDay = `0${convert.getDate()}`.slice(-2);
  return `${convert.getFullYear()}-${month}-${currentDay}`;
};

const getEndDate = date => {
  const convert = new Date(date.setDate(date.getDate() - 1));
  const threeMonthsmilisecods = 1000 * 60 * 60 * 24 * 30 * 3;
  convert.setTime(convert.getTime() - threeMonthsmilisecods);
  const month = `0${convert.getMonth() + 1}`.slice(-2);
  const currentDay = `0${convert.getDate()}`.slice(-2);
  return `${convert.getFullYear() + 1}-${month}-${currentDay}`;
};

export const validateBusinessDay: ValidateBusinessDayFunc = () => async dispatch => {
  const date = new Date();
  const startDate = getCurrentDate(date);
  const endDate = getEndDate(date);
  let businessDay = false;
  let businessDayError = false;
  try {
    const response = await TradingApi.validateBusinessDay();
    businessDay = response.data.businessDay;
  } catch {
    businessDay = false;
    businessDayError = true;
  }

  return TradingApi.getBusinessDays(startDate, endDate)
    .then(res => {
      const { data } = res;
      const addNotWorkingDay = process.env.REACT_APP_ADD_NO_WORWING_DAY ?? "";
      dispatch(
        setBusinessDay(businessDay, businessDayError, [
          ...data,
          addNotWorkingDay
        ])
      );
    })
    .catch(() => {
      dispatch(setBusinessDay(false, businessDayError, []));
    });
};

export const getEquitieFee: getEquityFeeFunc = (
  forceRefresh: boolean = false
) => (dispatch: Function, getState: Function) => {
  const { equityFee } = getState().account;
  if (equityFee && !forceRefresh) {
    return;
  }

  TradingApi.getEquitiesFee()
    .then(({ data }) => {
      if (data) {
        dispatch(setEquityFeeRedux(data));
      }
    })
    .catch(() => {});
};

export const getCloudFlareServer: getCloudFlareServerFunc = () => (
  dispatch: Function,
  getState: Function
) => {
  const { cloudFlareServer } = getState().account;
  return cloudFlareServer;
};

export const setSidebarType = (
  sidebarSelected: string
): setSidebarTypeFunc => ({
  type: ACTION_TYPES.SET_SIDEBAR_TYPE,
  sidebarSelected
});

export const setTickerOperation = (
  showTicketOperation: boolean
): setTickerOperationFunc => ({
  type: ACTION_TYPES.SET_TICKER_OPERATION,
  showTicketOperation
});

export const setShowDeepPostures = (
  showDeepPostures: boolean
): setShowDeepPosturesFunc => ({
  type: ACTION_TYPES.SET_SHOW_DEEP_POSTURES,
  showDeepPostures
});
