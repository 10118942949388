import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import TradingApi from "apis/tradingApi";
import { keyStorage } from "constants/globals";
import { useDispatch } from "react-redux";
import { PATTENS_BLUE } from "constants/colors";
import { setGeolocationLoader } from "ducks/account/actions";
import i18n from "i18n/index";
import {
  title,
  paragraph1,
  paragraph2,
  paragraph3,
  paragraph4
} from "../constants";
import {
  Container,
  SubContainer,
  HeaderText,
  BodyText,
  AgreeButton,
  RejetButton,
  TutorialImage
} from "./style";

const geolocalizationIcon = require("assets/img/configuration/geolocalization.svg");

type Props = { geolocalization: any };

function Confirmation(props: Props) {
  const { geolocalization } = props;
  const [open, setOpen] = useState(true);
  const disabledConfirmation = geolocalization === null;
  const dispatch = useDispatch();

  const confirmation = () => {
    if (
      geolocalization &&
      geolocalization.latitude &&
      geolocalization.longitude
    ) {
      dispatch(setGeolocationLoader(true));
    }
    TradingApi.confirmGeolocalization(
      geolocalization.latitude,
      geolocalization.longitude
    )
      .then(() => {
        const newStatus = btoa("true");
        sessionStorage.setItem(
          keyStorage.confirmationGeolocalization,
          newStatus
        );
        dispatch(setGeolocationLoader(false));

        setOpen(false);
      })
      .catch(() => {
        dispatch(setGeolocationLoader(false));

        setOpen(false);
      });
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      disableBackdropClick={true}
      hideBackdrop={false}
      aria-labelledby="confirmationGeolocalization-modal"
      aria-describedby="confirmationGeolocalization-modal-wizard"
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.8)"
        }
      }}
    >
      <Container>
        <SubContainer>
          <HeaderText>{title}</HeaderText>
          <CloseIcon
            data-testid={"btnCloseButtonGro"}
            onClick={() => setOpen(false)}
            style={{
              color: PATTENS_BLUE,
              margin: "24px 0px 0px 48px",
              cursor: "pointer"
            }}
          />
        </SubContainer>
        <div style={{ marginTop: -16 }}>
          <BodyText>{paragraph1}</BodyText>
          <BodyText>{paragraph2}</BodyText>
          <BodyText>{paragraph3}</BodyText>
          <BodyText>{paragraph4}</BodyText>
          <TutorialImage alt="geolocalization_img" src={geolocalizationIcon} />
        </div>
        <SubContainer style={{ float: "right" }}>
          <AgreeButton
            data-testid={"btnAgreeButtonGro"}
            disabled={disabledConfirmation}
            onClick={disabledConfirmation ? () => {} : () => confirmation()}
          >
            {i18n.t("agree")}
          </AgreeButton>
          <RejetButton disabled={false} onClick={() => setOpen(false)}>
            {i18n.t("reject")}
          </RejetButton>
        </SubContainer>
      </Container>
    </Modal>
  );
}

export default Confirmation;
