import GeolocationLoader from "components/Common/GeolocationLoader";
import i18n from "i18n/index";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { PATTENS_BLUE } from "constants/colors";
import { title } from "../constants";
import {
  Text,
  BodyContainer,
  Location,
  HeaderText,
  PositionUnavailableContainer
} from "./style";

const localizationIcon = require("assets/icons/icon-location.svg");

type Props = {
  showIcon: boolean,
  showLoader: boolean,
  positionUnavailable: boolean,
  closeModal: Function,
  enableResponsive?: boolean
};

const Body = (props: Props) => {
  const {
    showIcon,
    showLoader,
    positionUnavailable,
    closeModal,
    enableResponsive
  } = props;
  if (positionUnavailable) {
    return (
      <PositionUnavailableContainer showIcon={showIcon}>
        <div>
          <Location
            style={{ margin: "22px 10px" }}
            src={localizationIcon}
            alt="location_icon"
          />
        </div>
        <div style={{ height: 80 }}>
          <HeaderText style={{ marginTop: 24 }}>
            {i18n.t("geolocalitionError")}
          </HeaderText>
        </div>
        <div>
          <CloseIcon
            style={{
              color: PATTENS_BLUE,
              margin: "24px 15px 0px 0px",
              cursor: "pointer",
              float: "right"
            }}
            onClick={() => closeModal()}
          />
        </div>
      </PositionUnavailableContainer>
    );
  }

  return showLoader ? (
    <div>
      <GeolocationLoader isGetGeolocation={true} />
    </div>
  ) : (
    <BodyContainer showIcon={showIcon}>
      {showIcon && (
        <div>
          <Location src={localizationIcon} alt="location_icon" />
        </div>
      )}
      <div>
        <HeaderText enableResponsive={enableResponsive}>{title}</HeaderText>
        <Text enableResponsive={enableResponsive}>
          Por disposición de las autoridades, a partir del 23 de julio de 2021
          necesitamos conocer y resguardar la geolocalización del dispositivo
          desde el que realiza sus operaciones.
        </Text>
        <Text enableResponsive={enableResponsive}>
          Sólo así podrá seguir disfrutando de todos los servicios que ofrece
          BeTrading para la operación de instrumentos en el mercado de capitales
          y fondos de inversión.
        </Text>
        <Text enableResponsive={enableResponsive}>
          Todos sus datos serán tratados conforme a nuestro aviso de privacidad.
          Por tal motivo su información está completamente segura.
        </Text>
        <Text enableResponsive={enableResponsive}>
          Para activar su geolocalización de forma manual, le sugerimos copiar
          la siguiente ruta en la barra de direcciones de acuerdo al explorador
          que esté utilizando para operar en BeTrading:
        </Text>
        <Text enableResponsive={enableResponsive}>
          {`Chrome: chrome://settings/content/location ${" "}`}
          <br />
          {`Edge: edge://settings/content/location${" "}`}
          <br />
          {`Firefox: about:preferences#privacy${" "}`}
          <br />
          {`Safari: https://support.apple.com/es-es/HT5405${" "}`}
        </Text>
        <Text enableResponsive={enableResponsive}>
          Una vez que vuelva a ingresar a BeTrading es probable que aparezca un
          mensaje en el navegador pidiendo cargar nuevamente la página para
          aplicar los cambios, elija “Volver a cargar”. Una vez hecho esto podrá
          continuar operando normalmente.
        </Text>
        <Text enableResponsive={enableResponsive}>
          Si tiene cualquier duda o necesita información adicional, favor de
          contactar a su asesor o a través del correo electrónico
          betrading@banorte.com
        </Text>
      </div>
    </BodyContainer>
  );
};

export default Body;
