import { fetchNext } from "../utils/request";
import requestTypes from "../constants/requestTypes";
import type { ticker } from "./models/ticker";

export default class TickerApi {
  static getTicker = (): Promise<{ data: ticker }> =>
    fetchNext(requestTypes.GET, `/users/ticker`);

  static addTicker = (): Promise<{ data: ticker }> =>
    fetchNext(requestTypes.POST, `/users/ticker`);

  static deleteInstrument = (
    instrumentId: number
  ): Promise<{ data: ticker }> => {
    return fetchNext(
      requestTypes.DELETE,
      `/users/ticker/instrument/${instrumentId}`
    );
  };

  static updateInstruments = (
    instrumentIds: Array<number>
  ): Promise<{ data: ticker }> => {
    const reqBody = {
      instrumentIds
    };

    return fetchNext(
      requestTypes.PUT,
      `/users/ticker/update_instruments`,
      reqBody
    );
  };
}
