import type { ComponentType } from "react";
import styled from "styled-components";
import { SHARK } from "constants/colors";

export const Container: ComponentType<{}> = styled.div`
  background-color: ${SHARK};
  width: 500px;
  height: 578px;
  z-index: 5;
  border-radius: 20px;
  position: fixed;
  left: 50%;
  top: 20px;
  transform: translate(-50%, 0);
  outline: none;
`;

export const SubContainer: ComponentType<any> = styled.div`
  display: flex;
`;

export const HeaderText: ComponentType<any> = styled.p`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 29px;
  letter-spacing: 0.15px;
  color: #f2f2f2;
  padding-left: 16px;
  width: 410px;
`;

export const BodyText: ComponentType<any> = styled.p`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: justify;
  color: #cedaeb;
  padding: 2px 16px;
`;

export const AgreeButton: ComponentType<{ disabled: boolean }> = styled.div`
  background: #eb0029;
  border-radius: 8px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.25px;
  color: #ffffff;
  width: 78px;
  height: 37px;
  padding: 7px 8px;
  margin: 16px;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${props => (props.disabled ? ".5" : "1")};
`;

export const RejetButton: ComponentType<{ disabled: boolean }> = styled.div`
  background: transparent;
  border-radius: 8px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.25px;
  color: #cedaeb;
  width: 88px;
  height: 37px;
  border: 1px solid #cedaeb;
  cursor: pointer;
  padding: 7px 8px;
  margin: 16px 16px 0px 0px;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${props => (props.disabled ? ".5" : "1")};
`;

export const TutorialImage: ComponentType<any> = styled.img`
  display: block;
  margin-right: auto;
  margin-left: auto;
`;
