import {
  ABBEY,
  ALMOST_WHITE,
  BLACK,
  DARK,
  GRAY_EVEN,
  HEATHER,
  HEATHER2,
  LIGHT_GREY,
  LIGTHER_GREY,
  NEUTRAL_GREY,
  PALE_BLUE,
  PATTENS_BLUE,
  RAINMASTER,
  SEA_BUCKTHORN,
  SHARK,
  SHUTTLE_GREY,
  SILVER,
  SILVER_METALLIC,
  SPUN_PEARL,
  STEEL_GRAY,
  TORCH_RED,
  WHITE,
  WHITE_LESS_WHITE,
  WHITE_SMOKE,
  SILVER_LIGHT,
  TORCH_RED_50,
  PATTENS_BLUE_75,
  BLACK_50,
  SPUN_PEARL_50,
  SILVER_50,
  WHITE_50,
  SHARK_70,
  BLACK_30,
  PEARL_30,
  SPUN_PEARL_40,
  PATTENS_BLUE_80,
  BLACK_80,
  SILVER_10,
  SHARK_30,
  GRAY_EVEN_35,
  SILVER_85,
  GRAY_EVEN_85,
  SEA_BUCKTHORN_20,
  ANZAC_15,
  GRAY_EVEN_30,
  BACKGROUND_COLOR,
  RED_CHERRY
} from "constants/colors";

const palletes = {
  light: {
    palette: {
      SILVER_BLACK: SILVER,
      BLACK_WHITE: BLACK,
      GRAY_EVEN_SPUN_PEARL: GRAY_EVEN,
      PATTENS_BLUE_BLACK: PATTENS_BLUE,
      WHITE_GRAY_EVEN: WHITE,
      SILVER_TORCH_RED_50: SILVER,
      WHITE_SHARK: WHITE,
      BLACK_ALMOST_WHITE: BLACK,
      WHITE_PATTENS_BLUE: WHITE,
      PATTENS_BLUE_SILVER: PATTENS_BLUE,
      SILVER_SHARK: SILVER,
      BLACK_SILVER: BLACK,
      PATTENS_BLUE_75_BLACK_50: PATTENS_BLUE_75,
      SHARK_SILVER: SHARK,
      SPUN_PEARL_50_SILVER_50: SPUN_PEARL_50,
      SHARK_70_WHITE_50: SHARK_70,
      WHITE_SMOKE_SHARK: WHITE_SMOKE,
      SPUN_PEARL_SILVER: SPUN_PEARL,
      SPUN_PEARL_GRAY_EVEN: SPUN_PEARL,
      PATTENS_BLUE_SHARK: PATTENS_BLUE,
      SILVER_SPUN_PEARL: SILVER,
      WHITE_SMOKE_GRAY_EVEN: WHITE_SMOKE,
      WHITE_SMOKE_BLACK_30: WHITE_SMOKE,
      PATTENS_BLUE_GRAY_EVEN: PATTENS_BLUE,
      SILVER_NEUTRAL_GREY: SILVER,
      PALE_BLUE_TRANSPARENT: PALE_BLUE,
      WHITE_BLACK: WHITE,
      TORCH_RED_PALE_BLUE: TORCH_RED,
      SILVER_TRANSPARENT: SILVER,
      PATTENS_BLUE_BLACK_50: PATTENS_BLUE,
      WHITE_SMOKE_SHARK_70: WHITE_SMOKE,
      SHARK_WHITE_SMOKE: SHARK,
      SHARK_SPUN_PEARL: SHARK,
      SHARK_ALMOST_WHITE: SHARK,
      SILVER_RAINMASTER: SILVER,
      SHARK_LIGTHER_GREY: SHARK,
      SHARK_LIGHT_GREY: SHARK,
      SILVER_PALE_BLUE: SILVER,
      SILVER_ALMOST_WHITE: SILVER,
      SHARK_70_PEARL_30: SHARK_70,
      BLACK_LIGTHER_GREY: BLACK,
      SHARK_PALE_BLUE: SHARK,
      GRAY_EVEN_PALE_BLUE: GRAY_EVEN,
      SHARK_WHITE: SHARK,
      SILVER_LIGTHER_GREY: SILVER,
      SHARK_PATTENS_BLUE: SHARK,
      SILVER_PATTENS_BLUE: SILVER,
      SHARK_HEATHER: SHARK,
      SILVER_WHITE: SILVER,
      SHARK_TRANSPARENT: SHARK,
      SHARK_HEATHER2: SHARK,
      TORCH_RED_SEA_BUCKTHORN: TORCH_RED,
      SPUN_PEARL_DARK: SPUN_PEARL,
      SPUN_PEARL_SHUTTLE_GREY: SPUN_PEARL,
      WHITE_TORCH_RED: WHITE,
      SPUN_PEARL_40_GRAY_EVEN: SPUN_PEARL_40,
      WHITE_SPUN_PEARL: WHITE,
      SILVER_50_SILVER: SILVER_50,
      SPUN_PEARL_LIGTHER_GREY: SPUN_PEARL,
      BLACK_PATTENS_BLUE: BLACK,
      BLACK_TORCH_RED: BLACK,
      GRAY_EVEN_SILVER: GRAY_EVEN,
      TORCH_RED_PATTENS_BLUE: TORCH_RED,
      SILVER_ABBEY: SILVER,
      BLACK_WHITE_LESS_WHITE: BLACK,
      PATTENS_BLUE_80_BLACK_80: PATTENS_BLUE_80,
      GRAY_EVEN_WHITE_SMOKE: GRAY_EVEN,
      WHITE_SMOKE_BLACK: WHITE_SMOKE,
      SILVER_10_SHARK: SILVER_10,
      WHITE_STEEL_GRAY: WHITE,
      SPUN_PEARL_SILVER_METALLIC: SPUN_PEARL,
      SPUN_PEARL_SHARK: SPUN_PEARL,
      SHARK_30_NEUTRAL_GREY: SHARK_30,
      GRAY_EVEN_35_GRAY_EVEN: GRAY_EVEN_35,
      SILVER_85_GRAY_EVEN_85: SILVER_85,
      SEA_BUCKTHORN_20_ANZAC_15: SEA_BUCKTHORN_20,
      SILVER_HEATHER2: SILVER,
      PALE_BLUE_SILVER: PALE_BLUE,
      GRAY_EVEN_WHITE: GRAY_EVEN,
      SPUN_PEARL_PALE_BLUE: SPUN_PEARL,
      PALE_BLUE_STEEL_GRAY: PALE_BLUE,
      SILVER_GRAY_EVEN: SILVER,
      WHITE_SMOKE_GRAY_EVEN_30: WHITE_SMOKE,
      SILVER_WHITE_SMOKE: SILVER,
      GRAY_EVEN_PATTENS_BLUE: GRAY_EVEN,
      SHARK_WHITE_LESS_WHITE: SHARK,
      SILVER_WHITE_LESS_WHITE: SILVER,
      SPUN_PEARL_SILVER_LIGHT: SPUN_PEARL,
      PATTENS_BLUE_SHARK_70: PATTENS_BLUE,
      SHARK_BACKGROUND_COLOR: SHARK,
      WHITE_LIGHT_GREY: WHITE,
      SHARK_70_WHITE: WHITE,
      SILVER_RED_CHERRY: SILVER,
      WHITE_SMOKE: SHARK
    }
  },
  dark: {
    palette: {
      SILVER_BLACK: BLACK,
      BLACK_WHITE: WHITE,
      GRAY_EVEN_SPUN_PEARL: SPUN_PEARL,
      PATTENS_BLUE_BLACK: BLACK,
      WHITE_GRAY_EVEN: GRAY_EVEN,
      SILVER_TORCH_RED_50: TORCH_RED_50,
      WHITE_SHARK: SHARK,
      BLACK_ALMOST_WHITE: ALMOST_WHITE,
      WHITE_PATTENS_BLUE: PATTENS_BLUE,
      PATTENS_BLUE_SILVER: SILVER,
      SILVER_SHARK: SHARK,
      BLACK_SILVER: SILVER,
      PATTENS_BLUE_75_BLACK_50: BLACK_50,
      SHARK_SILVER: SILVER,
      SPUN_PEARL_50_SILVER_50: SILVER_50,
      SHARK_70_WHITE_50: WHITE_50,
      WHITE_SMOKE_SHARK: SHARK,
      SPUN_PEARL_SILVER: SILVER,
      SPUN_PEARL_GRAY_EVEN: GRAY_EVEN,
      PATTENS_BLUE_SHARK: SHARK,
      SILVER_SPUN_PEARL: SPUN_PEARL,
      WHITE_SMOKE_GRAY_EVEN: GRAY_EVEN,
      WHITE_SMOKE_BLACK_30: BLACK_30,
      PATTENS_BLUE_GRAY_EVEN: GRAY_EVEN,
      SILVER_NEUTRAL_GREY: NEUTRAL_GREY,
      PALE_BLUE_TRANSPARENT: "transparent",
      WHITE_BLACK: BLACK,
      TORCH_RED_PALE_BLUE: PALE_BLUE,
      SILVER_TRANSPARENT: "",
      PATTENS_BLUE_BLACK_50: BLACK_50,
      WHITE_SMOKE_SHARK_70: SHARK_70,
      SHARK_WHITE_SMOKE: WHITE_SMOKE,
      SHARK_SPUN_PEARL: SPUN_PEARL,
      SHARK_ALMOST_WHITE: ALMOST_WHITE,
      SILVER_RAINMASTER: RAINMASTER,
      SHARK_LIGTHER_GREY: LIGTHER_GREY,
      SHARK_LIGHT_GREY: LIGHT_GREY,
      SILVER_PALE_BLUE: PALE_BLUE,
      SILVER_ALMOST_WHITE: ALMOST_WHITE,
      SHARK_70_PEARL_30: PEARL_30,
      BLACK_LIGTHER_GREY: LIGTHER_GREY,
      SHARK_PALE_BLUE: PALE_BLUE,
      GRAY_EVEN_PALE_BLUE: PALE_BLUE,
      SHARK_WHITE: WHITE,
      SILVER_LIGTHER_GREY: LIGTHER_GREY,
      SHARK_PATTENS_BLUE: PATTENS_BLUE,
      SILVER_PATTENS_BLUE: PATTENS_BLUE,
      SHARK_HEATHER: HEATHER,
      SILVER_WHITE: WHITE,
      SHARK_TRANSPARENT: "",
      SHARK_HEATHER2: HEATHER2,
      TORCH_RED_SEA_BUCKTHORN: SEA_BUCKTHORN,
      SPUN_PEARL_DARK: DARK,
      SPUN_PEARL_SHUTTLE_GREY: SHUTTLE_GREY,
      WHITE_TORCH_RED: TORCH_RED,
      SPUN_PEARL_40_GRAY_EVEN: GRAY_EVEN,
      WHITE_SPUN_PEARL: SPUN_PEARL,
      SILVER_50_SILVER: SILVER,
      SPUN_PEARL_LIGTHER_GREY: LIGTHER_GREY,
      BLACK_PATTENS_BLUE: PATTENS_BLUE,
      BLACK_TORCH_RED: TORCH_RED,
      GRAY_EVEN_SILVER: SILVER,
      TORCH_RED_PATTENS_BLUE: PATTENS_BLUE,
      SILVER_ABBEY: ABBEY,
      BLACK_WHITE_LESS_WHITE: WHITE_LESS_WHITE,
      PATTENS_BLUE_80_BLACK_80: BLACK_80,
      GRAY_EVEN_WHITE_SMOKE: WHITE_SMOKE,
      WHITE_SMOKE_BLACK: BLACK,
      SILVER_10_SHARK: SHARK,
      WHITE_STEEL_GRAY: STEEL_GRAY,
      SPUN_PEARL_SILVER_METALLIC: SILVER_METALLIC,
      SPUN_PEARL_SHARK: SHARK,
      SHARK_30_NEUTRAL_GREY: NEUTRAL_GREY,
      GRAY_EVEN_35_GRAY_EVEN: GRAY_EVEN,
      SILVER_85_GRAY_EVEN_85: GRAY_EVEN_85,
      SEA_BUCKTHORN_20_ANZAC_15: ANZAC_15,
      SILVER_HEATHER2: HEATHER2,
      PALE_BLUE_SILVER: SILVER,
      GRAY_EVEN_WHITE: WHITE,
      SPUN_PEARL_PALE_BLUE: PALE_BLUE,
      PALE_BLUE_STEEL_GRAY: STEEL_GRAY,
      SILVER_GRAY_EVEN: GRAY_EVEN,
      WHITE_SMOKE_GRAY_EVEN_30: GRAY_EVEN_30,
      SILVER_WHITE_SMOKE: WHITE_SMOKE,
      GRAY_EVEN_PATTENS_BLUE: PATTENS_BLUE,
      SHARK_WHITE_LESS_WHITE: WHITE_LESS_WHITE,
      SILVER_WHITE_LESS_WHITE: WHITE_LESS_WHITE,
      SPUN_PEARL_SILVER_LIGHT: SILVER_LIGHT,
      PATTENS_BLUE_SHARK_70: SHARK_70,
      SHARK_BACKGROUND_COLOR: BACKGROUND_COLOR,
      WHITE_LIGHT_GREY: LIGHT_GREY,
      SHARK_70_WHITE: SHARK_70,
      SILVER_RED_CHERRY: RED_CHERRY,
      WHITE_SMOKE
    }
  }
};

export default palletes;
