// @flow
import { getSelectedContract } from "utils/helpers";
import { getMarketData, getManagementData } from "../utils/request";
import type { CompositionList } from "./models/composition";
import requestTypes from "../constants/requestTypes";

import type { PortafolioDetail } from "./models/portafolioDetail";

import type { PortafolioPerformance } from "./models/portafolioPerformance";

export default class PortfolioApi {
  static getPortafolioDetail = (): Promise<{ data: PortafolioDetail }> =>
    getMarketData(`/portfolio/${getSelectedContract()}`);

  static getPortafolioPerformance = (): Promise<{
    data: PortafolioPerformance
  }> => getMarketData(`/portfolio/${getSelectedContract()}/performance`);

  static getComposition = (): // contractNumber: string
  Promise<{ data: CompositionList }> =>
    getMarketData(`/portfolio/${getSelectedContract()}/composition`); // replace with contractNumber

  static getPosition = (
    issuer: string,
    series: string,
    typeMarket: string
  ): // contractNumber: string
  Promise<{ data: CompositionList }> =>
    getMarketData(
      `/portfolio/${getSelectedContract()}/position?issuer=${issuer}&series=${series}&typeMarket=${typeMarket}`
    ); // replace with contractNumber

  static getHistoricOperations = (
    // contractNumber: string
    dateFrom: string,
    dateTo: string
  ): Promise<{ data: CompositionList }> =>
    getManagementData(
      requestTypes.GET,
      `/contract/${getSelectedContract()}/orders?startDate=${dateFrom}&endDate=${dateTo}`
    ); // replace with contractNumber

  static getOrderDetail = (
    // contractNumber: string
    orderId: any
  ): Promise<{ data: CompositionList }> =>
    getManagementData(
      requestTypes.GET,
      `/contract/${getSelectedContract()}/orders/${orderId}`
    ); // replace with contractNumber

  static cancelOrder = (
    // contractNumber: string
    orderId: any
  ): Promise<{ orderId: any }> =>
    getManagementData(
      requestTypes.POST,
      `/order-management/contracts/${getSelectedContract()}/orders/${orderId}/cancel`
    ); // replace with contractNumber

  static getUrlTechRule = (
    module: number
  ): Promise<{ token: string, url: string, module: string }> =>
    getMarketData(`/modules/${module}/services`);
}
