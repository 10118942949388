const ACTION_TYPES = {
  GET_NEWS_SUCCESS: "GET_NEWS_SUCCESS",
  GET_NEWS_FAILED: "GET_NEWS_FAILED",

  GET_NEWS_DETAILS_SUCCESS: "GET_NEWS_DETAILS_SUCCESS",
  GET_NEWS_DETAILS_FAILED: "GET_NEWS_DETAILS_FAILED",
  GET_NOT_NEWS_PAGINATION: "GET_NOT_NEWS_PAGINATION",

  LOADER: "NEWS_LOADER"
};

export default ACTION_TYPES;
