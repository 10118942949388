import type { ComponentType } from "react";
import styled from "styled-components";
import { TORCH_RED, SPUN_PEARL } from "constants/colors";

export const Container: ComponentType<{}> = styled.div`
  position: relative;
`;

export const Bubble: ComponentType<any> = styled.div`
  background: ${TORCH_RED};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: bubble 1s infinite;
  animation-delay: inherit !important;
  transform: none !important;
`;

export const Text: ComponentType<any> = styled.p`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.25px;
  color: ${SPUN_PEARL};
  margin-top: 34px;
`;
