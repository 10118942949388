/* eslint-disable no-nested-ternary */
import React from "react";
import ReactDOM from "react-dom";
import i18n from "i18n/index";
import CloseLigthIcon from "assets/icons/ligth.svg";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {
  FloatingModal,
  SidebarBody,
  Title,
  SidebarHeaderButtons,
  Backdrop,
  ButtonConfirm,
  ContainerButtons,
  Subtitle,
  useStyles,
  ContainerCounter,
  Counter
} from "../style";

type Props = {
  showModal: boolean,
  handleLogout: Function,
  hanlderContinueSession: Function,
  isPrompted: boolean,
  isCounter: boolean,
  remaining: number
};

const IdleTimeOutModal = ({
  showModal,
  handleLogout,
  hanlderContinueSession,
  isCounter,
  remaining,
  isPrompted
}: Props) => {
  const modalContainer = document.querySelector("#modalContainer");
  const classes = useStyles();

  return showModal ? (
    <React.Fragment>
      {showModal ? <Backdrop /> : null}
      {modalContainer !== null
        ? ReactDOM.createPortal(
            <FloatingModal
              open={showModal}
              lighterBackground={true}
              isInstrument={false}
            >
              <SidebarBody>
                <SidebarHeaderButtons onClick={hanlderContinueSession}>
                  <img
                    src={CloseLigthIcon}
                    className={classes.closeIcon}
                    alt="close-ligth-icon"
                  />
                </SidebarHeaderButtons>
                <Title>
                  {isPrompted
                    ? i18n.t("sessionExpiredAutomatly")
                    : i18n.t("sessionExpiredTitle")}
                </Title>
                <Subtitle>
                  {isPrompted
                    ? isCounter
                      ? i18n.t("sessionExpiredAutomatlyCounter")
                      : i18n.t("sessionExpiredAutomatlyRemaining")
                    : i18n.t("sessionExpiredSubtitle")}
                </Subtitle>

                {isCounter && isPrompted && (
                  <ContainerCounter>
                    <Counter>{remaining}</Counter>{" "}
                    {i18n.t("sessionExpiredAutomatlySeconds")}
                  </ContainerCounter>
                )}

                <ContainerButtons>
                  {isCounter && isPrompted && (
                    <ButtonConfirm onClick={hanlderContinueSession}>
                      <p>{i18n.t("sessionExpiredAutomatlyContinueSession")}</p>
                      <ArrowForwardIcon alt="arrow-forward-icon" />
                    </ButtonConfirm>
                  )}

                  {remaining === 0 && !isPrompted && (
                    <ButtonConfirm onClick={handleLogout}>
                      <p>{i18n.t("agree")}</p>
                    </ButtonConfirm>
                  )}
                </ContainerButtons>
              </SidebarBody>
            </FloatingModal>,
            modalContainer
          )
        : null}
    </React.Fragment>
  ) : null;
};

export default IdleTimeOutModal;
