import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import Body from "./body";
import { Container, LoaderContainer, SubContainer } from "./style";

type Props = {
  isModal: boolean,
  closeModal?: Function,
  enableResponsive?: boolean
};

function LockedPositionModal(props: Props) {
  const { isModal, enableResponsive } = props;
  const [open, setOpen] = useState(true);
  const finishGetCurrentPosition = useSelector(
    state => state.trading.finishGetCurrentPosition
  );

  const positionUnavailable = useSelector(
    state => state.trading.positionUnavailable
  );

  const closeModal = () => {
    if (isModal && props.closeModal) {
      props.closeModal(false);
    }
    setOpen(false);
  };

  return isModal ? (
    <Modal
      open={open}
      disableBackdropClick={true}
      hideBackdrop={false}
      aria-labelledby="confirmationGeolocalization-modal"
      aria-describedby="confirmationGeolocalization-modal-wizard"
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.8)"
        }
      }}
    >
      <Container isModal={true}>
        {positionUnavailable ? (
          <Body
            showIcon={true}
            showLoader={false}
            positionUnavailable={positionUnavailable}
            closeModal={closeModal}
          />
        ) : (
          <>
            <SubContainer>
              <CloseIcon
                style={{
                  margin: "14px 15px 0px 0px",
                  cursor: "pointer",
                  float: "right"
                }}
                onClick={() => closeModal()}
              />
            </SubContainer>
            <Body
              showIcon={true}
              showLoader={false}
              positionUnavailable={positionUnavailable}
              closeModal={closeModal}
            />
          </>
        )}
      </Container>
    </Modal>
  ) : (
    <LoaderContainer
      enableResponsive={enableResponsive}
      showLoader={!finishGetCurrentPosition}
    >
      <Body
        showIcon={false}
        showLoader={!finishGetCurrentPosition}
        positionUnavailable={positionUnavailable}
        closeModal={closeModal}
        enableResponsive={enableResponsive}
      />
    </LoaderContainer>
  );
}

export default LockedPositionModal;
