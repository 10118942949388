import { combineReducers } from "redux";
import account from "./account/reducer";
import news from "./news/reducer";
import workspaces from "./workspaces/reducer";
import instrumentDetails from "./instrumentDetails/reducer";
import newsDetails from "./newsDetails/reducer";
import ticker from "./ticker/reducer";
import trading from "./trading/reducer";
import type { Reducers } from "./types";

const reducers: Reducers = {
  account,
  news,
  workspaces,
  instrumentDetails,
  newsDetails,
  ticker,
  trading
};

const combined: any = combineReducers(reducers);

export default combined;
