export function getApiWorkspaceUrl() {
  return process.env.REACT_APP_WORKSPACE_URL;
}

export function getApiMarketUrl() {
  return process.env.REACT_APP_MARKET_URL;
}

export function getApiNewsUrl() {
  return process.env.REACT_APP_NEWS_URL;
}

export function getApiAlarms() {
  return process.env.REACT_APP_MARKET_URL;
}

export function getApiPortafolio() {
  return process.env.REACT_APP_ALARMS_APP_API_URL;
}

export function getApiOrdenManagment() {
  return process.env.REACT_APP_MANAGEMENT_APP_API_URL;
}

export function getApiCalendar() {
  return process.env.REACT_APP_CALENDAR_URL;
}
