// @flow

import ACTION_TYPES from "./actionTypes";
import type { InstrumentDetailsReducer } from "./types";

const initialState = {
  isLoading: false,
  selectedInstrument: "",
  widgetTypeId: null,
  marketTypeId: null,
  valueTypeId: null,
  exchangeId: null,
  instrumentDetails: null,
  row: null,
  error: "",
  instrumentId: "",
  instrumentSelectedAlarm: "",
  instrumentSelectedDescription: "",
  instrumentSelectedOperation: null,
  instrumentSelectedOperationType: "",
  alarmsCompleted: [],
  chartLoadCompleted: false,
  selectedInstrumentIntraday: null
};

const reducer: InstrumentDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.START_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case ACTION_TYPES.STOP_LOADING:
      return {
        ...state,
        isLoading: false
      };
    case ACTION_TYPES.SET_INSTRUMENT_SUCCESS:
      return {
        ...state,
        selectedInstrument: action.selectedInstrument,
        widgetTypeId: action.widgetTypeId,
        marketTypeId: action.marketTypeId,
        valueTypeId: action.valueTypeId,
        exchangeId: action.exchangeId,
        row: action.row,
        instrumentId: action.instrumentId
      };
    case ACTION_TYPES.GET_INSTRUMENT_SUCCESS:
      return {
        ...state,
        instrumentDetails: action.instrumentDetails
      };
    case ACTION_TYPES.GET_INSTRUMENT_FAILED:
      return {
        ...state,
        error: action.errors
      };
    case ACTION_TYPES.SET_INSTRUMENT_ALARM_SUCCESS:
      return {
        ...state,
        instrumentSelectedAlarm: action.instrumentSelectedAlarm,
        instrumentSelectedDescription: action.instrumentSelectedDescription
      };
    case ACTION_TYPES.SET_INSTRUMENT_OPERATION_SUCCESS:
      return {
        ...state,
        instrumentSelectedOperation: action.instrumentSelectedOperation,
        instrumentSelectedOperationType: action.instrumentSelectedOperationType
      };
    case ACTION_TYPES.SET_ALARMS_COMPLETED:
      return {
        ...state,
        alarmsCompleted: action.alarmsCompleted
      };
    case ACTION_TYPES.SET_CHART_LOAD_COMPLETED:
      return {
        ...state,
        chartLoadCompleted: action.chartLoadCompleted
      };
    case ACTION_TYPES.SET_INSTRUMENT_INTRADAY:
      return {
        ...state,
        selectedInstrumentIntraday: action.selectedInstrumentIntraday
      };
    default:
      return state;
  }
};

export default reducer;
