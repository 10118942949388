import i18n from "i18n/index";
import moment from "moment";
import { formats } from "components/Table/MaterialTable/constants";
import {
  ALMOST_WHITE,
  INCREASING_GREEN,
  TORCH_RED,
  WHITE
} from "constants/colors";
import {
  CDT_TIMEZONE,
  USA_LOCALE,
  EncodedCharacters,
  InstrumentPrefixes,
  widgetType,
  DATE_FORMAT,
  DATE_FORMAT_INITIAL,
  sortTypes,
  cardTypes,
  specialCharacters,
  MarketTypesArray,
  keyStorage,
  instrumentDefaultForAnalitycs,
  decimalsDefaultAlarms,
  uniqueKeysFundsToLS,
  uniqueKeysInternationalBags,
  CCL1_INSTRUMENT_NAME
} from "constants/globals";
import { CONFIGURATION_MAX_CHARS } from "apis/constants";
import type {
  getYearFromDateFunc,
  getDateStringFromDateFunc,
  getHourStringFromDateFunc,
  calculateNewsTimestampFunc,
  getJulianDateStringFromDateFunc,
  getHourStringFromNumberFunc,
  colorFormattingFunc,
  currentTimestampFunc,
  formatNumberFunc
} from "./types";

export const calculateNewsTimestamp: calculateNewsTimestampFunc = timestamp => {
  const date = new Date();
  const currentTime = date.getHours() * 60 + date.getMinutes();

  const hour = timestamp ? timestamp.slice(0, 2) : null;
  const minutes = timestamp ? timestamp.slice(3, 5) : null;

  if (!hour || !minutes) {
    return null;
  }

  const timestampTime = Number(hour) * 60 + Number(minutes);

  let diff = currentTime - timestampTime;
  diff = diff < 0 ? 1440 + diff : diff;

  return diff < 60 && diff > 0
    ? `${diff} ${i18n.t("timestampMinutes")}`
    : `${hour}:${minutes}`;
};

export const calculatePassedTimeInHours: calculateNewsTimestampFunc = timestamp => {
  const date = new Date();
  const currentTime = date.getHours() * 60 + date.getMinutes();

  const hour = timestamp ? timestamp.slice(0, 2) : null;
  const minutes = timestamp ? timestamp.slice(3, 5) : null;

  if (!hour || !minutes) {
    return null;
  }

  const timestampTime = Number(hour) * 60 + Number(minutes);

  let diff = currentTime - timestampTime;
  diff = diff < 0 ? 1440 + diff : diff;

  if (diff > 0 && diff < 60) {
    return `${diff} ${i18n.t("timestampMinutes")}`;
  }
  if (diff >= 60 && diff < 120) {
    return `${(diff / 60).toFixed(0)} ${i18n.t("hour")}`;
  }

  return `${(diff / 60).toFixed(0)} ${i18n.t("hours")}`;
};

export const getYearFromDate: getYearFromDateFunc = date =>
  date && date.slice(0, 4);

export const getDateStringFromDate: getDateStringFromDateFunc = date => {
  const dateFormat = new Date(`${date.slice(0, 11)}00:00:00`);

  let dateMonth = dateFormat.getMonth() + 1;
  let dateDay = dateFormat.getDate();
  const dateYear = dateFormat.getFullYear();

  if (dateMonth < 10) dateMonth = `0${dateMonth}`;
  if (dateDay < 10) dateDay = `0${dateDay}`;

  return `${dateDay}/${dateMonth}/${dateYear}`;
};
export const getJulianDateStringFromDate: getJulianDateStringFromDateFunc = (
  dateJulian: number
) => {
  let date = new Date();

  if (!dateJulian) {
    // eslint-disable-next-line no-param-reassign
    dateJulian = 0;
  }
  date = new Date(1960, 12, 31);
  date = new Date(date.setDate(dateJulian));

  let dateMonth = date.getMonth() + 1;
  let dateDay = date.getDate();
  const dateYear = date.getFullYear();

  if (dateMonth < 10) dateMonth = `0${dateMonth}`;
  if (dateDay < 10) dateDay = `0${dateDay}`;

  return `${dateDay}-${dateMonth}-${dateYear}`;
};

export const getHourStringFromNumber: getHourStringFromNumberFunc = (
  time: string
) => {
  let timeString = "";
  let hour;
  let minutes;
  if (time.length <= 5) {
    hour = time ? time.slice(0, 1) : null;
    minutes = time ? time.slice(1, 3) : null;
    timeString = `0${hour || "0"}:${minutes || "00"}`;
  }
  if (time.length >= 6) {
    hour = time ? time.slice(0, 2) : null;
    minutes = time ? time.slice(2, 4) : null;
    timeString = `${hour || "00"}:${minutes || "00"}`;
  }
  return timeString;
};

export const getHourStringFromDate: getHourStringFromDateFunc = date =>
  date && date.slice(11, 16);

export const formatHourFromNumber = (time: string | number) => {
  let timeString = time.toString();

  timeString = timeString.length === 3 ? `0${timeString}` : timeString;

  return `${timeString.substring(0, 2)}:${timeString.substring(2, 4)}`;
};

export const currentTimestamp: currentTimestampFunc = () =>
  `${new Date().getHours()}:${new Date().getMinutes()} ${CDT_TIMEZONE}`;

export const colorFormatting: colorFormattingFunc = (value, defaultColor) => {
  if (value === 0) {
    return defaultColor || WHITE;
  }
  if (value > 0) {
    return INCREASING_GREEN;
  }
  if (value < 0) {
    return TORCH_RED;
  }
  return "";
};

export const formatNumber: formatNumberFunc = (
  value,
  isInteger = false,
  numberOfDecimals = 2
) => {
  return isInteger
    ? value.toLocaleString(USA_LOCALE)
    : value.toLocaleString(USA_LOCALE, {
        minimumFractionDigits: numberOfDecimals || 2,
        maximumFractionDigits: numberOfDecimals || 2
      });
};

// "TODO: 06.05.20 (A.G.) join functions formatNumber && formatWithoutRounding"
export const formatWithoutRounding: formatNumberFunc = (
  value,
  isInteger = false,
  numberOfDecimals = 2
) => {
  if (!value) {
    return "0";
  }
  if (isInteger) {
    return value.toLocaleString(USA_LOCALE);
  }

  const newValue =
    value.toString().indexOf(".") === -1
      ? value
      : parseFloat(
          value
            .toString()
            .substring(0, value.toString().indexOf(".") + numberOfDecimals + 1)
        );

  return newValue.toLocaleString(USA_LOCALE, {
    minimumFractionDigits: numberOfDecimals || 2,
    maximumFractionDigits: numberOfDecimals || 2
  });
};

export const formatFigure = (value: number) => {
  const figures = value
    .toString()
    .replace("-", "")
    .split(".")[0];

  if (figures.length < 3) {
    return formatWithoutRounding(value, false, 4);
  }
  if (figures.length === 3) {
    return formatWithoutRounding(value, false, 2);
  }
  return formatWithoutRounding(parseInt(value, 10), true);
};

// date.toLocaleString(MEXICAN_LOCALE) returns dd/MM/yyyy. This method returns dd-MM-yyyy
export const formatDate = (
  date: Date | string,
  initialFormat?: string = DATE_FORMAT_INITIAL,
  targetFormat?: string = DATE_FORMAT
) => {
  moment.updateLocale("es");
  // $FlowFixMe
  return moment(date, initialFormat)
    .utc()
    .format(targetFormat);
};

export const getPrefixForLs = (widgetTypeId: string) => {
  switch (widgetTypeId) {
    case widgetType.bmvStocks:
      return InstrumentPrefixes.BmvStockInstrumentPrefixForLS;
    case widgetType.currencies:
      return InstrumentPrefixes.CurrencyInstrumentPrefixForLS;
    case widgetType.indexes:
      return InstrumentPrefixes.IndexInstrumentPrefixForLS;
    case widgetType.generalIndicators:
      return InstrumentPrefixes.GeneralIndicatorsInstrumentPrefixForLS;
    case widgetType.bmvEquitySIC:
      return InstrumentPrefixes.BmvEquitySicInstrumentPrefixForLS;
    case widgetType.SICBIVA:
      return InstrumentPrefixes.SICBIVAInstrumentPrefixForLS;
    case widgetType.nyseNasdaq:
      return InstrumentPrefixes.SICBIVAInstrumentPrefixForLS;
    default:
      return InstrumentPrefixes.BmvStockInstrumentPrefixForLS;
  }
};

export const getPrefixForApi = (widgetTypeId?: string) => {
  switch (widgetTypeId) {
    case widgetType.bmvStocks:
      return InstrumentPrefixes.BmvStockInstrumentPrefixForApi;
    case widgetType.currencies:
      return InstrumentPrefixes.CurrencyInstrumentPrefixForApi;
    case widgetType.indexes:
      return InstrumentPrefixes.IndexInstrumentPrefixForApi;
    case widgetType.bmvEquitySIC:
      return InstrumentPrefixes.BmvEquitySicInstrumentPrefixForApi;
    case widgetType.SICBIVA:
      return InstrumentPrefixes.SICBIVAInstrumentPrefixForApi;
    default:
      return InstrumentPrefixes.BmvStockInstrumentPrefixForApi;
  }
};

export const generateUniqueKey = (
  marketTypeId: string,
  valueTypeId: string,
  exchangeId: string,
  symbol: string
) => {
  return `${marketTypeId}/${valueTypeId}/${exchangeId}/${symbol}/`;
};

export const formatInstrumentForLS = (
  instrument: string,
  widgetTypeId?: string,
  marketType?: number | string,
  valueType?: number | string,
  stockExchange?: number | string
): string => {
  let subscriptionFormat;

  if (!instrument) {
    return "";
  }
  if (
    marketType !== undefined &&
    valueType !== undefined &&
    stockExchange !== undefined
  ) {
    subscriptionFormat = `${marketType}.${valueType}.${stockExchange}.${instrument
      .replace("*", EncodedCharacters.ASTERISK)
      .replace("&", EncodedCharacters.AMPERSAND)
      .replace("-", EncodedCharacters.DASH)
      .replace(/\s/g, EncodedCharacters.SPACE)
      .replace(".BI", "")}`;
  } else {
    subscriptionFormat =
      (widgetTypeId ? getPrefixForLs(widgetTypeId) : "") +
      instrument
        .replace("*", EncodedCharacters.ASTERISK)
        .replace("&", EncodedCharacters.AMPERSAND)
        .replace("-", EncodedCharacters.DASH)
        .replace(/\s/g, EncodedCharacters.SPACE)
        .replace(".BI", "");
  }
  return subscriptionFormat;
};

export const formatUniqueKeyToLS = (uniqueKey: string) =>
  uniqueKey
    .replace(/\//g, ".")
    .replace("*", EncodedCharacters.ASTERISK)
    .replace("&", EncodedCharacters.AMPERSAND)
    .replace("-", EncodedCharacters.DASH)
    .replace(/\s/g, EncodedCharacters.SPACE)
    .replace(".BI", "");

export const formatInstrumentForFoundsPosition = (symbol: string): string[] => {
  const symbolFormated = symbol
    .replace("*", EncodedCharacters.ASTERISK)
    .replace("&", EncodedCharacters.AMPERSAND)
    .replace("-", EncodedCharacters.DASH)
    .replace(/\s/g, EncodedCharacters.SPACE);

  return uniqueKeysFundsToLS.map(uniqueKey => `${uniqueKey}${symbolFormated}`);
};

export const formatInstrumentWithPositionFieldsForLS = (
  instrument: string,
  marketType?: number | string,
  valueType?: number | string,
  stockExchange?: number | string
): string =>
  InstrumentPrefixes.InstrumentPrefixForLSPositionFields +
  formatInstrumentForLS(instrument, "", marketType, valueType, stockExchange);

export const sortArrayById = (data: Array<Object>): Array<Object> =>
  data.sort((a, b) => b.id - a.id);

export const sortArrayOfObjects = (
  data: Array<Object>,
  type: string,
  property: string
) => {
  let sortedData = [];
  if (!data.some(row => row[property])) {
    return data;
  }

  if (type === sortTypes.ASCENDING) {
    // TO DO: enable flow below (V.I. 31.03.2020)
    // $FlowFixMe
    sortedData = [...data].sort((a, b) => {
      let first = a[property];
      let seccond = b[property];
      if (property === "uniqueInstrumentKey") {
        let instrumentKeys = first.split("/");
        if (instrumentKeys && instrumentKeys.length) {
          first = instrumentKeys[instrumentKeys.length - 1];
        }
        instrumentKeys = seccond.split("/");
        if (instrumentKeys && instrumentKeys.length) {
          seccond = instrumentKeys[instrumentKeys.length - 1];
        }
      }
      // if property is null it should be placed after all others
      if (!first && first !== 0) {
        return 1;
      }
      if (!seccond && seccond !== 0) {
        return -1;
      }
      return first < seccond ? -1 : 1;
    });
  } else {
    // TO DO: enable flow below (V.I. 31.03.2020)
    // $FlowFixMe
    sortedData = [...data].sort((a, b) => {
      let first = a[property];
      let seccond = b[property];
      if (property === "uniqueInstrumentKey") {
        let instrumentKeys = first.split("/");
        if (instrumentKeys && instrumentKeys.length) {
          first = instrumentKeys[instrumentKeys.length - 1];
        }
        instrumentKeys = seccond.split("/");
        if (instrumentKeys && instrumentKeys.length) {
          seccond = instrumentKeys[instrumentKeys.length - 1];
        }
      }
      // if property is null it should be placed after all others
      if (!first && first !== 0) {
        return 1;
      }
      if (!seccond && seccond !== 0) {
        return -1;
      }
      return first < seccond ? 1 : -1;
    });
  }

  return sortedData;
};

export const comparer = (otherArray: Array<any>) => (current: Object) => {
  return (
    otherArray.filter(other => {
      return other.descripcion === current.descripcion;
    }).length === 0
  );
};

export const bodyToString = (body: Object): string => {
  if (!body) {
    return "";
  }
  const bodyString = JSON.stringify(body);

  if (bodyString.length > CONFIGURATION_MAX_CHARS) {
    return "";
  }
  return bodyString;
};

export const getScreenWidth = () => {
  if (document.body) {
    return Math.max(document.body.scrollWidth, document.body.offsetWidth);
  }

  if (document.documentElement) {
    return Math.max(
      document.documentElement.scrollWidth,

      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }

  return 0;
};

export const getScreenHeight = () => {
  if (document.body) {
    return Math.max(document.body.scrollHeight, document.body.offsetHeight);
  }

  if (document.documentElement) {
    return Math.max(
      document.documentElement.scrollHeight,

      document.documentElement.offsetHeight,
      document.documentElement.clientHeight
    );
  }

  return 0;
};

export const defaultTableTitle = (marketId: number | string): string => {
  let title = "";
  const marketType = MarketTypesArray.find(
    market => market.id === Number(marketId)
  );
  if (!marketType) {
    title = i18n.t("tableTitle");
  } else {
    title = marketType.name;
  }
  return title;
};

export const defaultCardTitle = (newCard: Object): string => {
  let title = "";
  switch (newCard.type) {
    case cardTypes.TABLE:
    case cardTypes.PREDEFINED_TABLE:
      title = defaultTableTitle(
        Number(newCard.marketId || newCard.predefinedTableId)
      );
      break;
    case cardTypes.CHART:
      title = i18n.t("chartTitle");
      break;
    case cardTypes.NEWS:
      title = i18n.t("newsTitle");
      break;
    case cardTypes.GEO_HEATMAP:
      title = i18n.t("geoHeatmapTitle");
      break;
    case cardTypes.TREEMAP:
      title = i18n.t("treemap");
      break;
    case cardTypes.ECONOMIC_CALENDAR:
      title = i18n.t("economicCalendar");
      break;
    default:
      break;
  }
  return title;
};

export const formatInstrumentPPForLS = (
  instrument: string,
  lado: string
): string => {
  if (!instrument) {
    return "";
  }

  const subscriptionFormat = `PP${
    lado ? `.${lado}` : ""
  }.32780.120.1010.${instrument
    .replace("*", EncodedCharacters.ASTERISK)
    .replace("&", EncodedCharacters.AMPERSAND)
    .replace("-", EncodedCharacters.DASH)
    .replace(/\s/g, EncodedCharacters.SPACE)
    .replace(".BI", "")}`;

  return subscriptionFormat;
};

export const IsJsonString = (str: Object): boolean => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
export const getTimestampFromString = (str: string): number =>
  +new Date(...str.split(specialCharacters.HYPHEN_MINUS).reverse());

export const formatTime = (value: string) => {
  let newVal = value;
  if (value) {
    const partHour = value.split(":");

    if (
      partHour[0] &&
      partHour[0].length === 1 &&
      parseInt(partHour[0], 10) < 10
    ) {
      partHour[0] = "0".concat(partHour[0]);
    }

    newVal = partHour[0].concat(":").concat(partHour[1]);

    if (newVal.length <= 5) {
      newVal = newVal.concat(":00");
    }
  } else {
    newVal = value;
  }
  return newVal;
};

export const getInstrumentForAnalitycs = () => {
  if (localStorage.getItem(keyStorage.instrumentAnalitycs)) {
    return JSON.parse(
      localStorage.getItem(keyStorage.instrumentAnalitycs) || "{}"
    );
  }
  return instrumentDefaultForAnalitycs;
};

export const getSelectedContract = () => {
  if (sessionStorage.getItem(keyStorage.selectedContract)) {
    return sessionStorage.getItem(keyStorage.selectedContract) || "";
  }
  return "";
};

export const formatValue = (
  value: number,
  format: string,
  numberOfDecimals: number = 2,
  isFieldNotZeros?: boolean,
  newFormatDate?: string
): string | number => {
  const zeroWithDecimals = "0.00";
  let newVal;

  if (value === null) {
    return "";
  }

  switch (format) {
    case formats.FLOAT:
      if (value === 0) {
        newVal = zeroWithDecimals;
      } else {
        newVal = formatWithoutRounding(
          parseFloat(value),
          false,
          numberOfDecimals
        );
      }

      break;
    case formats.INT:
      newVal = formatNumber(parseInt(value, 10), true);
      break;
    case formats.STRING:
      newVal = value;
      break;
    case formats.FIGURE:
      newVal = formatFigure(parseFloat(value));
      break;
    case formats.DATE:
      if (String(value) === "31-12-1969" || String(value) === "31-12-1960") {
        return "-";
      }
      newVal = value ? formatDate(String(value)) : "-";
      break;
    case formats.DATETIME:
      newVal =
        value && String(value).length > 10
          ? formatDate(new Date(value), DATE_FORMAT_INITIAL, newFormatDate)
          : value;
      break;
    default:
      newVal = formatNumber(value, true);
      break;
  }
  if (isFieldNotZeros && (newVal === 0 || newVal === "0.00")) {
    return "-";
  }
  return newVal;
};

export const getMondayOfCurrentWeek = () => {
  const date = new Date();
  const day = date.getDay();
  const prevMonday = new Date();
  if (date.getDay() === 0) {
    prevMonday.setDate(date.getDate() - 7);
  } else {
    prevMonday.setDate(date.getDate() - (day - 1));
  }

  return prevMonday;
};

export const getSundayOfCurrentWeek = () => {
  const today = new Date();
  const first = today.getDate() - today.getDay() + 1;
  const last = first + 6;

  const sunday = new Date(today.setDate(last));

  return sunday;
};

export const checkTime = (i: number) => {
  return i < 10 ? `0${i}` : i;
};

export const formatTwoDecimals = (value: any) => {
  return parseFloat(value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
};

export const getValueWithPrecision = (value: number, precision: number) => {
  return parseFloat(value).toFixed(value % 1 === 0 ? 0 : precision);
};

export const getFormatDecimalsFromAlarms = (value: number) => {
  const numberValue = Number(value);
  if (numberValue % 1 === 0) {
    return 0;
  }
  return decimalsDefaultAlarms;
};

export const filterDate = (nonWorkingDays: Array<any>, date: any) => {
  const tempFormatDate = new Date(date);
  if (nonWorkingDays && nonWorkingDays.length > 0) {
    const day = `0${tempFormatDate.getDate()}`.slice(-2);
    const month = `0${tempFormatDate.getMonth() + 1}`.slice(-2);
    const stringDate = `${tempFormatDate.getFullYear()}-${month}-${day}`;
    return !nonWorkingDays.includes(stringDate);
  }
  return tempFormatDate.getDay() !== 0 && tempFormatDate.getDay() !== 6;
};

export const formatDateMonthYear = (value: any) => {
  const date = new Date(value);
  const month = date.getUTCMonth();
  const year = date.getFullYear();
  const months = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Se",
    "Oct",
    "Nov",
    "Dic"
  ];

  return `${months[month]}-${year}`;
};

export const isWeekday = (date: Date, nonWorkingDays: Array<any>) => {
  const tmpFormatDate = new Date(date);
  if (nonWorkingDays && nonWorkingDays.length > 0) {
    const day = `0${tmpFormatDate.getDate()}`.slice(-2);
    const month = `0${tmpFormatDate.getMonth() + 1}`.slice(-2);
    const stringDate = `${tmpFormatDate.getFullYear()}-${month}-${day}`;
    return !nonWorkingDays.includes(stringDate);
  }
  return tmpFormatDate.getDay() !== 0 && tmpFormatDate.getDay() !== 6;
};

export const htmlEntitiesDecode = (text: string) => {
  return text
    .replace(/&ntilde;/g, "ñ")
    .replace(/&Ntilde;/g, "Ñ")
    .replace(/&amp;/g, "&")
    .replace(/&Ntilde;/g, "Ñ")
    .replace(/&ntilde;/g, "ñ")
    .replace(/&Ntilde;/g, "Ñ")
    .replace(/&Agrave;/g, "À")
    .replace(/&Aacute;/g, "Á")
    .replace(/&Acirc;/g, "Â")
    .replace(/&Atilde;/g, "Ã")
    .replace(/&Auml;/g, "Ä")
    .replace(/&Aring;/g, "Å")
    .replace(/&AElig;/g, "Æ")
    .replace(/&Ccedil;/g, "Ç")
    .replace(/&Egrave;/g, "È")
    .replace(/&Eacute;/g, "É")
    .replace(/&Ecirc;/g, "Ê")
    .replace(/&Euml;/g, "Ë")
    .replace(/&Igrave;/g, "Ì")
    .replace(/&Iacute;/g, "Í")
    .replace(/&Icirc;/g, "Î")
    .replace(/&Iuml;/g, "Ï")
    .replace(/&ETH;/g, "Ð")
    .replace(/&Ntilde;/g, "Ñ")
    .replace(/&Ograve;/g, "Ò")
    .replace(/&Oacute;/g, "Ó")
    .replace(/&Ocirc;/g, "Ô")
    .replace(/&Otilde;/g, "Õ")
    .replace(/&Ouml;/g, "Ö")
    .replace(/&Oslash;/g, "Ø")
    .replace(/&Ugrave;/g, "Ù")
    .replace(/&Uacute;/g, "Ú")
    .replace(/&Ucirc;/g, "Û")
    .replace(/&Uuml;/g, "Ü")
    .replace(/&Yacute;/g, "Ý")
    .replace(/&THORN;/g, "Þ")
    .replace(/&szlig;/g, "ß")
    .replace(/&agrave;/g, "à")
    .replace(/&aacute;/g, "á")
    .replace(/&acirc;/g, "â")
    .replace(/&atilde;/g, "ã")
    .replace(/&auml;/g, "ä")
    .replace(/&aring;/g, "å")
    .replace(/&aelig;/g, "æ")
    .replace(/&ccedil;/g, "ç")
    .replace(/&egrave;/g, "è")
    .replace(/&eacute;/g, "é")
    .replace(/&ecirc;/g, "ê")
    .replace(/&euml;/g, "ë")
    .replace(/&igrave;/g, "ì")
    .replace(/&iacute;/g, "í")
    .replace(/&icirc;/g, "î")
    .replace(/&iuml;/g, "ï")
    .replace(/&eth;/g, "ð")
    .replace(/&ntilde;/g, "ñ")
    .replace(/&ograve;/g, "ò")
    .replace(/&oacute;/g, "ó")
    .replace(/&ocirc;/g, "ô")
    .replace(/&otilde;/g, "õ")
    .replace(/&ouml;/g, "ö")
    .replace(/&oslash;/g, "ø")
    .replace(/&ugrave;/g, "ù")
    .replace(/&uacute;/g, "ú")
    .replace(/&ucirc;/g, "û")
    .replace(/&uuml;/g, "ü")
    .replace(/&yacute;/g, "ý")
    .replace(/&thorn;/g, "þ")
    .replace(/&yuml;/g, "ÿ")
    .replace(/&ldquo;/g, '"')
    .replace(/&rdquo;/g, '"');
};

export const arrayValidation = (array: Array<Object>) => {
  return array && Array.isArray(array) && array.length;
};

export const tableSort = (
  listItems: Array<Object>,
  order: string,
  orderBy: string
) => {
  if (!order || !orderBy) {
    return listItems;
  }

  const newArrar = [];

  for (let i = 0; i < listItems.length; i += 1) {
    const row = listItems[i];
    const value = row[orderBy];
    newArrar.push({
      ...row,
      [orderBy]: value || ""
    });
  }
  return sortArrayOfObjects(listItems, order, orderBy);
};

export const formatInstrumentsForInternationalBags = (
  symbol: string
): string[] => {
  let formatSymbol = symbol;
  if (symbol === "CCL1") formatSymbol = CCL1_INSTRUMENT_NAME;

  return uniqueKeysInternationalBags.map(value => `${value}${formatSymbol}`);
};

export const getPercentageChangeColor = (percentageChange: number) => {
  let color;
  if (percentageChange === 0) {
    color = ALMOST_WHITE;
  } else if (percentageChange > 0) {
    color = INCREASING_GREEN;
  } else {
    color = TORCH_RED;
  }

  return color;
};
