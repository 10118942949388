import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import i18n from "i18n/index";
import { Container, Bubble, Text } from "./style";

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    top: "50%",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: " 0 auto",
    textAlign: "center",
    minHeight: "100%",
    display: "table-cell",
    verticalAlign: "middle",
    outline: "none"
  },
  containerBubble: {
    position: "absolute",
    top: "calc(50% - 5px)",
    left: "calc(50% - 5px)",
    transformOrigin: "-150% 50%"
  },
  firstChild: {
    transform: "translateX(200%) rotate(-90deg)",
    animationDelay: "-1.5s"
  },
  secondChild: {
    transform: "translateX(200%) rotate(-45deg)",
    animationDelay: "-1.375s"
  },
  thirdChild: {
    transform: "translateX(200%)",
    animationDelay: "-1.25s"
  },
  fourthChild: {
    transform: "translateX(200%) rotate(45deg)",
    animationDelay: "-1.125s"
  },
  fifthChild: {
    transform: "translateX(200%) rotate(90deg)",
    animationDelay: "-1s"
  },
  sixthChild: {
    transform: "translateX(200%) rotate(135deg)",
    animationDelay: "-0.875s"
  },
  seventhChild: {
    transform: "translateX(200%) rotate(180deg)",
    animationDelay: "-0.750s"
  },
  eighthChild: {
    transform: "translateX(200%) rotate(225deg)",
    animationDelay: "-0.625s"
  }
}));

type Props = {
  isGetGeolocation: boolean,
  isModal?: boolean
};

export default function GeolocationLoader(props: Props) {
  const { isGetGeolocation, isModal } = props;
  const classes = useStyles();

  const getBody = () => {
    return (
      <div className={classes.root}>
        <Container>
          <div className={`${classes.firstChild} ${classes.containerBubble}`}>
            <Bubble />
          </div>
          <div className={`${classes.secondChild} ${classes.containerBubble}`}>
            <Bubble />
          </div>
          <div className={`${classes.thirdChild} ${classes.containerBubble}`}>
            <Bubble />
          </div>
          <div className={`${classes.fourthChild} ${classes.containerBubble}`}>
            <Bubble />
          </div>
          <div className={`${classes.fifthChild} ${classes.containerBubble}`}>
            <Bubble />
          </div>
          <div className={`${classes.sixthChild} ${classes.containerBubble}`}>
            <Bubble />
          </div>
          <div className={`${classes.seventhChild} ${classes.containerBubble}`}>
            <Bubble />
          </div>
          <div className={`${classes.eighthChild} ${classes.containerBubble}`}>
            <Bubble />
          </div>
        </Container>
        <div>
          <Text>
            {isGetGeolocation
              ? i18n.t("getGeolocalization")
              : i18n.t("setGeolocalization")}
          </Text>{" "}
        </div>
      </div>
    );
  };

  return isModal ? (
    <Modal
      open={true}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.8)"
        }
      }}
      disableBackdropClick={true}
      hideBackdrop={false}
      aria-labelledby="geolocationLoader-modal"
      aria-describedby="geolocationLoader-modal-wizard"
    >
      {getBody()}
    </Modal>
  ) : (
    getBody()
  );
}
