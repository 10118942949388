import {
  ALMOST_WHITE,
  BLACK,
  GRAY_EVEN,
  SHARK,
  SILVER,
  SILVER_LIGHT,
  WHITE_SMOKE
} from "constants/colors";

const defaultSettings = {
  MuiAutocomplete: {
    paper: {
      boxShadow: "none",
      margin: 0,
      borderRadius: "5px 5px 20px 20px",
      padding: 0,
      marginTop: 1,
      elevation: 0,
      width: "calc(100% + 38px)",
      marginLeft: -37
    },
    listbox: {
      margin: 0,
      borderRadius: "5px 5px 20px 20px",
      padding: 0,
      overflow: "auto"
    },
    option: {
      minHeight: "auto",
      margin: 0,
      padding: 0,
      alignItems: "flex-start",
      '&[aria-selected="true"]': {
        backgroundColor: "transparent"
      }
    }
  },
  MuiTooltip: {
    tooltip: {
      borderRadius: 8,
      textAlign: "inherit",
      marginTop: 20,
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 14,
      lineHeight: "133.1%",
      letterSpacing: "0.25px",
      whiteSpace: "break-spaces"
    }
  }
};

const overrides = {
  light: {
    overrides: {
      MuiAutocomplete: {
        popper: {
          margin: 0,
          transform: "none"
        },
        paper: {
          ...defaultSettings.MuiAutocomplete.paper,
          background: WHITE_SMOKE
        },
        listbox: {
          ...defaultSettings.MuiAutocomplete.listbox,
          border: `solid 1px ${SILVER}`,
          "& li:nth-of-type(even)": {
            backgroundColor: "rgba(99,99,102,0.1)"
          },
          "& li:nth-of-type(odd)": {
            backgroundColor: WHITE_SMOKE
          }
        },
        option: {
          ...defaultSettings.MuiAutocomplete.option,
          backgroundColor: SHARK,
          '&[data-focus="true"]': {
            backgroundColor: SHARK
          }
        },
        noOptions: {
          display: "none"
        }
      },
      MuiTooltip: {
        arrow: {
          fontSize: 20,
          color: SHARK,
          "&::before": {
            backgroundColor: WHITE_SMOKE,
            border: `1px solid ${GRAY_EVEN}`
          }
        },
        tooltip: {
          ...defaultSettings.MuiTooltip.tooltip,
          backgroundColor: WHITE_SMOKE,
          color: SHARK,
          border: `1px solid ${GRAY_EVEN}`,
          "& > span": {
            "&.MuiTooltip-arrow": {
              color: GRAY_EVEN
            }
          }
        }
      }
    }
  },
  dark: {
    overrides: {
      MuiAutocomplete: {
        popper: {
          margin: 0,
          transform: "none"
        },
        paper: {
          ...defaultSettings.MuiAutocomplete.paper,
          background: SHARK,
          color: ALMOST_WHITE
        },
        listbox: {
          ...defaultSettings.MuiAutocomplete.listbox,
          border: `solid 1px ${SILVER}`,

          "& li:nth-of-type(even)": {
            backgroundColor: SHARK
          },
          "& li:nth-of-type(odd)": {
            backgroundColor: BLACK
          }
        },
        option: {
          ...defaultSettings.MuiAutocomplete.option,
          backgroundColor: SHARK,
          '&[data-focus="true"]': {
            backgroundColor: SHARK
          }
        },
        noOptions: {
          display: "none"
        }
      },
      MuiTooltip: {
        arrow: {
          fontSize: 20,
          color: SHARK,
          "&::before": {
            backgroundColor: SHARK,
            border: `1px solid ${SILVER_LIGHT}`
          }
        },
        tooltip: {
          ...defaultSettings.MuiTooltip.tooltip,
          backgroundColor: SHARK,
          border: `1px solid ${SILVER_LIGHT}`,
          "& > span": {
            "&.MuiTooltip-arrow": {
              color: SILVER_LIGHT
            }
          }
        }
      }
    }
  }
};

export default overrides;
