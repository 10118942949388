import i18n from "i18n/index";
import {
  WHITE_SMOKE,
  INCREASING_GREEN,
  TORCH_RED,
  LIGHT_GREY
} from "constants/colors";

export const formats = {
  INT: "int",
  FLOAT: "float",
  STRING: "string",
  DATE: "date",
  FIGURE: "figure",
  TIME: "time",
  DATETIME: "datetime"
};

export const alignment = {
  LEFT: "left",
  RIGHT: "right"
};
export const initialColors = {
  background: "transparent",
  text: WHITE_SMOKE,
  noOperation: LIGHT_GREY
};

export const valueIncreaseColors = {
  background: INCREASING_GREEN,
  text: INCREASING_GREEN
};
export const valueDecreaseColors = {
  background: TORCH_RED,
  text: TORCH_RED
};

export const labels = {
  SYMBOL: i18n.t("descripcion"),
  CHANGE_PROCENTAGE: i18n.t("porcentaje"),
  CHANGE_UNIT: i18n.t("variacion"),
  VOLUMEN_TOTAL: i18n.t("volumentotal"),
  VOLUMEN_DONE: i18n.t("volumenhecho"),
  PURCHASE_VALUE: i18n.t("purchaseValue"),
  PURCHASE_VOLUME: i18n.t("purchaseVolume"),
  SALE_VALUE: i18n.t("saleValue"),
  SALE_VOLUME: i18n.t("saleVolume"),
  ISIN: i18n.t("ISIN"),
  PREVIOUS_PRICE_DATE: i18n.t("fechaprecioanterior"),
  DATE: i18n.t("fechaprecioactual"),
  HOUR: i18n.t("hora"),
  FULL_NAME: i18n.t("nombrelargo"),
  PURCHASE: i18n.t("purchase"),
  SALE: i18n.t("sale"),
  PURCHASEVSSALE: i18n.t("purchaseVsSale"),
  SPREAD: i18n.t("spread"),
  VALUE: i18n.t("value"),
  ABSOLUTE_CHANGE: i18n.t("absoluteChange"),
  POSTURE_BID_TIME: i18n.t("postureBidTime"),
  POSTURE_ASK_TIME: i18n.t("postureAskTime"),
  PURCHASE_DATE: i18n.t("purchaseDate"),
  SALE_DATE: i18n.t("saleDate"),
  PREVIOUS_DATE: i18n.t("previousDate"),
  MINIMUM: i18n.t("minimum"),
  PREVIOUS_PRICE: i18n.t("previousPrice"),
  MAXIMUM: i18n.t("maximum"),
  TOTAL_AMOUNT: i18n.t("importetotal"),
  MONTHLY_PRICE: i18n.t("monthlyNumber"),
  YEARLY_PRICE: i18n.t("annualNumber"),
  HALF_YEAR_PRICE: i18n.t("semunalNumber"),
  REND_EN_ANO: i18n.t("rendEnAnoNumber"),
  OPENING_PRICE: i18n.t("precioapertura"),
  ACTUAL_PRICE: i18n.t("actualPrice"),
  PERIODICITY: i18n.t("periodicity"),
  DESCRIPTION: i18n.t("fulldescription"),
  LIQUID_PRICE: i18n.t("precioliquido"),
  PREVIUS_LIQUID_PRICE: i18n.t("precioanteriorliquido"),
  COMULATIVE_VOLUME: i18n.t("volumenacumulado"),
  MAX_VALUE: i18n.t("valormaxdia"),
  MIN_VALUE: i18n.t("valormindia"),
  OPEN_CONTRACTS: i18n.t("contratosabiertos"),
  NUMBER_OPER: i18n.t("numOfOperations"),
  POSTURE_SALE_DATE: i18n.t("fechaposturaventa"),
  POSTURE_SALE_HOUR: i18n.t("horapventa"),
  POSTURE_PURCHASE_DATE: i18n.t("fechaposturacompra"),
  POSTURE_PURCHASE_HOUR: i18n.t("horapcompra"),
  EXPIRATION_DATE: i18n.t("fechaexpiracion"),
  OPEN_PRICE: i18n.t("precioapertura"),
  PREVIUS_PRICE: i18n.t("previousPrice"),
  VOLUME: i18n.t("volume"),
  INNER_VALUE: i18n.t("innerValue"),
  PPP_VALUE: i18n.t("pppValue"),
  PPP_NUMBER: i18n.t("pppNumber"),
  BUYER: i18n.t("buyer"),
  LAST_DATE: i18n.t("lastDate"),
  LAST_TIME: i18n.t("hora"),
  PEAK_AMOUNT: i18n.t("peakAmount"),
  PURCHASE_TIME: i18n.t("purchaseTime"),
  OPERATION: i18n.t("operation"),
  MAXIMUM_12_MONTHS: i18n.t("maxPrice12Months"),
  MINIMUM_12_MONTHS: i18n.t("minPrice12Months"),
  PEAK_PRICE: i18n.t("peakPrice"),
  SELLER: i18n.t("seller"),
  PEAK_VOLUME: i18n.t("peakVolume"),
  AVERAGE_PRICE: i18n.t("averagePrice"),
  MONTHLY_YIELD: i18n.t("monthlyYield"),
  ANNUAL_YIELD: i18n.t("annualYield"),
  WEEKLY_YIELD: i18n.t("weeklyYield"),
  YIELD_PER_YEAR: i18n.t("yieldPerYear"),
  MAX_VOLUME_52S: i18n.t("maxVol52s"),
  MIN_VOLUME_52S: i18n.t("minVol52s"),
  AVERAGE_VOLUME_3A: i18n.t("averageVol3a"),
  AVERAGE_VOLUME_3M: i18n.t("averageVol3m")
};
