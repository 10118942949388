import { createGlobalStyle } from "styled-components";

const ScrollbarStyleContainer = createGlobalStyle`
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    &::-webkit-scrollbar-thumb:horizontal {
      border-radius: 12px;
      border: 8px solid transparent;
      background-color: ${props =>
        props.theme.palette.SPUN_PEARL_SILVER_METALLIC};
    }
    &::-webkit-scrollbar-thumb:vertical {
      border-radius: 12px;
      background-color: ${props =>
        props.theme.palette.SPUN_PEARL_SILVER_METALLIC};
      border: 8px solid transparent;
    }

    &::-webkit-scrollbar-track:horizontal {
      border-radius: 12px;
      background-repeat: repeat-x;
      background-color: ${props => props.theme.palette.PATTENS_BLUE_GRAY_EVEN};
    }
    &::-webkit-scrollbar-track:vertical {
      border-radius: 12px;
      background-repeat: repeat-y;
      background-color: ${props => props.theme.palette.PATTENS_BLUE_GRAY_EVEN};
    }
    &::-webkit-scrollbar-track:vertical {
      margin-bottom: 0px;
    }
    &::-webkit-scrollbar-button:start:decrement{
      display: block;
    }
    &::-webkit-scrollbar-button:end:increment {
      display: block;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
`;

export default ScrollbarStyleContainer;
