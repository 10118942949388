// @flow

import {
  getToken,
  getSSOToken,
  fetchNext,
  refreshToken,
  checkHAService
} from "../utils/request";
import type { LoginData, TokenResponse } from "../constants/types";
import requestTypes from "../constants/requestTypes";

export default class AccountApi {
  static obtainToken = (
    credentials: LoginData
  ): Promise<{ data: TokenResponse }> => getToken("/login", credentials);

  static logout = (): Promise<{ data: TokenResponse }> => {
    return fetchNext(requestTypes.POST, `/logout`);
  };

  static refreshToken = (): Promise<{ data: TokenResponse }> => refreshToken();

  static checkHAService = (): Promise<{ data: any }> => checkHAService();

  static accessToken = (token: string): Promise<{ data: TokenResponse }> =>
    getSSOToken("/token", token);

  static getImage = (): Promise<{ data: any }> =>
    fetchNext(requestTypes.GET, "/users/image");

  static sendMail = (
    message: string,
    topic: string,
    sic: string,
    requiresFollowUp: boolean
  ) => {
    const reqBody = {
      message,
      topic,
      sic,
      platform: "web",
      requires_follow_up: requiresFollowUp
    };
    return fetchNext(requestTypes.POST, `/support/report_problem`, reqBody);
  };
}
