import ContractApi from "apis/contractApi";
import { keyStorage } from "constants/globals";
import PortfolioApi from "apis/portfolioApi";
import { getSelectedContract, checkTime } from "utils/helpers";
import ACTION_TYPES from "./actionTypes";
import type {
  SetContractsAction,
  getContractsFunc,
  SetPortafolioDetailAction,
  getPortafolioDetailFunc,
  SetPortafolioPerformanceAction,
  getPortafolioPerformanceFunc,
  setContratedServicesFunc,
  setContratedServicesAction
} from "./types";

const defaultPortafolioDetail = {
  markets: [],
  totalValuation: 0
};

const defaultPortafolioPerformance = {
  accumulatedByYear: { amount: "", percentage: "" },
  accumulatedByMonth: { amount: "", percentage: "" },
  performanceByYear: [],
  performanceByMonth: []
};

const getCurrentDate = () => {
  const currentDate = new Date();
  const hour = checkTime(currentDate.getHours());
  const mins = checkTime(currentDate.getMinutes());
  const secs = checkTime(currentDate.getSeconds());
  return `${hour}:${mins}:${secs} hrs`;
};

export const setContracts = (
  contractList: Array<any>,
  contractError: boolean,
  contractLoading: boolean
): SetContractsAction => ({
  type: ACTION_TYPES.SET_CONTRACTS,
  contractList,
  contractError,
  contractLoading
});

const setPortafolioDetail = (
  portafolioDetail,
  portafolioDetailError
): SetPortafolioDetailAction => ({
  type: ACTION_TYPES.SET_PORTAFOLIO_DETAIL,
  portafolioDetail,
  portafolioDetailError
});

const setPortafolioPerformance = (
  portafolioPerformance,
  portafolioPerformanceError
): SetPortafolioPerformanceAction => ({
  type: ACTION_TYPES.SET_PORTAFOLIO_PERFORMANCE,
  portafolioPerformance,
  portafolioPerformanceError
});

const setContratedServices = (
  depthPositionContrated: boolean,
  contratedServices: Object
): setContratedServicesAction => ({
  type: ACTION_TYPES.SET_CONTRATED_SERVICES,
  depthPositionContrated,
  contratedServices
});

export const resetState = () => ({
  type: ACTION_TYPES.RESET_STATE
});

export const setFinishGetCurrentPosition = () => ({
  type: ACTION_TYPES.SET_FINISH_GET_CURRENT_POSITION
});

export const setPositionUnavailable = (positionUnavailable: boolean) => ({
  type: ACTION_TYPES.SET_POSITION_UNAVAILABLE,
  positionUnavailable
});

export const getContracts: getContractsFunc = (callbackFunction: Function) => (
  dispatch,
  getState: Function
) => {
  const { contractList } = getState().trading;
  if (contractList && contractList.length > 0) {
    return;
  }
  dispatch(setContracts([], false, true));
  ContractApi.getContracts()
    .then(({ data }) => {
      if (data && data.length > 0) {
        sessionStorage.setItem(
          keyStorage.selectedContract,
          data[0].contractNumber
        );
        dispatch(setContracts(data, false, false));
        callbackFunction();
      } else {
        dispatch(setContracts(data, true, true));
      }
    })
    .catch(() => {
      dispatch(setContracts([], true, true));
    });
};

export const getPortafolioDetail: getPortafolioDetailFunc = forceRefresh => (
  dispatch,
  getState: Function
) => {
  const { portafolioDetail } = getState().trading;
  if (portafolioDetail && !forceRefresh) {
    return;
  }

  dispatch(setPortafolioDetail(null, false));
  if (getSelectedContract()) {
    PortfolioApi.getPortafolioDetail()
      .then(({ data }) => {
        const currentDate = getCurrentDate();
        const newData = data;
        newData.dataDate = currentDate;
        if (data) {
          dispatch(setPortafolioDetail(newData, false));
        } else {
          dispatch(
            setPortafolioDetail(
              { ...defaultPortafolioDetail, dataDate: currentDate },
              true
            )
          );
        }
      })
      .catch(() => {
        const currentDate = getCurrentDate();
        dispatch(
          setPortafolioDetail(
            { ...defaultPortafolioDetail, dataDate: currentDate },
            true
          )
        );
      });
  }
};

export const getPortafolioPerformance: getPortafolioPerformanceFunc = forceRefresh => (
  dispatch,
  getState: Function
) => {
  const { portafolioPerformance } = getState().trading;
  if (portafolioPerformance && !forceRefresh) {
    return;
  }

  dispatch(setPortafolioPerformance(null, false));
  if (getSelectedContract()) {
    PortfolioApi.getPortafolioPerformance()
      .then(({ data }) => {
        const newData = data;
        if (data) {
          dispatch(setPortafolioPerformance(newData, false));
        } else {
          dispatch(
            setPortafolioPerformance({ ...defaultPortafolioPerformance }, true)
          );
        }
      })
      .catch(() => {
        dispatch(
          setPortafolioPerformance({ ...defaultPortafolioPerformance }, true)
        );
      });
  }
};

export const getContratedServices: setContratedServicesFunc = (
  forceRefresh: boolean = false
) => (dispatch, getState: Function) => {
  const { contratedServices } = getState().trading;
  if (contratedServices && !forceRefresh) {
    return;
  }

  ContractApi.getContractServices()
    // eslint-disable-next-line
    .then(({ data }) => {
      const service = data.find(
        element => element.name === "PROFUNDIDAD DE LIBRO"
      );
      if (service) {
        dispatch(setContratedServices(service.contracted, data));
      } else {
        dispatch(setContratedServices(false, data));
      }
    })
    .catch(() => {});
};
