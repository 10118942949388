// @flow

const ACTION_TYPES = {
  START_LOADING: "START_LOADING",
  STOP_LOADING: "STOP_LOADING",
  SET_INSTRUMENT_SUCCESS: "SET_INSTRUMENT_SUCCESS",
  SET_INSTRUMENT_FAILED: "SET_INSTRUMENT_FAILED",
  GET_INSTRUMENT_SUCCESS: "GET_INSTRUMENT_SUCCESS",
  GET_INSTRUMENT_FAILED: "GET_INSTRUMENT_FAILED",
  SET_INSTRUMENT_ALARM_SUCCESS: "SET_INSTRUMENT_ALARM_SUCCESS",
  SET_INSTRUMENT_OPERATION_SUCCESS: "SET_INSTRUMENT_OPERATION_SUCCESS",
  SET_ALARMS_COMPLETED: "SET_ALARMS_COMPLETED",
  SET_CHART_LOAD_COMPLETED: "SET_CHART_LOAD_COMPLETED",
  SET_INSTRUMENT_INTRADAY: "SET_INSTRUMENT_INTRADAY"
};

export default ACTION_TYPES;
