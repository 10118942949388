import i18n from "i18next";

i18n.init({
  // we init with resources
  resources: {
    en: {
      translations: {
        LogOutButton: "Log out",
        newFunction: "News functions",
        changes: "Changes",
        Introduction: "Welcome to Infosel!",
        loginFormUsername: "Username",
        loginFormEnter: "Log In",
        loginTextMessage: "Enter your information to access your account",
        headerNewCardButton: "ADD CARD",
        headerSearchPlaceholder: "Search...",
        headerSubtitleSearch: "Search for symbol or description",
        workspaceNoResult:
          "This space is still empty, add a module (graphic, news or table type)",
        tipovalor: "Tipo Valor",
        tipomercado: "Tipo Mercado",
        market: "Mercado",
        bolsa: "Stock",
        nombrelargo: "Name",
        importetotal: "Importe total",
        totalAmountCSV: "Importe total",
        hora: "Hour",
        previousPrice: "Previous price",
        porcentaje: "Variación %",
        variacion: "Variación unitaria",
        absoluteChange: "Absolute change",
        volumentotal: "Volume Total",
        purchaseValue: "Precio de compra",
        purchaseVolume: "Postura compra volumen",
        purchasePrice: "Precio de compra",
        purchaseVolumbmv: "Volumen de compra",
        saleValue: "Precio de venta",
        saleVolume: "Volumen de venta",
        posturapreciocompra: "Post. Precio Cmpr",
        posturavolcompra: "Post. Vol Cmpr",
        posturaprecioventa: "Post. Prec. Vnt.",
        posturavolventa: "Post. Volventa",
        preciomaxdia: "Precio Máx.",
        maximum: "Maximum",
        minimum: "Minimum",
        preciomindia: "Precio Mín.",
        numOfOperations: "Número de operaciones",
        monitorvolumen: "Monitor Vol.",
        foliocid: "Folio Cid",
        fechaprecioactual: "Date",
        posturafecha: "Fecha postura",
        preciopp: "Precio Pp",
        ppp_oficial: "Ppp",
        airecierre: "Airecierre",
        descripcion: "Instrumento",
        fulldescription: "Description",
        precioapertura: "Precio de apertura",
        preciomaximo: "Precio Máx.",
        preciominimo: "Precio Mín.",
        purchaseTime: "Posture time",
        fechaprecioanterior: "Fecha precio anterior",
        previousDate: "Previous date",
        volumenhecho: "Vol. Hecho",
        seller: "Seller",
        accion: "Accion",
        PicoLote: "Pico Lote",
        PrecioPico: "Precio Pico",
        peakVolume: "Peak volume",
        peakAmount: "Peak amount",
        FechaPico: "Fecha Pico",
        HoraPico: "Hora Pico",
        newsFeatured: "Featured",
        newsImportantTag: "Important",
        sidebarDefaultHeader: "Add new card",
        searchSymbolNoResults:
          "No instruments were found that match your search",
        timestampMinutes: "minutes",
        actualPrice: "Current price",
        actualPriceValue: "Value",
        variation: "Variación",
        variationFixed: "Variación Fija",
        volume: "Volumen",
        lastClosure: "Último cierre",
        amount: "Importe",
        buyPosition: "Posturas compra",
        salePosition: "Posturas venta",
        industry: "Industria",
        foundation: "Fundación",
        founders: "Fundadores",
        filiales: "Filiales",
        employees: "Empleados",
        location: "Ubicación",
        address: "Dirección",
        sidebarInformationTimestamp: "Información al cierre, a las",
        low: "Baja",
        high: "Alta",
        chartTitle: "Gráfica",
        tableTitle: "Tabla",
        newsTitle: "Noticias",
        generalIndicators: "GENERAL INDICATORS",
        maxCardsLimitExceeded: "Puedes tener como máximo 9 módulos.",
        download: "Descargar",
        newsletters: "Newsletters",
        at: "at",
        hour: "Hour",
        hours: "hours",
        changeName: "Change name",
        delete: "Delete",
        undoText: "Undo",
        okNewCard: "Card has been added",
        okNewList: "New studies list created",
        duplicatedList: "Ya existe una lista con ese nombre",
        okDeletedList: "List has been deleted",
        shouldSelectAList: "You need to select a list",
        maxListsPermitted: "You can have up to 5 lists",
        listSaved: "List have been saved",
        deleteListConfirmation: "Do you want to delete the study?",
        yesDeleteList: "Yes, delete",
        concurrentUser: "You logged in to another device, disconnecting ...",
        cardDeleted: "Se ha eliminado el módulo",
        searchResults: "Search results:",
        recentResults: "Recent results:",
        period: "Period",
        periodicity: "Periodicity",
        interval: "Interval",
        range: "Range",
        Min: "Min",
        Hour: "Hour",
        Hours: "Hours",
        seeMore: "SEE MORE",
        seeLess: "SEE LESS",
        purchase: "Purchase price",
        sale: "Sale price",
        purchaseVsSale: "Purchase/Sale",
        spread: "Spread",
        value: "Current value",
        postureBidTime: "Posture Bid Time",
        postureAskTime: "Posture Ask Time",
        purchaseDate: "Purchase Date",
        saleDate: "Sale Date",
        instrument: "Instrument",
        instrumentCSV: "Emisora",
        instrumentCapitalsCSV: "Instrumento",
        description: "Description",
        lsconnection: "I connect to the LS server: ",
        lsconnectionfail: "Could not connect to server: ",
        monthlyNumber: "Monthly Yiled",
        annualNumber: "Anual Yield",
        semunalNumber: "Semanual Yield",
        volumen: "Volume",
        rendEnAnoNumber: "Yield per Year",
        precioliquido: "Precio de liquidación",
        precioanteriorliquido: "Precio anterior de liquidación",
        innerValue: "Inner value",
        operation: "Operation",
        maxPrice12Months: "Maximum price (12 months)",
        minPrice12Months: "Minimum price (12 months)",
        peakPrice: "Peak price",
        averagePrice: "Average price",
        monthlyYield: "Monthly yield",
        weeklyYield: "Weekly yield",
        annualYield: "Annual yield",
        yieldPerYear: "Yield per year",
        maxVol52s: "Vol. Max. 52(s)",
        minVol52s: "Vol. Min. 52(s)",
        averageVol3a: "Vol. Avg. 3(a)",
        averageVol3m: "Vol. Avg. 3(m)",
        isin: "ISIN",
        serie: "Fixed Income Serie",
        interest24H: "Interest 24H",
        scoreFitch: "Score Fitch",
        scoreMoodys: "Score Moody's",
        scoreSP: "Score S&P",
        scoreHR: "Score HR",
        daysToExpire: "Days to expire",
        fixedIncomeDate: "Date",
        dayMaxValue: "Day Max Value",
        dayMinValue: "Day Min Value",
        openingPrice: "Opening price",
        yieldRate: "Yield Rate",
        valueType: "Value Type",
        fixedIncomeTM: "TM",
        fixedIncomeTV: "TV",
        fixedIncomeQuotationKey: "Quotation Key",
        emissionDate: "Emission date",
        cleanPrice: "Clean price",
        dirtyPrice: "Diry price",
        surtaxPIP: "Surtax",
        mdCleanPrice: "Clean price MD",
        mdDirtyPrice: "Dirty price MD",
        volumeMade: "Volume done",
        postureDate: "Posture Date",
        postureHour: "Posture Hour",
        unitVariation: "Variación unitaria",
        stockpAsk: "Posture Stock Ask",
        stockpBid: "Posture Stock Bid",
        stockValue: "Stock Value",
        noMoreInformationMessage: "There is no more information to show.",
        pppValue: "Value / PPP",
        pppNumber: "PPP",
        minutes: "minutes",
        day: "day",
        widgetNotFound: "Información del widget no encontrada",
        basic: "OPTIONS",
        sortAZ: "Sort A → Z",
        sortZA: "Sort Z → A",
        moveRight: "Move right",
        moveLeft: "Move left",
        copy: "Copy",
        copyToClipboard: "Copied to clipboard",
        blockColumn: "Block column",
        deleteColumn: "Delete column",
        unblockColumn: "Unblock column",
        compare: "Compare",
        addComparison: "Add Comparison",
        compareSmallSize: "cf.",
        draw: "Draw",
        studies: "Studies",
        styles: "Styles",
        view: "View",
        withoutTool: "Without tool",
        measure: "Measure",
        fibArc: "FibArc",
        fibFan: "FibFan",
        gannFan: "GannFan",
        fibTimeUse: "Fib time use",
        resistanceArc: "Resistance arc",
        resistanceLine: "Resistance line",
        timeCycle: "Time cycle",
        rectangleArea: "Area",
        star: "Star",
        elliptical: "Elíptica",
        timeCycle2: "timeCycle",
        shapes: "Shapes",
        horizontal: "Horizontal",
        vertical: "Vertical",
        diagonal: "Diagonal",
        annotation: "Annotation",
        text: "Text",
        trend: "Triend",
        momentum: "Momentum",
        volatility: "Volatility",
        menuStudiesVolume: "Volume",
        duplicate: "Duplicate",
        block: "Block",
        noConnection: "No connection",
        deafultWorkspaceName: "Workspace",
        deafultListStudiesName: "List",
        newWorkspace: "New workspace",
        viewDetails: "View Details",
        copyRow: "Copy Row",
        duplicateRow: "Duplicate row in",
        blockRow: "Block row",
        deleteRow: "Delete instrument",
        moveUp: "Move up",
        moveDown: "Move down",
        addInstrument: "Add instrument...",
        graph: "Graph",
        workspaceHasBeenDeleted: "The workspace has been deleted",
        workspaceDuplicated: "Duplicated workspace",
        duplicateWorkspaceModal: "Are you sure you want to duplicate",
        deleteWorkspaceModal: "Are you sure you want to delete",
        agree: "Agree",
        disagree: "Disagree",
        workspace: "workspace",
        removeStudy: "Delete study",
        configStudy: "Edit settings...",
        btnSave: "Save",
        btnEdit: "Edit",
        deleteList: "Delete list",
        addNewList: "Add new list",
        addIcon: "Add",
        checkMark: "Check mark",
        deleteAll: "Delete All",
        sortNumberHighLow: "Sort largest to smallest",
        sortNumberLowHigh: "Sort smallest to highest",
        price: "Precio",
        tickerSmall: "Reduce size",
        tickerLarge: "Expand size",
        hide: "Hide",
        workspacesLimitExceeded:
          "You have reached the limit of allowed Workspaces.",
        instrumentAdded: "Instrument added",
        yearShortcut: "Y",
        bmvStocks: "BMV",
        bivaStocks: "BIVA",
        bmvSic: "BMV SIC",
        nyseStocks: "NYSE, AMEX and NASDAQ",
        currencies: "CURRENCY",
        bivaSic: "BIVA SIC",
        indices: "INDICES",
        fixedIncome: "FIXED INCOME",
        derivatives: "DERIVATIVES",
        nyse: "NYSE",
        amex: "AMEX",
        nasdaq: "NASDAQ",
        newChartTitle: "What do you want to graph?",
        chooseMarket: "Choose a market or instrument.",
        newTableTitle: "Choose the type of market.",
        predefinedTitle: "Add predefined table.",
        addCard: "Add card",
        chooseTableMarket: "They have the contents of all markets",
        add: "ADD",
        bmvStocksInTitle: "What stock of BMV do you want to graph?",
        bivaStocksInTitle: "What stock of BIVA do you want to graph?",
        bmvSicInTitle: "What stock of BMV - SIC do you want to graph?",
        nyseStocksInTitle: "What stock of NYSE do you want to graph?",
        currenciesInTitle: "What foreign exhange do you want to graph?",
        bivaSicInTitle: "What stock of BIVA - SIC do you want to graph?",
        indicesInTitle: "What index do you want to graph?",
        fixedIncomeInTitle: "What stock of fixed income do you want to graph?",
        derivativesInTitle: "What stock of derivatives do you want to graph?",
        generalIndicatorsInTitle:
          "What stock of general indicators do you want to graph?",
        nasdaqInTitle: "What stock of NASDAQ do you want to graph?",
        amexInTitle: "What stock of AMEX do you want to graph?",
        show: "To show ticker",
        onError: "An error has occurred",
        noChartDataMessage: "Loading instrument information.",
        deleteCard: "Delete card",
        moveCard: "Move card",
        workspaces: "SPACES",
        addNewInstrumentTitle: "Add new instruments:",
        duplicateCard: "Card has been duplicated",
        pricesMaxAndMin: "Máx/Mín",
        capitalization: "Capitalization",
        companyValue: "Company Value",
        annualPerformance: "Annual Performance",
        maxOfTheYear: "Max Of The Year",
        minOfTheYear: "Min Of The Year",
        averageDailyVolume: "Average Daily Volume (3M)",
        divYield: "Div. Yield",
        targetPrice: "Target Price",
        recommendation: "Recommendation",
        opening: "Opening",
        numOrder: "Num Order",
        purchaseDepth: "Purchase",
        saleDepth: "Sale",
        volSale: "Vol Sale",
        numOrders: "Num Orders",
        ticker: "Ticker",
        quantity: "Quantity",
        ppp: "PPP",
        liquidation: "Liquidation",
        graphDesc: "Graph",
        sevenDays: "7 days",
        thirtyDays: "30 days",
        ofYear: "Of year",
        lastTwelveMonths: "Last 12 m.",
        pupa: "P/UPA",
        pvl: "PVL",
        transmitter: "Transmitter",
        suggestedForex: "Suggested Forex",
        suggestedStocks: "Suggested Stocks",
        predefinedIpc: "IPC",
        predefinedBanorte: "Banorte Funds",
        atLeast5instruments:
          "You can save a minimum of 5 instruments and a maximum of 20",
        operator: "Operator",
        fundType: "Type of investment fund",
        investorType: "Type of investor",
        term: "Term",
        maxInstrumentsNumber: "You can add up to 20 instruments",
        deleteInstrument: "Instrument removed",
        financialReports: "Financial reports",
        brent: "Brent",
        wti: "WTI",
        wts: "WTS",
        istmo: "ISTMO",
        maya: "MAYA",
        olmeca: "OLMECA",
        mezcla: "MEZCLA",
        shcp: "SHCP (A)",
        dif: "DIF (B)",
        petroleum: "Petroleum",
        today: "Today",
        thisWeek: "Esta semana",
        thisMonth: "Este mes",
        fuelOil: "Fuel oil (A)",
        difference: "Difference% Previous level",
        gasoline: "Gasoline (A)",
        naturalGas: "Natural gas (B)",
        naturalGasName: "Natural Gas",
        preciousMetals: "Precious Metals",
        metals: "Metals",
        unitVariationA: "Unit variation (A)",
        yesterdayPP: "Yesterday At",
        yesterday: "Yesterday",
        quotation7: "Quote 7 days",
        quotation30: "Quote 30 days",
        industrialMetals: "Industrial Metals",
        dollars: "Dollars",
        pesos: "Pesos",
        variation7days: "Variation 7 days",
        variarion30days: "Variation 30 days",
        variationYear: "Variation in the year",
        frequency: "Frequency",
        unitValue: "Unit value",
        financialIndicators: "Financial indicators",
        financeCurrency: "Finance and Currency",
        pricesWages: "Prices and wages",
        interestChanges: "Interest and changes",
        publicAccounts: "Public Accounts",
        employmentProduction: "Employment and Production",
        externalAccounts: "External accounts",
        cetesPrimaryMarket: "Cetes Primary Market",
        primaryMarketBonds: "Primary Market Bonds",
        Commodities: "Metales",
        bondesPrimaryMarket: "Bondes Primary Market",
        usBonds: "US Bonds",
        workspacesLimitDelete:
          "You have reached the limit of allowed Workspaces to delete.",
        deselectAll: "Deselect all",
        removeStickers: "Remove Stickers",
        removeDrawings: "Remove Drawings",
        studiesLists: "Studies lists",
        DetailPurse: "Detalle de cartera",
        hidePurse: "Ocultar Cartera",
        showPurse: "Mostrar Cartera",
        reloadPurse: "Recargar Cartera",
        capitals: "Capitales",
        money: "Fondos",
        moneyTilte: "Fondo",
        marketMoney: "Mercado de Dinero",
        mrkMoney: "Mdo. de Dinero",
        cash: "Efectivo",
        editContract: "Editar Contrato",
        contract: "Contrato",
        porLiquidar: "Cash to be settled",
        operacionesHistoricas: "Movimientos",
        composicionCartera: "Composición de cartera",
        fundamentalAnalysis: "Fundamental analysis",
        technicalAnalysis: "Technical analysis",
        noResults: "We haven't found anything around here",
        newSearch: "Please search with another word",
        newOperation: "OPERAR",
        titleNewOperation: "Hacer nueva operación",
        subTitleNewOperation: "Busca y elige el instrumento",
        changeToChart: "Cambiar a gráfica",
        changeToDetail: "Cambiar a detalle",
        changeToList: "Cambiar a lista",
        titleTradingOperation: "Detalle de orden",
        orderResume: "Orden resume",
        profPosturas: "Profundidad de posturas",
        consolidateProfPosturas: "Profundidad de posturas consolidadas",
        buy: "COMPRA",
        sell: "VENTA",
        continue: "Continuar",
        confirmOrder: "Confirmar",
        increment100: "100",
        increment1000: "1,000",
        increment10000: "10,000",
        dateLimit: "Fecha límite",
        titleNumber: "No. de títulos",
        showAOption: "Elige una opción",
        sendChanel: "Canal de envío",
        liquidez: "Liquidez",
        orderType: "Tipo de orden",
        position: "Posición",
        protectMarket: "Mercado con protección",
        limitPrice: "Limitada por precio",
        bestEject: "Mejor ejecución",
        consolidatedBMV: "Acciones consolidadas",
        consolidatedBMVSIC: "Acciones y SIC consolidadas",
        consolidatedSIC: "SIC consolidados",
        titleRegistredRequest: "Solicitud registrada",
        orderGenerated: "La orden se generó con el folio",
        requestFirstPart: "La solicitud fue registrada para la ",
        requestTitle: "título",
        requestTitles: "títulos",
        requestSeccondPart: " de la emisora",
        requestFundSeccondPart: " del fondo ",
        requestFundThirdPart: "con un subtotal de $",
        viewMyOperations: "Ver mis operaciones",
        toolTipProtectMarket: "La orden será ejecutada en la BMV.",
        toolTipPriceLimit: "La orden será asignada a la mejor ejecución.",
        comparatives: "COMPARATIVOS DE REPORTES FINANCIERO NOMINALES",
        businessName: "Razón social",
        comparative: "COMPARATIVO",
        initialYear: "Año Inicial",
        finalYear: "Año Final",
        keyCSV: "CLAVE",
        textCSV: "TEXTO",
        folioCSV: "Orden",
        symbolCSV: "Instrumento",
        orderTypeCSV: "Operación",
        sharesCSV: "Títulos",
        priceCSV: "Precio Solicitado",
        unitPriceCSV: "Precio unitario",
        totalCSV: "Importe",
        taxCSV: "% Corretaje",
        sharesAssignedCSV: "Títulos Asignados",
        orderDateCSV: "Fecha de Operación",
        orderDate: "Fecha de operación",
        orderTimeCSV: "Hora",
        priceForTitles: "Precio por títulos",
        detailBuy: "Detalle de la compra",
        detailSell: "Detalle de la venta",
        amountToBuy: "Importe a comprar",
        typeSell: "Tipo de venta",
        totalSell: "Venta total",
        partialSell: "Venta parcial",
        byNumberOfTitlesSell: "Sale by number of titles",
        numberOfTitlesNewOperation: "Number of titles",
        sellFor: "Venta por",
        numberOdContracts: "No. de contrato",
        found: "Fondo",
        fundPriceTotal: "Saldo actual de fondo",
        priceForTitle: "Precio*",
        priceForTitleLegend: "* Precio de referencia por título",
        titlesForBuy: "Títulos a comprar",
        requestDate: "Fecha de solicitud",
        registerDate: "Fecha de registro",
        validityDate: "Fecha de vigencia",
        priorityVolume: "Priority Volume",
        priorityVolumeTooltip:
          "The order will be produced giving priority to the volume based on what is available in the stock exchanges, according to the new operating rules.",
        settlementDate: "Fecha de liquidación",
        settlementDateCSV: "Fecha liquidación",
        date: "Fecha",
        subTotal: "Subtotal",
        noteFondRegistred1:
          "La solicitud será registrada de acuerdo a las particularidades de cada Fondo de Inversión conforme al prospecto de inversión. Operación sujeta al Contrato de Intermediación Bursátil celebrado entre usted (cliente) y Banorte Casa de Bolsa. El monto ingresado se ajustará de acuerdo al precio del fondo, multiplicado por el número de títulos a operar. El importe final del retiro, así como el precio al que se efectuará la",
        noteFondRegistred2: "se conocerán el día de la liquidación",
        noteFondConfirmation1:
          "La solicitud será registrada de acuerdo a las particularidades de cada Fondo de Inversión conforme al prospecto de inversión. Operación sujeta al Contrato de Intermediación Bursátil celebrado entre usted (cliente) y Banorte Casa de Bolsa. El monto ingresado se ajustará de acuerdo al precio del fondo, multiplicado por el número de títulos a operar. El importe final del retiro, así como el precio al que se efectuará la",
        noteFondConfirmation2: "se conocerán el día de la liquidación",
        currentLevel: "Current level",
        previousLavel: "Previous level",
        contractLoadError: "Error en la carga de contrato(s)",
        loadError: "Error en carga",
        repeatedContract:
          "Ya existe un contrato con ese nombre, por favor elige otro",
        moreLongerName: "Elige un nombre más largo",
        onLoad: "Cargando...",
        loadContracts: "Cargando contrato(s)",
        errorLogin: "Usuario o contraseña incorrectos.",
        closingHours: "Schedule",
        emptyField: "Enter a name",
        serviceUnableProfPosturas:
          "No cuenta con el servicio de Profundidad de posturas. Contacte a su asesor Banorte",
        portfolioDetailLoadError: "Error loading portfolio detail",
        results: "Results",
        hourDone: "Hora hecho",
        dateDone: "Fecha hecho",
        getFeeError: "Error getting the equity fee",
        toolTipDate:
          "Puedes consultar hasta un máximo de 90 días a partir de la fecha de hoy (fechas anteriores a la actual)",
        toolTipDateNew:
          "Puedes consultar hasta un rando de 30 días (fechas anteriores a la actual)",
        loadingInfoError: "Error trying to get the data",
        profPosturasTitle: "Profundidad de posturas",
        lastUpdate: "Last update",
        legalWaring: "LEGAL WARNING:",
        settings: "Settings",
        appearance: "Appearance",
        tutorials: "Tutorials",
        support: "Support",
        alarms: "Alarms",
        personalization: "personalization",
        warningMessage: "This action is irreversible. Are you sure?",
        warningMessage2: "This action is irreversible.",
        warningMessageCancelOrder:
          "This order has already been assigned and cannot be modified.",
        cancelOrder: "To cancel an order",
        toolTipNoWorkingDay:
          "Lo sentimos, por el momento no puedes operar al ser día inhábil",
        logOutQuestion: "Do you want to log out?",
        operationError: "Ha ocurrido un error al procesar tu transacción",
        processOperation: "Tu orden se está procesando",
        frequentQuestions: "Frequent questions",
        askReportProblem: "Would you like to report a problem?",
        reportProblem: "Report a problem",
        usefulInformation: "Did you find this information helpful?",
        textYes: "Yes",
        textNo: "No",
        noPositionOnSymbol: "Por el momento, no cuenta con títulos para vender",
        noEnoughTitles: "No se cuenta con los títulos suficientes",
        selectOrderType: "Selecciona el tipo de orden",
        titlesMoreOfOne: "Ingresa como mínimo 1 título",
        notLiquid:
          "No cuentas con la liquidez suficiente para registrar la orden.",
        limiteListaEstudios:
          "Has alcanzado el límite de listas de estudios permitidos a eliminar.",
        active: "Activas",
        inactive: "Inactivas",
        alarmNotFound: "Ninguna alarma asociada",
        addAlarm: "Agregar alarma",
        lowerLimit: "Del límite inferior",
        upperLimit: "Al límite superior",
        newAlarm: "Nueva alarma",
        addName: "Agrega un nombre para identificarla",
        alarmType: "Tipo",
        condition: "Condición",
        priceMustBeHigher: "El precio debe ser mayor a 0",
        mountMustBeHigher: "El monto a operar debe de ser mayor a 0",
        alarmCreated: "Alarma creada",
        duplicateAlarm: "El nombre de la alarma se encuentra duplicada",
        notLiquid2: "No se cuenta con liquidez suficiente",
        total: "Total",
        messageAmount:
          "No cuenta con los títulos suficientes para completar la operación",
        messageTitle:
          "Ingrese un valor menor o igual al número de títulos en posesión",
        financialReasons: "Financial Reasons",
        orders: "Orders",
        geoHeatmapTitle: "World Indices",
        allAlarms: "All Alarms",
        getComposition: "Consultando posición",
        noInformation: "Sin información disponible",
        consultingInformation: "Consultando información",
        capitalsDisclaimer:
          "Nota: Operación sujeta al contrato de intermediación bursátil celebrado entre usted (cliente) y Banorte Casa de Bolsa. El monto de inversión se ajustará de acuerdo al precio del instrumento seleccionado, multiplicado por el número de títulos a operar y, la comisión de corretaje se calculará en función de dicho monto. ",
        alarmDeleted: "Alarm deleted",
        deleteAlarm: "Delete alarm",
        confirmationText: "This action is irreversible. Are you sure?",
        orderColumn: "Order column",
        blockedColumn: "Blocked column",
        couponRate: "Coupon rate",
        purchaseRate: "Purchase rate",
        salesRate: "Sales rate",
        selectFund: "Selecciona un fondo",
        fundSellEmpty: "Por el momento, no cuenta con títulos para vender",
        serviceError: "Error en el servicio intenta más tarde",
        geolocalitionError:
          "No fue posible obtener tu ubicación, valida que se encuentre activa en tu navegador.",
        balance: "Posición",
        editAlarm: "Edit alarm",
        editedAlarm: "Se guardaron los cambios en la alarma",
        instrumentNoOperable:
          "Sorry, operation cannot be done, instrument temporarily suspended",
        financialReport: "Financial Report",
        instrumentNoOperable2:
          "Sorry, this instrument cannot be operated at the moment",
        instrumentDayInhabil:
          "Lo sentimos, por el momento no se puede operar al ser día inhábil",
        channel: "Channel",
        callout: "Callout",
        continuous: "Continuous",
        freeform: "Doodle",
        retracement: "Fib retracement",
        fibprojection: "Fib projection",
        pitchfork: "Horquilla",
        quadrant: "Quadrant line",
        ray: "Ray",
        regression: "Regresion line",
        segment: "Segment",
        tirone: "Tirone Levels",
        trendline: "Trend line",
        doodle: "Doodle",
        check: "Check",
        cross: "Cross",
        heart: "Heart",
        arrow: "Arrow",
        numberOfTitles: "No. de Títulos",
        numberOfTitlesCSV: "Número de títulos",
        fundPrice: "Precio del fondo",
        foundBalance: "Valuación",
        amountAlarm: "Amount",
        amountMustBeHigher: "El monto debe ser mayor a 0",
        percentageMustBeHigher: "La variación debe ser mayor a 0",
        rangeMustBeHigher:
          "El límite superior debe ser mayor al límite inferior",
        saveStudies: "Save study list",
        savedStudies: "Saved Study List",
        saveStudiesError:
          "No fue posible completar tu solicitud, inténtalo nuevamente",
        sessionExpiredTitle: "Session expired",
        sessionExpiredSubtitle:
          "Your session has expired. You are exiting Be Trading",
        sessionExpiredAutomatly: "Cierre de sesión automáticamente",
        sessionExpiredAutomatlyRemaining: "Su sesión se cerrará en 5 minutos.",
        sessionExpiredAutomatlyCounter: "Su sesión expirará en:",
        sessionExpiredAutomatlyContinueSession: "Continuar en la sesión",
        sessionExpiredAutomatlySeconds: "segundos",
        sessionFinalizedTitle: "Sesión finalizada",
        sessionFinalizedSubtitle: "Su sesión de BeTrading ha finalizado",
        savedEdition: "Saved edition",
        choose: "Choose",
        calculate: "Consult",
        newDateRange: "Please search with other dates",
        kagi: "Set Reversal Percentage",
        renko: "Set Brick Size",
        rangeBars: "Set Range",
        lineBreak: "Set Price Lines",
        autoSelect: "Auto Select",
        dateAndHourPostures: "Fecha y hora de posturas",
        monday: "Monday",
        tuesday: "Tuesday",
        wednesday: "Wednesday",
        thursday: "Thursday",
        friday: "Friday",
        saturday: "Saturday",
        sunday: "Sunday",
        january: "January",
        february: "February",
        march: "March",
        april: "April",
        may: "May",
        june: "June",
        july: "July",
        august: "August",
        september: "September",
        october: "October",
        november: "November",
        december: "December",
        contractErrorMessageTab:
          "Sorry, your Portfolio is not available at the moment, try again later",
        contractErrorMessageOperation:
          "Sorry, at the moment your Contract is not available to operate, try again later",
        loadingTableData: "Loading information",
        newWorkspaceAdded: "Added workspace",
        addNewStudyList: "Agregar lista de estudio",
        tooltipStudys: "Debes mantener como mínimo una lista de estudios",
        reject: "Reject",
        getGeolocalization: "Getting geolocation",
        setGeolocalization: "Registrando geolocalización",
        relatedTopic: "Related topic",
        send: "Send",
        reportSent: "Report sent",
        businessDayError:
          "An error has occurred, please try to refresh your browser",
        geolocalization: "Geolocalización",
        general: "Configuración",
        visualize: "Visualización",
        spacing: "Spacing",
        standar: "Standar",
        compact: "Compact",
        letterSize: "Letter size",
        contrast: "Contrast",
        deletelastInstrument:
          "Debes mantener como mínimo un instrumento por tabla",
        maxDay: "Máximo del día",
        minDay: "Mínimo del día",
        max12Months: "Máximo últimos 12 meses",
        BanorteAnalysis: "Análisis Banorte",
        AnalysisTools: "Herramienta de Análisis",
        confirmCancelOrder: "¿Desea cancelar su orden?",
        no: "No",
        confirmCancel: "Si, Cancelar",
        tooltiModulepPredefinedTable:
          "Agregue una lista de instrumentos preconfigurada. Una vez guardada no se podrán añadir o eliminar instrumentos a estas listas",
        tooltipModuleNewTable:
          "Agregue o elimine instrumentos en una lista de seguimiento personalizada",
        tooltipModuleChart:
          "Agregue la gráfica del comportamiento de algún instrumento",
        tooltipModuleNews:
          "Agregue una tarjeta y visualice los últimos titulares de noticias",
        tooltipModuleHeatmap:
          "Agregue un mapa, el color de cada país le muestra el rendimiento de sus principales índices accionarios",
        percentage: "Porcentaje",
        unitary: "Unitaria",
        titleResetWorkspace: "¿Desea aplicar los cambios?",
        subTitleResetWorkspace:
          "Los espacios de trabajo creados en el módulo Mis Mercados se borrarán de su plataforma.",
        quickCategorys: "Quick categorys",
        status: "Estatus",
        tooltipTreemap:
          "Add a heat map, shows the behavior of the stations by type of market",
        treemap: "Stock heatmap",
        noResultsTreemap: "There are no stations under this match",
        thisSpace: "This space",
        otherSpace: "Other space",
        newSpace: "New space",
        mySpaces: "My spaces",
        duplicateNewCard: "Duplicate card in",
        economicCalendar: "Calendario económico",
        tooltipeconomicCalendar:
          "Agregue una tarjeta para visualizar las fechas en que se producirán eventos o noticias financieras relevantes",
        analysisToolTracingTooltip:
          "Informe exhaustivo y detallado de los activos de inversión. Cada reporte contiene información actualizada e histórica para valorar los instrumentos.",
        analysisToolTracingTitle: "Reporte de emisoras",
        analysisToolReportTooltip:
          "Análisis de mercado que te permite dar seguimiento diario al comportamiento de tus activos.",
        analysisToolReportTitle: "Seguimiento de emisoras",
        analysisToolOportunityTooltip:
          "Conoce instrumentos interesantes a considerar para comprar.",
        analysisToolOportunityTitle: "Oportunidades de compra",
        analysisToolCompareTooltip:
          "Compara diferentes activos entre sí para toma de decisiones.",
        analysisToolCompareTitle: "Comparador gráfico",
        analysisToolModalTitle: "Saliendo de BeTrading",
        analysisToolModalContent:
          "Estás a punto de salir de la página o del dominio Banorte y estás a punto de ser direccionado a una página externa la cual se rige bajo sus propias políticas de seguridad y de privacidad.",
        tradingView: {
          savedCharts: {
            deleted: "The Chart was successfully removed.",
            maxReached:
              "You reached the maximum number of Charts allowed, if you want to add a new one you could delete another one.",
            saved: "The Chart was saved successfully.",
            title: "Charts"
          },
          templates: {
            maxReached:
              "The maximum number of templates is 5, if you want to add a new one you could delete another one.",
            saved: "The template was saved successfully.",
            title: "Templates",
            notSpecialCharacter:
              "The template name cannot contain special characters"
          }
        },
        operationTicket: {
          title: "Operation Ticket",
          message:
            "Because the order was captured outside of operating hours, it will be considered for the next business day."
        },
        newFilterSearch: "Please search with another filter",
        noInformationComposition:
          "Durante el proceso SVT - 13 no existen registros para la consulta"
      }
    },
    es: {
      translations: {
        LogOutButton: "Cerrar sesión",
        newFunction: "Nuevas funcionalidades",
        changes: "Arreglos",
        Introduction: "Bienvenido a infosel!",
        loginFormUsername: "Usuario",
        loginTextMessage: "Ingresa tus datos para entrar a tu cuenta",
        loginFormEnter: "ENTRAR",
        headerNewCardButton: "Agrega un módulo",
        headerSearchPlaceholder: "Buscar...",
        headerSubtitleSearch: "Buscar por símbolo o descripción",
        workspaceNoResult:
          "Este espacio aún esta vacío, agrega un módulo (de tipo gráfica, noticia o tabla)",
        nombrelargo: "Nombre",
        tipomercado: "Tipo Mercado",
        market: "Mercado",
        tipovalor: "Tipo Valor",
        bolsa: "Bolsa",
        importetotal: "Importe total",
        totalAmountCSV: "Importe total",
        hora: "Hora",
        previousPriceValue: "Valor anterior",
        previousPrice: "Precio anterior",
        porcentaje: "Variación %",
        variacion: "Variación unitaria",
        absoluteChange: "Variación unitaria",
        volumentotal: "Volumen total",
        purchaseValue: "Precio de compra",
        purchaseVolume: "Volumen de compra",
        purchasePrice: "Precio de compra",
        purchaseVolumen: "Volumen de compra",
        saleValue: "Precio de venta",
        saleVolume: "Volumen de venta",
        posturapreciocompra: "Post. Precio Cmpr",
        posturavolcompra: "Post. Vol Cmpr",
        posturaprecioventa: "Post. Prec. Vnt.",
        posturavolventa: "Post. Volventa",
        preciomaxdia: "Precio Máx.",
        maximum: "Máximo",
        minimum: "Mínimo",
        numOfOperations: "Número de operaciones",
        monitorvolumen: "Monitor Vol.",
        foliocid: "Folio Cid",
        fechaprecioactual: "Fecha",
        posturafecha: "Fecha postura",
        preciopp: "Precio Pp",
        ppp_oficial: "Ppp",
        airecierre: "Airecierre",
        descripcion: "Instrumento",
        fulldescription: "Descripción",
        precioapertura: "Precio de apertura",
        preciomaximo: "Precio Máx.",
        preciominimo: "Precio Mín.",
        purchaseTime: "Hora postura",
        fechaprecioanterior: "Fecha precio anterior",
        previousDate: "Fecha anterior",
        volumenhecho: "Volumen hecho",
        buyer: "Comprador",
        seller: "Vendedor",
        accion: "Accion",
        PicoLote: "Pico Lote",
        PrecioPico: "Precio Pico",
        peakVolume: "Volumen Pico",
        peakAmount: "Importe Pico",
        FechaPico: "Fecha Pico",
        HoraPico: "Hora Pico",
        newsFeatured: "Destacados",
        newsImportantTag: "Importante",
        sidebarDefaultHeader: "Agregar nuevos módulos",
        searchSymbolNoResults:
          "Sin resultados que coincidan con el criterio de búsqueda",
        timestampMinutes: "minutos",
        actualPrice: "Precio actual",
        actualPriceValue: "Valor",
        variation: "Variación",
        variationFixed: "Variación Fija",
        volume: "Volumen",
        lastClosure: "Último cierre",
        amount: "Importe",
        buyPosition: "Posturas compra",
        salePosition: "Posturas venta",
        industry: "Industria",
        foundation: "Fundación",
        founders: "Fundadores",
        filiales: "Filiales",
        employees: "Empleados",
        location: "Ubicación",
        address: "Dirección",
        sidebarInformationTimestamp: "Información al cierre, a las",
        low: "Baja",
        high: "Alta",
        chartTitle: "Gráfica",
        tableTitle: "Tabla",
        newsTitle: "Noticias",
        generalIndicators: "INDICADORES",
        maxCardsLimitExceeded: "Puedes tener como máximo 9 módulos.",
        download: "Descargar",
        newsletters: "Boletines",
        at: "a las",
        hour: "Hora",
        hours: "horas",
        changeName: "Cambiar nombre",
        delete: "Eliminar",
        undoText: "DESHACER",
        okNewList: "Lista de estudios creada",
        duplicatedList: "Ya existe una lista con ese nombre",
        okDeletedList: "Lista de estudios eliminada correctamente",
        shouldSelectAList: "Debes seleccionar una lista para agregar estudios",
        maxListsPermitted: "Sólo puedes tener 5 listas",
        listSaved: "Se actualizó la lista",
        deleteListConfirmation: "¿Deseas eliminar tu lista de estudio?",
        yesDeleteList: "Sí, eliminar",
        okNewCard: "Módulo agregado",
        concurrentUser:
          "Se inicio session en otro dispositivo, desconectando...",
        cardDeleted: "Se ha eliminado el módulo",
        searchResults: "Resultados de búsqueda:",
        recentResults: "Búsquedas recientes:",
        period: "Período",
        periodicity: "Periodicidad",
        interval: "Intervalo",
        range: "Rango",
        Min: "Min",
        Hour: "Hora",
        Hours: "Horas",
        seeMore: "VER MÁS",
        seeLess: "VER MENOS",
        purchase: "Precio de compra",
        sale: "Precio de venta",
        purchaseVsSale: "Compra/Venta",
        spread: "Spread",
        value: "Valor actual",
        postureBidTime: "Hora compra",
        postureAskTime: "Hora venta",
        purchaseDate: "Fecha postura",
        saleDate: "Fecha venta",
        instrument: "Instrumento",
        instrumentCSV: "Emisora",
        instrumentCapitalsCSV: "Instrumento",
        description: "Descripción",
        monthlyNumber: "Rend. Mensual",
        annualNumber: "Rend. Anual",
        semunalNumber: "Rend. Semanal",
        volumen: "Volumen",
        rendEnAnoNumber: "Rend. en el Año",
        precioliquido: "Precio de liquidación",
        precioanteriorliquido: "Precio anterior de liquidación",
        volumenacumulado: "Volumen acumulado",
        valormaxdia: "Máximo",
        valormindia: "Mínimo",
        contratosabiertos: "Contratos Abiertos",
        fechaposturaventa: "Fecha Postura de Venta",
        horapventa: "Hora p. Venta",
        fechaposturacompra: "Fecha Postura de Compra",
        horapcompra: "Hora p. Compra",
        fechaexpiracion: "Fecha de expiracón",
        reseña: "Descripción",
        lsconnection: "Se conecto al servidor de LS: ",
        lsconnectionfail: "No se pudo conectar al servidor: ",
        innerValue: "Valor Anterior",
        operation: "Operación",
        maxPrice12Months: "Precio máx. (Ult. 12 Meses)",
        minPrice12Months: "Precio min. (Ult. 12 Meses)",
        peakPrice: "Último precio",
        averagePrice: "Precio promedio",
        monthlyYield: "Rendimiento mensual",
        weeklyYield: "Rendimiento semanal",
        annualYield: "Rendimiento anual",
        yieldPerYear: "Rendimiento año a la fecha",
        maxVol52s: "Vol. Max. 52(s)",
        minVol52s: "Vol. Min. 52(s)",
        averageVol3a: "Vol. Prom. 3(a)",
        averageVol3m: "Vol. Prom. 3(m)",
        isin: "ISIN",
        serie: "Serie",
        expirationDate: "Fecha de vencimiento",
        interest24H: "Interés 24H",
        scoreFitch: "Calificación Fitch",
        scoreMoodys: "Calificación Moody's",
        scoreSP: "Calificación S&P",
        scoreHR: "Calificación HR",
        daysToExpire: "Días por vencer",
        fixedIncomeDate: "Fecha",
        fixedIncomeHour: "Hora",
        dayMaxValue: "Valor Máx. Día",
        dayMinValue: "Valor Min. Día",
        openingPrice: "Precio apertura",
        yieldRate: "Tasa de rendimiento",
        valueType: "Tipo de valor",
        fixedIncomeTM: "TM",
        fixedIncomeTV: "TV",
        quotationKey: "Clave de Cotización",
        emissionDate: "Fecha de emisión",
        cleanPrice: "Precio limpio",
        dirtyPrice: "Precio sucio",
        surtaxPIP: "Sobretasa",
        mdCleanPrice: "Precio limpio MD",
        mdDirtyPrice: "Precio sucio MD",
        volumeMade: "Volumen hecho",
        postureDate: "Fecha Postura",
        postureHour: "Hora Postura",
        unitVariation: "Variación unitaria",
        stockpAsk: "Bolsa P. Venta",
        stockpBid: "Bolsa P. Compra",
        stockValue: "Bolsa hecho",
        noMoreInformationMessage: "No hay más información para mostrar.",
        pppValue: "Valor / PPP",
        pppNumber: "PPP",
        minutes: "minuto",
        day: "dia",
        widgetNotFound: "Información del widget no encontrada",
        basic: "OPCIONES",
        sortAZ: "Ordenar A → Z",
        sortZA: "Ordenar Z → A",
        moveRight: "Mover a la derecha",
        moveLeft: "Mover a la izquierda",
        copy: "Copiar",
        copyToClipboard: "Copiado en el portapapeles",
        blockColumn: "Bloquear columna",
        deleteColumn: "Eliminar columna",
        unblockColumn: "Desbloquear columna",
        compare: "Comparar",
        addComparison: "Añadir comparación",
        compareSmallSize: "cf.",
        draw: "Dibujo",
        studies: "Estudios",
        styles: "Estilos",
        view: "Vista",
        withoutTool: "Sin herramienta",
        measure: "Medida",
        fibArc: "FibArc",
        fibFan: "FibFan",
        gannFan: "GannFan",
        fibTimeUse: "Fib huso horario",
        resistanceArc: "Arco de resistencia",
        resistanceLine: "Línea de resistencia",
        timeCycle: "Ciclo de tiempo",
        rectangleArea: "Área",
        star: "Estrella",
        elliptical: "Elíptica",
        timeCycle2: "Moño",
        shapes: "Formas",
        horizontal: "Horizontal",
        vertical: "Vertical",
        diagonal: "Diagonal",
        annotation: "Anotación",
        text: "Texto",
        trend: "Tendencia",
        momentum: "Momentum",
        volatility: "Volatilidad",
        menuStudiesVolume: "Volumen",
        duplicate: "Duplicar",
        block: "Bloquear",
        noConnection: "Sin conexión",
        deafultWorkspaceName: "Nuevo espacio",
        deafultListStudiesName: "Lista",
        newWorkspace: "Agregar espacio de trabajo",
        viewDetails: "Ver detalles",
        copyRow: "Copiar fila",
        duplicateRow: "Duplicar fila en",
        blockRow: "Bloquear fila",
        deleteRow: "Eliminar instrumento",
        moveUp: "Mover arriba",
        moveDown: "Mover abajo",
        addInstrument: "Agregar instrumento...",
        graph: "Graficar",
        workspaceHasBeenDeleted: "Se ha eliminado el espacio ",
        workspaceDuplicated: "Se ha duplicado el",
        duplicateWorkspaceModal: "¿Estás seguro de que quieres duplicar",
        deleteWorkspaceModal: "¿Deseas eliminar",
        agree: "Aceptar",
        disagree: "Cancelar",
        workspace: "espacio",
        removeStudy: "Eliminar estudio",
        configStudy: "Editar configuración...",
        btnSave: "Guardar",
        btnEdit: "Editar",
        deleteList: "Borrar listado",
        addNewList: "Agregar nueva lista",
        addIcon: "Agregar",
        checkMark: "Guardar cambios",
        deleteAll: "Borrar todo",
        sortNumberHighLow: "Ordenar de mayor a menor",
        sortNumberLowHigh: "Ordenar de menor a mayor",
        price: "Precio",
        valor: "Valor",
        purchaseVolumbmv: "Volumen de compra",
        tickerSmall: "Reducir tamaño",
        tickerLarge: "Ampliar tamaño",
        hide: "Ocultar",
        workspacesLimitExceeded:
          "Has alcanzado el límite de espacioss permitidos.",
        instrumentAdded: "Instrumento(s) agregado(s)",
        salePrice: "Precio de venta",
        yearShortcut: "A",
        bmvStocks: "BMV",
        bivaStocks: "BIVA",
        bmvSic: "BMV SIC",
        nyseStocks: "NYSE, NASDAQ y AMEX",
        currencies: "DIVISAS",
        bivaSic: "BIVA SIC",
        indices: "ÍNDICES",
        fixedIncome: "DEUDA",
        derivatives: "DERIVADOS",
        nyse: "NYSE",
        amex: "AMEX",
        nasdaq: "NASDAQ",
        newChartTitle: "¿Qué quieres graficar?",
        newTableTitle: "Elige el tipo de mercado",
        predefinedTitle: "Agrega una lista",
        addCard: "Agrega un módulo",
        chooseMarket: "Elige un mercado o instrumento",
        chooseTableMarket: "Tienen el contenido de todos los mercados",
        add: "AGREGAR",
        bmvStocksInTitle: "¿Qué acción de BMV quieres graficar?",
        bivaStocksInTitle: "¿Qué acción de BIVA quieres graficar?",
        bmvSicInTitle: "¿Qué acción de BMV - SIC quieres graficar?",
        nyseStocksInTitle: "¿Qué acción de NYSE quieres graficar?",
        currenciesInTitle: "¿Qué acción de Divisas quieres graficar?",
        bivaSicInTitle: "¿Qué acción de BIVA - SIC quieres graficar?",
        indicesInTitle: "¿Qué acción de Indices quieres graficar?",
        fixedIncomeInTitle: "¿Qué acción de Deuda quieres graficar?",
        derivativesInTitle: "¿Qué acción de Derivados quieres graficar?",
        generalIndicatorsInTitle:
          "¿Qué acción de Indicadores quieres graficar?",
        nasdaqInTitle: "¿Qué acción de NASDAQ quieres graficar?",
        amexInTitle: "¿Qué acción de AMEX quieres graficar?",
        show: "Mostrar ticker",
        onError: "Ha ocurrido un error",
        noChartDataMessage: "Cargando información del instrumento.",
        deleteCard: "Eliminar módulo",
        moveCard: "Mover módulo",
        addNewInstrumentTitle: "Agregar instrumento:",
        duplicateCard: "Se duplicó este módulo en",
        movedCard: "Se ha movido el módulo",
        movedCardToNewWS: "a un nuevo espacio de trabajo",
        undoMoveCard: "Has eliminado el módulo",
        pricesMaxAndMin: "Máx/Mín",
        capitalization: "Capitalización",
        companyValue: "Valor de la Empresa",
        annualPerformance: "Rendimiento Anual",
        maxOfTheYear: "Máx. del Año",
        minOfTheYear: "Mín. del Año",
        averageDailyVolume: "Volumen Promedio Diario (3M)",
        divYield: "Div. Yield",
        targetPrice: "Precio Objetivo",
        recommendation: "Recomendación",
        opening: "Apertura",
        numOrder: "Núm. Orden",
        purchaseDepth: "Compra",
        saleDepth: "Venta",
        volSale: "Vol. Venta",
        numOrders: "Núm. Ordenes",
        move: "Mover",
        options: "Opciones",
        ticker: "Ticker",
        addInstrumentTicker: "Instrumentos en ticker",
        addSearchInstrumentTicker:
          "Buscar instrumento por clave o por tipo de mercado",
        atLeast5instruments:
          "Puedes guardar como mínimo 5 instrumentos y como máximo 20",
        searchInstrument: "Buscar el instrumento",
        quantity: "Cantidad",
        ppp: "PPP",
        liquidation: "Liquidación",
        graphDesc: "Gráfica",
        sevenDays: "7 días",
        thirtyDays: "30 días",
        ofYear: "Del año",
        lastTwelveMonths: "Últ. 12 m.",
        pupa: "P/UPA",
        pvl: "PVL",
        transmitter: "Instrumento",
        limitInstrumentsTicker:
          "Limite de elementos excedido, te sugerimos eliminar uno",
        addInstrumentsTicker: "Se han guardado los cambios en ticker",
        maxInstrumentsNumber: "Puedes agregar hasta 20 instrumentos",
        limitMaxInstrumentsTicker:
          "No se pueden agregar más de 20 instrumentos al ticker.",
        suggestedForex: "Divisas sugeridas",
        suggestedStocks: "Capitales sugeridos",
        predefinedIpc: "IPC",
        predefinedBanorte: "Fondos Banorte",
        spreadVariation: "Spread (%)",
        instrumentType: "Tipo de instrumento",
        closingPrice: "Cierre Anterior",
        closingPriceFunds: "Precio",
        change: "Variación",
        operator: "Operadora",
        fundType: "Tipo de fondo de inversión",
        investorType: "Tipo de inversionista",
        term: "Plazo",
        minimumMount: "Monto mínimo de inversión",
        purchasePriceVariation: "Variación precio de compra",
        salePriceVariation: "Variación precio de venta",
        dailyYield: "Rendimiento diario",
        searchInstrumentTable: "Busca por símbolo o descripción",
        searchInThisList: "Buscar en esta lista...",
        downloadReport: "Descargar reporte",
        selectPeriod: "Elige el tipo de informe y el periodo",
        type: "Tipo",
        since: "Desde",
        until: "Hasta",
        fundamental: "Información fundamental",
        news: "Noticia",
        newsDate: "Fecha",
        important: "Importante",
        reports: "Reportes financieros",
        deleteInstrument: "Instrumento eliminado",
        informationNotAvailable: "Información no disponible",
        financialReports: "Reportes Financieros",
        brent: "Brent",
        wti: "WTI",
        wts: "WTS",
        istmo: "ISTMO",
        maya: "MAYA",
        olmeca: "OLMECA",
        mezcla: "MEZCLA",
        shcp: "SHCP (A)",
        dif: "DIF (B)",
        petroleum: "Petroleo",
        today: "Hoy",
        thisWeek: "Esta semana",
        thisMonth: "Este mes",
        Yesterday: "Ayer",
        fuelOil: "Combustóleo (A)",
        difference: "Diferencia % Nivel previo",
        gasoline: "Gasolina (A)",
        naturalGas: "Gas natural (B)",
        naturalGasName: "Gas Natural",
        preciousMetals: "Metales Preciosos",
        metals: "Metales",
        unitVariationA: "Variación unitaria (A)",
        yesterday: "Día anterior",
        yesterdayPP: "Ayer a las",
        quotation7: "Cotización 7 días",
        quotation30: "Cotización 30 días",
        industrialMetals: "Metales industriales",
        dollars: "Dórales",
        pesos: "Pesos",
        variation7days: "Variación 7 días",
        variarion30days: "Variación 30 días",
        variationYear: "Variación en el año",
        frequency: "Frecuencia",
        unitValue: "Valor unitario",
        financialIndicators: "Indicadores Financieros",
        financeCurrency: "Finanzas y Monedas",
        pricesWages: "Precios y salarios",
        interestChanges: "Interés y cambio",
        publicAccounts: "Cuentas Públicas",
        employmentProduction: "Empleo y Producción",
        externalAccounts: "Cuentas externas",
        cetesPrimaryMarket: "Cetes Mercado Primario",
        primaryMarketBonds: "Bonos Mercado Primario",
        Commodities: "Metales",
        bondesPrimaryMarket: "Bondes Mercado Primario",
        usBonds: "Bonos EE. UU.",
        workspacesLimitDelete:
          "Has alcanzado el límite de espacios permitidos a eliminar.",
        varPercentage: "Var. %",
        deselectAll: "Deseleccionar todo",
        removeStickers: "Remover Stickers",
        removeDrawings: "Remover Dibujos",
        studiesLists: "Listado de estudios",
        DetailPurse: "Detalle de cartera",
        hidePurse: "Ocultar Cartera",
        showPurse: "Mostrar Cartera",
        reloadPurse: "Recargar Cartera",
        capitals: "Capitales",
        money: "Fondos",
        moneyTilte: "Fondo",
        marketMoney: "Mercado de Dinero",
        cash: "Efectivo",
        editContract: "Editar Contrato",
        contract: "Contrato",
        porLiquidar: "Efectivo por liquidar",
        operacionesHistoricas: "Movimientos",
        composicionCartera: "Composición de cartera",
        cancelPending: "Cancelar pendientes",
        pendings: "Pendientes",
        confirm: "Sí",
        questionPendings: "¿Deseas cancelar la orden?",
        operationDetail: "Detalle de orden",
        asign: "Asignada",
        pending: "Pendiente",
        orderCanceled: "Orden cancelada",
        fundamentalAnalysis: "Análisis fundamental",
        technicalAnalysis: "Análisis técnico",
        noResults: "No hemos encontrado nada por aquí",
        newSearch: "Por favor busca con otra palabra",
        newOperation: "OPERAR",
        titleNewOperation: "Hacer nueva operación",
        subTitleNewOperation: "Busca y elige el instrumento",
        changeToChart: "Cambiar a gráfica",
        changeToDetail: "Cambiar a detalle",
        changeToList: "Cambiar a lista",
        mrkMoney: "Mdo. de Dinero",
        titleTradingOperation: "Detalle de orden",
        orderResume: "Resumen de la orden",
        profPosturas: "Profundidad de posturas",
        consolidateProfPosturas: "Profundidad de posturas consolidadas",
        buy: "COMPRA",
        sell: "VENTA",
        continue: "Continuar",
        confirmOrder: "Confirmar",
        increment100: "100",
        increment1000: "1,000",
        increment10000: "10,000",
        dateLimit: "Fecha límite",
        titleNumber: "No. de títulos",
        showAOption: "Elige una opción",
        sendChanel: "Canal de envío",
        liquidez: "Liquidez",
        orderType: "Tipo de orden",
        position: "Posición",
        protectMarket: "Mercado con protección",
        limitPrice: "Limitada por precio",
        bestEject: "Mejor ejecución",
        consolidatedBMV: "Acciones consolidadas",
        consolidatedBMVSIC: "Acciones y SIC consolidadas",
        consolidatedSIC: "SIC consolidados",
        titleRegistredRequest: "Solicitud registrada",
        orderGenerated: "La orden se generó con el folio",
        requestFirstPart: "La solicitud fue registrada para la",
        requestTitle: "título",
        requestTitles: "títulos",
        requestSeccondPart: " de la emisora",
        requestFundSeccondPart: " del fondo ",
        requestFundThirdPart: "con un subtotal de $",
        viewMyOperations: "Ver mis operaciones",
        toolTipProtectMarket: "La orden será ejecutada en la BMV.",
        toolTipPriceLimit: "La orden será asignada a la mejor ejecución.",
        toolTipWS: "Debes mantener como mínimo un espacio de trabajo",
        comparatives: "COMPARATIVOS DE REPORTES FINANCIERO NOMINALES",
        disclaimerExcel:
          'Términos Legales: Las marcas, logotipos, signos distintivos, nombres comerciales, diseños industriales, personajes y demás derechos de Propiedad Intelectual, en lo sucesivo la "Propiedad Intelectual", exhibidos en los “documentos descargables” de BeTrading®, son propiedad de Banco Mercantil del Norte, S.A., Institución de Banca Múltiple, Grupo Financiero Banorte (en adelante Banorte) y de terceros según sea el caso, sin que pueda entenderse que por el simple hecho de acceder a los mismos se tenga derecho alguno sobre dicha Propiedad Intelectual. Se prohíbe usar la Propiedad Intelectual sin el permiso por escrito de Banorte o del tercero a quien pertenezca la Propiedad Intelectual. Todo el contenido exhibido o disponible a través de estos documentos está protegido por la legislación nacional e internacional de Derechos de Autor. Se prohíbe modificar, copiar, distribuir, transmitir, exhibir, publicar, vender, otorgar licencias, crear trabajos derivados o utilizar cualquier contenido del documento para cualquier fin comercial, público o ilegal.',
        businessName: "Razón social",
        comparative: "COMPARATIVO",
        initialYear: "Año Inicial",
        finalYear: "Año Final",
        keyCSV: "CLAVE",
        textCSV: "TEXTO",
        folioCSV: "Orden",
        symbolCSV: "Instrumento",
        orderTypeCSV: "Operación",
        sharesCSV: "Títulos",
        priceCSV: "Precio Solicitado",
        unitPriceCSV: "Precio unitario",
        totalCSV: "Importe",
        taxCSV: "% Corretaje",
        sharesAssignedCSV: "Títulos Asignados",
        sharesCanceledCSV: "Títulos Cancelados",
        orderDateCSV: "Fecha de Operación",
        orderDate: "Fecha de operación",
        expirationDateCSV: "Vigencia",
        orderTimeCSV: "Hora",
        priceForTitles: "Precio por títulos",
        detailBuy: "Detalle de la compra",
        detailSell: "Detalle de la venta",
        amountToBuy: "Importe a comprar",
        typeSell: "Tipo de venta",
        totalSell: "Venta total",
        partialSell: "Venta parcial",
        byNumberOfTitlesSell: "Venta por número de títulos",
        numberOfTitlesNewOperation: "Número de títulos",
        sellFor: "Venta por",
        numberOdContracts: "No. de contrato",
        found: "Fondo",
        fundPriceTotal: "Saldo actual de fondo",
        priceForTitle: "Precio*",
        priceForTitleLegend: "* Precio de referencia por título",
        titlesForBuy: "Títulos a comprar",
        requestDate: "Fecha de solicitud",
        registerDate: "Fecha de registro",
        validityDate: "Fecha de vigencia",
        priorityVolume: "Prioridad volumen",
        priorityVolumeTooltip:
          "Se enviará la orden dando prioridad al volumen con base a lo disponible en las bolsas de valores, de acuerdo a las nuevas reglas de operación.",
        settlementDate: "Fecha de liquidación",
        settlementDateCSV: "Fecha liquidación",
        date: "Fecha",
        subTotal: "Subtotal",
        noteFondRegistred1:
          "La solicitud será registrada de acuerdo a las particularidades de cada Fondo de Inversión conforme al prospecto de inversión. Operación sujeta al Contrato de Intermediación Bursátil celebrado entre usted (cliente) y Banorte Casa de Bolsa. El monto ingresado se ajustará de acuerdo al precio del fondo, multiplicado por el número de títulos a operar. El importe final del retiro, así como el precio al que se efectuará la",
        noteFondRegistred2: "se conocerán el día de la liquidación",
        noteFondConfirmation1:
          "La solicitud será registrada de acuerdo a las particularidades de cada Fondo de Inversión conforme al prospecto de inversión. Operación sujeta al Contrato de Intermediación Bursátil celebrado entre usted (cliente) y Banorte Casa de Bolsa. El monto ingresado se ajustará de acuerdo al precio del fondo, multiplicado por el número de títulos a operar. El importe final del retiro, así como el precio al que se efectuará la",
        noteFondConfirmation2: "se conocerán el día de la liquidación",
        currentLevel: "Nivel actual",
        previousLavel: "Nivel anterior",
        contractLoadError: "Error en la carga de contrato(s)",
        loadError: "Error en carga",
        repeatedContract:
          "Ya existe un contrato con ese nombre, por favor elige otro",
        moreLongerName: "Elige un nombre más largo",
        onLoad: "Cargando...",
        loadContracts: "Cargando contrato(s)",
        errorLogin: "Usuario o contraseña incorrectos.",
        closingHours: "Horario",
        emptyField: "Ingresa un nombre",
        serviceUnableProfPosturas:
          "No cuenta con el servicio de Profundidad de posturas. Contacte a su asesor Banorte",
        portfolioDetailLoadError: "Error en la carga de detalle de cartera",
        results: "Resultados",
        hourDone: "Hora hecho",
        dateDone: "Fecha hecho",
        getFeeError:
          "Error en la carga de comisión, no se puede continuar con la operación",
        toolTipDate:
          "Puedes consultar hasta un máximo de 90 días a partir de la fecha de hoy (fechas anteriores a la actual)",
        toolTipDateNew:
          "Puedes consultar hasta un rando de 30 días (fechas anteriores a la actual)",
        loadingInfoError: "Error con la carga de información",
        profPosturasTitle: "Profundidad de posturas",
        lastUpdate: "Última actualización",
        legalWaring: "AVISO LEGAL:",
        settings: "Ajustes",
        appearance: "Apariencia",
        tutorials: "Tutoriales",
        support: "Soporte",
        alarms: "Alarmas",
        personalization: "Personalización",
        warningMessage: "Esta acción es irreversible ¿estás seguro?",
        warningMessage2: "Esta acción es irreversible.",
        cancelOrder: "Cancelar orden",
        warningMessageCancelOrder:
          "Esta orden ya ha sido asignada y no es posible modificarla.",
        toolTipNoWorkingDay:
          "Lo sentimos, por el momento, no puedes operar al ser día inhábil",
        logOutQuestion: "¿Deseas cerrar sesión?",
        operationError: "Ha ocurrido un error al procesar tu transacción",
        processOperation: "Tu orden se está procesando",
        frequentQuestions: "Preguntas frecuentes",
        askReportProblem: "¿Te gustaría reportar un problema?",
        reportProblem: "Reportar un problema",
        usefulInformation: "¿Te fue útil esta información?",
        textYes: "Si",
        textNo: "No",
        noPositionOnSymbol: "Por el momento, no cuenta con títulos para vender",
        noAvailablePositions: "Sin posiciones disponibles",
        noEnoughTitles: "No se cuenta con los títulos suficientes",
        selectOrderType: "Selecciona el tipo de orden",
        titlesMoreOfOne: "Ingresa como mínimo 1 título",
        notLiquid:
          "No cuentas con la liquidez suficiente para registrar la orden.",
        limiteListaEstudios:
          "Has alcanzado el límite de listas de estudios permitidos a eliminar.",
        active: "Activas",
        inactive: "Inactivas",
        alarmNotFound: "Ninguna alarma asociada",
        addAlarm: "Agregar alarma",
        lowerLimit: "Del límite inferior",
        upperLimit: "Al límite superior",
        newAlarm: "Nueva alarma",
        addName: "Agrega un nombre para identificarla",
        alarmType: "Tipo",
        condition: "Condición",
        priceMustBeHigher: "El precio debe ser mayor a 0",
        mountMustBeHigher: "El monto a operar debe de ser mayor a 0",
        alarmCreated: "Alarma creada",
        duplicateAlarm: "El nombre de la alarma se encuentra duplicada",
        notLiquid2: "No se cuenta con liquidez suficiente",
        total: "Total",
        messageAmount:
          "No cuenta con los títulos suficientes para completar la operación",
        lessAmountFund:
          "El monto a vender debe ser mayor al precio unitario del fondo",
        messageTitle:
          "Ingrese un valor menor o igual al número de títulos en posesión",
        financialReasons: "Razones Financieras",
        orders: "Órdenes",
        geoHeatmapTitle: "Índices del Mundo",
        allAlarms: "Todas las alarmas",
        getComposition: "Consultando posición",
        noInformation: "Sin información disponible",
        consultingInformation: "Consultando información",
        capitalsDisclaimer:
          "Nota: Operación sujeta al contrato de intermediación bursátil celebrado entre usted (cliente) y Banorte Casa de Bolsa. El monto de inversión se ajustará de acuerdo al precio del instrumento seleccionado, multiplicado por el número de títulos a operar y, la comisión de corretaje se calculará en función de dicho monto. ",
        alarmDeleted: "Alarma eliminada",
        deleteAlarm: "Eliminar alarma",
        confirmationText: "Esta acción es irreversible ¿estás seguro?",
        orderColumn: "Ordenar columna",
        blockedColumn: "Columna bloqueada",
        guvernmentDebt: "Deuda Gubernamental",
        couponRate: "Tasa cupón",
        purchaseRate: "Tasa de compra",
        salesRate: "Tasa de venta",
        selectFund: "Selecciona un fondo",
        fundSellEmpty: "Por el momento, no cuenta con títulos para vender",
        serviceError: "Error en el servicio intenta más tarde",
        geolocalitionError:
          "No fue posible obtener tu ubicación, valida que se encuentre activa en tu navegador.",
        balance: "Posición",
        editAlarm: "Editar alarma",
        editedAlarm: "Se guardaron los cambios en la alarma",
        instrumentNoOperable:
          "Lo sentimos, no se puede hacer la operación, instrumento temporalmente suspendido",
        financialReport: "Reporte Financiero",
        instrumentNoOperable2:
          "Lo sentimos, no se puede hacer la operación, instrumento temporalmente suspendido",
        instrumentDayInhabil:
          "Lo sentimos, por el momento no se puede operar al ser día inhábil",
        channel: "Canal",
        callout: "Llamada",
        continuous: "Continuo",
        freeform: "Forma libre",
        retracement: "Fib retracement",
        fibprojection: "Fib projection",
        pitchfork: "Horquilla",
        quadrant: "Quadrant line",
        ray: "Ray",
        regression: "Regresion line",
        segment: "Segment",
        tirone: "Tirone Levels",
        trendline: "Trend line",
        doodle: "Forma libre",
        check: "Check",
        cross: "Cross",
        heart: "Heart",
        arrow: "Arrow",
        numberOfTitles: "No. de Títulos",
        numberOfTitlesCSV: "Número de títulos",
        fundPrice: "Precio del fondo",
        foundBalance: "Valuación",
        amountAlarm: "Monto",
        amountMustBeHigher: "El monto debe ser mayor a 0",
        percentageMustBeHigher: "La variación debe ser mayor a 0",
        rangeMustBeHigher:
          "El límite superior debe ser mayor al límite inferior",
        saveStudies: "Guardar lista de estudios",
        savedStudies: "Lista de estudios guardada",
        saveStudiesError:
          "No fue posible completar tu solicitud, inténtalo nuevamente",
        sessionExpiredTitle: "Sesión expirada",
        sessionExpiredSubtitle:
          "Su sesión ha expirado. Usted está saliendo de Be Trading",
        sessionExpiredAutomatly: "Cierre de sesión automáticamente",
        sessionExpiredAutomatlyRemaining: "Su sesión se cerrará en 5 minutos.",
        sessionExpiredAutomatlyCounter: "Su sesión expirará en:",
        sessionExpiredAutomatlyContinueSession: "Continuar en la sesión",
        sessionExpiredAutomatlySeconds: "segundos",
        sessionFinalizedTitle: "Sesión finalizada",
        sessionFinalizedSubtitle: "Su sesión de BeTrading ha finalizado",
        savedEdition: "Edición guardada",
        choose: "Elegir",
        calculate: "Consultar",
        newDateRange: "Por favor busca con otras fechas",
        kagi: "Set Reversal Percentage",
        renko: "Set Brick Size",
        rangeBars: "Set Range",
        lineBreak: "Set Price Lines",
        autoSelect: "Auto Select",
        dateAndHourPostures: "Fecha y hora de posturas",
        monday: "Lunes",
        tuesday: "Martes",
        wednesday: "Miércoles",
        thursday: "Jueves",
        friday: "Viernes",
        saturday: "Sábado",
        sunday: "Domingo",
        january: "Enero",
        february: "Febrero",
        march: "Marzo",
        april: "Abril",
        may: "Mayo",
        june: "Junio",
        july: "Julio",
        august: "Agosto",
        september: "Septiembre",
        october: "Octubre",
        november: "Noviembre",
        december: "Diciembre",
        contractErrorMessageTab:
          "Lo sentimos, por el momento tu Portafolio no esta disponible , inténtalo más tarde",
        contractErrorMessageOperation:
          "Lo sentimos, por el momento tu Contrato no esta disponible para operar , inténtalo más tarde",
        loadingTableData: "Cargando información",
        newWorkspaceAdded: "Espacio de trabajo agregado",
        addNewStudyList: "Agregar lista de estudio",
        tooltipStudys: "Debes mantener como mínimo una lista de estudios",
        reject: "Rechazar",
        getGeolocalization: "Obteniendo geolocalización",
        setGeolocalization: "Registrando geolocalización",
        relatedTopic: "Tema relacionado",
        send: "Enviar",
        reportSent: "Reporte enviado",
        businessDayError:
          "Ha ocurrido un error, por favor intente refrescar su navegador",
        geolocalization: "Geolocalización",
        general: "Configuración",
        visualize: "Visualización",
        spacing: "Espaciado",
        standar: "Estandar",
        compact: "Compacta",
        letterSize: "Tamaño de letra",
        contrast: "Contraste",
        minPlus: "Min/Plus",
        minPlusPercentage: "Min/Plus %",
        deletelastInstrument:
          "Debes mantener como mínimo un instrumento por tabla",
        maxDay: "Máximo del día",
        minDay: "Mínimo del día",
        max12Months: "Máximo últimos 12 meses",
        BanorteAnalysis: "Análisis Banorte",
        AnalysisTools: "Herramienta de Análisis",
        exitBeTradingToAnalisis: "Saliendo de BeTrading",
        exitBeTradingToAnalisisSubTitle:
          "Usted está a punto de salir de BeTrading, confirmándole que su sesión quedará activa ¿Desea continuar a Análisis Banorte?",
        confirmCancelOrder: "¿Desea cancelar su orden?",
        no: "No",
        confirmCancel: "Si, Cancelar",
        tooltiModulepPredefinedTable:
          "Agregue una lista de instrumentos preconfigurada. Una vez guardada no se podrán añadir o eliminar instrumentos a estas listas",
        tooltipModuleNewTable:
          "Agregue o elimine instrumentos en una lista de seguimiento personalizada",
        tooltipModuleChart:
          "Agregue la gráfica del comportamiento de algún instrumento",
        tooltipModuleNews:
          "Agregue una tarjeta y visualice los últimos titulares de noticias",
        tooltipModuleHeatmap:
          "Agregue un mapa, el color de cada país le muestra el rendimiento de sus principales índices accionarios",
        percentage: "Porcentaje",
        unitary: "Unitaria",
        titleResetWorkspace: "¿Desea aplicar los cambios?",
        subTitleResetWorkspace:
          "Los espacios de trabajo creados en el módulo Mis Mercados se borrarán de su plataforma.",
        quickCategorys: "Categorias rapidas",
        status: "Estatus",
        tooltipTreemap:
          "Agregue un mapa de calor, muestra el comportamiento de las emisoras por tipo de mercado",
        treemap: "Heatmap de emisoras",
        noResultsTreemap: "No existen emisoras bajo esta coincidencia",
        thisSpace: "Este espacio",
        otherSpace: "Otro espacio",
        newSpace: "Nuevo espacio",
        mySpaces: "Mis espacios",
        duplicateNewCard: "Duplicar tarjeta en",
        economicCalendar: "Calendario económico",
        tooltipeconomicCalendar:
          "Agregue una tarjeta para visualizar las fechas en que se producirán eventos o noticias financieras relevantes",
        analysisToolTracingTooltip:
          "Informe exhaustivo y detallado de los activos de inversión. Cada reporte contiene información actualizada e histórica para valorar los instrumentos.",
        analysisToolTracingTitle: "Reporte de emisoras",
        analysisToolReportTooltip:
          "Análisis de mercado que te permite dar seguimiento diario al comportamiento de tus activos.",
        analysisToolReportTitle: "Seguimiento de emisoras",
        analysisToolOportunityTooltip:
          "Conoce instrumentos interesantes a considerar para comprar.",
        analysisToolOportunityTitle: "Oportunidades de compra",
        analysisToolCompareTooltip:
          "Compara diferentes activos entre sí para toma de decisiones.",
        analysisToolCompareTitle: "Comparador gráfico",
        analysisToolModalTitle: "Saliendo de BeTrading",
        analysisToolModalContent:
          "Estás a punto de salir de la página o del dominio Banorte y estás a punto de ser direccionado a una página externa la cual se rige bajo sus propias políticas de seguridad y de privacidad.",
        tradingView: {
          savedCharts: {
            deleted: "El diseño de gráfico se eliminó correctamente.",
            maxReached:
              "Ya guardaste el máximo de diseños de gráficos, si deseas agregar uno nuevo podrías eliminar otro.",
            saved: "El diseño de gráfico se guardó correctamente.",
            title: "Diseño de gráficos"
          },
          templates: {
            maxReached:
              "El máximo de plantillas es 5, si deseas agregar una nueva podrías eliminar otra.",
            saved: "La plantilla se guardó correctamente.",
            title: "Plantillas",
            notSpecialCharacter:
              "El nombre de la plantilla no puede contener caracteres especiales"
          }
        },
        operationTicket: {
          title: "Boleta de operación",
          message:
            "Debido a que la orden se capturó fuera del horario de operación, se considerará para el siguiente día hábil."
        },
        newFilterSearch: "Por favor busca con otro filtro",
        noInformationComposition:
          "Durante el proceso SVT - 13 no existen registros para la consulta"
      }
    }
  },
  fallbackLng: "es",
  debug: false,
  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },
  react: {
    wait: true
  }
});

export default i18n;
