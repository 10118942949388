export const DARKER_GREY = "#1c2129";
export const DARKER_GREY_ALPHA = "#1c212900";
export const DARK_GREY = "#232933";
export const DARK_GREY_ALPHA = "#23293380";
export const NEW_DARK_GREY = "#2e3135";
export const NEUTRAL_GREY = "#363d47";
export const DARK = "#3F4247";
export const DAT_GREY = "#374252";
export const GREY = "#3e4652";
export const LIGHT_GREY = "#878e99";
export const ALMOST_WHITE = "#c6d1e0";
export const INCREASING_GREEN = "#00b03d";
export const DECREASING_RED = "#b72521";
export const ACCENT_BLUE = "#7a98eb";
export const BACKGROUND_COLOR = "#171a1f";
export const DARK_BACKGROUND_COLOR = "#0c0d0f";
export const LIGTHER_GREY = "#cedaeb";
export const WHITE = "#ffffff";
export const WHITE_50 = "rgba(255,255,255,0.50)";
export const BOTICELLI = "#c6d1e0";
export const INDICATOR_BLUE = "#0144e3";
export const TICKER_TEXT_COLOR = "#fafeff";
export const BLACK = "#000000";
export const BLACK_30 = "rgba(0,0,0,0.3)";
export const BLACK_50 = "rgba(0,0,0,0.5)";
export const BLACK_80 = "rgba(0,0,0,0.8)";
export const BLACK_APLHA = "#00000020";
export const LIGHTEST_GRAY = "#a2aab8";
export const YELLOW = "#fff202";
export const SALMON = "#ED9077";
export const NEW_PINK = "#DAC9FF";
export const MAUVE = "#B797FF";
export const PALE_LAVENDER = "#dcccff";
export const THUNDER = "#323132";
export const SHARK = "#1c1d1e";
export const SHARK_70 = "rgba(28, 29, 30, 0.75)";
export const SHARK_30 = "rgba(28,29,30,0.3)";
export const SHARK_DARK = "#252729";
export const EBONY = "#080b14";
export const ABBEY = "#565b60";
export const STEEL_GRAY = "#3d4147";
export const RAINMASTER = "#98a3a9";
export const CHARLOTTE = "#ceebf5";
export const SHUTTLE_GREY = "#5b6770";
export const PALE_BLUE = "#bfcdd4";
export const THUNDER_DARK = "#282929";
export const HEATHER = "#bfcdd4";
export const WHITE_LESS_WHITE = "#eef6f8";
export const BLACK_RUSSIAN = "#11141d";
export const SAIL = "#AAE4F9";
export const MISCHKA = "#a3acb9";
export const SEA_BUCKTHORN = "#fa8d29";
export const SEA_BUCKTHORN_20 = "rgba(250, 141, 41, 0.2)";
export const ORANGE_ROUGHY = "#c2531a";
export const ORANGE_ROUGHYB = "#c2531B";
export const PICKLED_BEAN = "#6e3528";
export const CARNATION = "#F96563";
export const HEATHER_LIGHT = "#b4bdcc";
export const ANZAC = "#e6b652";
export const ANZAC_15 = "rgba(230, 182, 82, 0.15)";
export const BURNT_SIENNA = "#E67152";
export const ROYAL_BLUE = "#5277E6";
export const TRADEWIND = "#5AB7B2";
export const CHRISTINE = "#D35E13";
export const TEAL = "#00837E";
export const RUM = "#7e6990";
export const PINK_SALMON = "#FED5CB";
export const BRIGHT_RED = "#db3345";
export const TORCH_RED = "#eb0029";
export const TORCH_RED_RGBA = "rgba(235, 0, 41, 0.75)";
export const TORCH_RED_50 = "rgb(235, 0, 41, 0.5)";
export const GREY_ALPHA = "#dadde9";
export const CHARCOAL_GREY = "#444950";
export const BURNED_RED = "#520000";
export const ALTO = "#e0e0e0";
export const GALLERY = "#ededed";
export const BRICK = "#b72521";
export const PERSIAN_GREEN = "#009490";
export const FLUSH_ORANGE = "#ff8300";
export const PRAIRIE_SAND = "#9A3820";
export const HEATHER2 = "#bac4d2";
export const WHITE_SMOKE = "#F2F2F2";
export const PATTENS_BLUE = "#E2E5E7";
export const PATTENS_BLUE_75 = "rgba(226, 229, 231, 0.75)";
export const PATTENS_BLUE_80 = "rgba(226,229,231,0.8)";
export const SPUN_PEARL = "#A0A0A3";
export const SPUN_PEARL_50 = "rgba(160,160,163,0.5)";
export const SPUN_PEARL_40 = "rgba(160,160,163,0.4)";
export const NOBEL = "#b3b3b3";
export const GRAY_EVEN = "#303337";
export const GRAY_EVEN_30 = "rgba(48,51,55,0.3)";
export const GRAY_EVEN_35 = "rgba(48, 51, 55, 0.35)";
export const GRAY_EVEN_85 = "rgba(48, 51, 55, 0.85)";
export const SILVER = "#636366";
export const SILVER_50 = "rgba(99,99,102,0.5)";
export const SILVER_10 = "rgba(99,99,102,0.1)";
export const SILVER_85 = "rgba(99, 99, 102, 0.85)";
export const PLATINUM = "#EAEAEA";
export const KEPPEL = "#4EC2AB";
export const PERSIAN_GREEN_LIGHT = "#1E988A";
export const SPANISH_VIOLET = "#553278";
export const SILVER_LIGHT = "#C4C4C4";
export const TART_ORANGE = "#F44336";
export const SILVER_METALLIC = "#A2A2A2";
export const GREEN_LIGHT = "#B7F6AF";
export const PINK_LINEAR_GRADIENT = "#DE3974";
export const GRAY_SIX = "#54626F";
export const PEARL_30 = "rgba(235, 235, 245, 0.3)";
export const YELLOW_IMPORTANT = "#E6B652";
export const SPANISH_RED = "#EB0029";
export const RED_CHERRY = "#750014";
