import ACTION_TYPES from "./actionTypes";
import type { TradingReducer } from "./types";

const initialState = {
  contractList: [],
  contractLoading: true,
  contractError: false,
  portafolioDetail: null,
  portafolioDetailError: false,
  portafolioPerformance: null,
  portafolioPerformanceError: false,
  contratedServices: null,
  depthPositionContrated: false,
  finishGetCurrentPosition: false,
  positionUnavailable: false
};

const reducer: TradingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_CONTRACTS:
      return {
        ...state,
        contractList: action.contractList,
        contractError: action.contractError,
        contractLoading: action.contractLoading
      };
    case ACTION_TYPES.SET_PORTAFOLIO_DETAIL:
      return {
        ...state,
        portafolioDetail: action.portafolioDetail,
        portafolioDetailError: action.portafolioDetailError
      };
    case ACTION_TYPES.SET_PORTAFOLIO_PERFORMANCE:
      return {
        ...state,
        portafolioPerformance: action.portafolioPerformance,
        portafolioPerformanceError: action.portafolioPerformanceError
      };
    case ACTION_TYPES.SET_CONTRATED_SERVICES:
      return {
        ...state,
        contratedServices: action.contratedServices,
        depthPositionContrated: action.depthPositionContrated
      };
    case ACTION_TYPES.RESET_STATE:
      return { ...initialState };
    case ACTION_TYPES.SET_FINISH_GET_CURRENT_POSITION:
      return {
        ...state,
        finishGetCurrentPosition: true
      };
    case ACTION_TYPES.SET_POSITION_UNAVAILABLE:
      return {
        ...state,
        positionUnavailable: action.positionUnavailable
      };
    default:
      return state;
  }
};

export default reducer;
