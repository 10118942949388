import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
// $FlowFixMe
import { PersistGate } from "redux-persist/integration/react";
import { I18nextProvider } from "react-i18next";
import "./index.css";
// $FlowFixMe
import { SettingsProvider } from "hooks/useSettings";
// $FlowFixMe
import { AlarmsProvider } from "hooks/useAlarms";
import App from "./App";
// $FlowFixMe
import * as serviceWorker from "./serviceWorker";
import i18n from "./i18n/index";
import store, { persistor } from "./ducks/store";

const root = document.getElementById("root");

if (root !== null) {
  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SettingsProvider>
            <AlarmsProvider>
              <App />
            </AlarmsProvider>
          </SettingsProvider>
        </PersistGate>
      </Provider>
    </I18nextProvider>,
    root
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
