const ACTION_TYPES = {
  SET_CONTRACTS: "SET_CONTRACTS",
  SET_PORTAFOLIO_DETAIL: "SET_PORTAFOLIO_DETAIL",
  SET_PORTAFOLIO_PERFORMANCE: "SET_PORTAFOLIO_PERFORMANCE",
  SET_CONTRATED_SERVICES: "SET_CONTRATED_SERVICES",
  RESET_STATE: "RESET_STATE",
  SET_FINISH_GET_CURRENT_POSITION: "SET_FINISH_GET_CURRENT_POSITION",
  SET_POSITION_UNAVAILABLE: "SET_POSITION_UNAVAILABLE"
};

export default ACTION_TYPES;
