export const fontSizes = {
  small: {
    fontSize: {
      "11-13": 11,
      "12-14": 12,
      "10-12": 10,
      "14-16": 14,
      "18-20": 18
    }
  },
  large: {
    fontSize: {
      "11-13": 13,
      "12-14": 14,
      "10-12": 12,
      "14-16": 16,
      "18-20": 20
    }
  }
};

export const spacings = {
  standar: {
    spacings: {
      tables: "8px 3px",
      heatmapTable: "8px 0px",
      searchBarResults: "8px 0px 8px 8px",
      alarmsTable: "8px 12px",
      newsHeader: "18px",
      timeNewsHeader: "10px",
      newsSidebarBottom: "56px",
      headerGeoTable: "0px 8px",
      searchBar: "8px 30px 8px 22px",
      postures: "18px",
      inputTradingContainer: "8px",
      heightTradingModal: "592px",
      disclaimerConfirmation: "12px",
      registreRequestSpacing: "16px",
      marginBottomValueContainer: "18px",
      marginBottomMarketContainer: "10px",
      marginBottomInputSupport: "32px",
      marginBottomDisclaimerSupport: "8px",
      marginBottomAlarm: "16px",
      marginBottomAlarmType: "24px",
      foundItemsBottomMargin: "8px",
      foundItemsHeight: "30px",
      foundBottomMargin: "16px",
      foundAlarmDescriptionBottomMargin: "16px",
      foundAlarmNameBottomMargin: "6px"
    }
  },
  compact: {
    spacings: {
      tables: "4px 3px",
      heatmapTable: "4px 0px",
      searchBarResults: "4px 0px 4px 8px",
      alarmsTable: "4px 12px",
      newsHeader: "10px",
      timeNewsHeader: "6px",
      newsSidebarBottom: "52px",
      headerGeoTable: "0px 6px",
      searchBar: "4px 30px 4px 22px",
      postures: "6px",
      inputTradingContainer: "4px",
      heightTradingModal: "557px",
      disclaimerConfirmation: "8px",
      registreRequestSpacing: "12px",
      marginBottomValueContainer: "16px",
      marginBottomMarketContainer: "2px",
      marginBottomInputSupport: "24px",
      marginBottomDisclaimerSupport: "0px",
      marginBottomAlarm: "12px",
      marginBottomAlarmType: "16px",
      foundItemsBottomMargin: "4px",
      foundItemsHeight: "26px",
      foundBottomMargin: "12px",
      foundAlarmDescriptionBottomMargin: "8px",
      foundAlarmNameBottomMargin: "3px"
    }
  }
};
