const ACTION_TYPES = {
  START_LOADING: "START_LOADING",
  STOP_LOADING: "STOP_LOADING",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  UPDATE_IMAGE_INFO: "UPDATE_IMAGE_INFO",
  LOGIN_FAILED: "LOGIN_FAILED",
  UPDATE_CONNECTION_STATUS: "UPDATE_CONNECTION_STATUS",
  SET_BUSINESS_DAY: "SET_BUSINESS_DAY",
  SET_SHOW_VERSION: "SET_SHOW_VERSION",
  SET_GEOLOCALIZATION_ERROR: "SET_GEOLOCALIZATION_ERROR",
  SET_SHOW_GELOCATION_LOADER: "SET_SHOW_GELOCATION_LOADER",
  SET_EQUITY_FEE: "SET_EQUITY_FEE",
  SET_CLOUDFLARE_SERVER: "SET_CLOUDFLARE_SERVER",
  SET_SIDEBAR_TYPE: "SET_SIDEBAR_TYPE",
  SET_TICKER_OPERATION: "SET_TICKER_OPERATION",
  SET_SHOW_DEEP_POSTURES: "SET_SHOW_DEEP_POSTURES"
};

export default ACTION_TYPES;
