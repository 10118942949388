export const TABLE_BODY_ROWS = "tableBodyRows";
export const SHEET_TAB_BUTTON_CONTAINER = "SheetTabButtonContainer";
export const A_SHEET_TAB_WORKSPACE = "aSheetTabWorkspace";
export const A_SHEET_TAB_STUDIE_LIST = "A_SHEET_TAB_StudieList";
export const TABLE_ROW = "tableRow";
export const MATERIAL_TABLE_CELL = "materialTableCell";
export const ARTICLE_DATE_TIME = "articleDateTime";
export const CHANGE_INDICATOR_LABEL = "changeIndicatorLabel";
export const SEARCH_INPUT = "searchInput";
export const SEARCH_RESULTS = "searchResults";
export const ONCLICK_RESULTS = "onClickResults";
export const ONCLICK_RECENT_RESULT = "onClickRecentResults";
export const COLUMN_MENU_BUTTON = "columnMenuButton";
export const COLUMN_MENU = "columnMenu";
export const PERCENTAGE_ELEMENT = "percentage";
export const VARIATION_ELEMENT = "unitVariation";
export const TICKER_CONTAINER = "tickerContainer";
export const ARTICLE_CONTAINER = "articleContainer";
export const IMPORTANT_FLAG = "importantFlag";
export const TIMESTAMP_CONTAINER = "timestampContainer";
export const TIME_AGO_CONTAINER = "timeAgoContainer";
export const COLUMN_HEADER = "columnHeader";
export const SELECTED_COLUMN = "selectedColumn";
export const MOVE_COLUMN_RIGHT = "moveColumnRight";
export const MOVE_COLUMN_LEFT = "moveColumnLeft";
export const DELETE_COLUMN = "deleteColumn";
export const BUTTON_CONTAINER = "buttonContainer";
export const DROPDOWN_CONTAINER = "dropDrownContainer";
export const CLOSE_BUTTON = "closeButton";
export const INPUT_LAYOUT = "inputLayout";
export const DELETE_ITEM = "deleteItem";
export const RESPONSIVE_CHART = "responsiveChart";
export const CHART_CONTAINER = "chart";
export const SYMBOLS = "symbols";
export const INPUT_WS = "changeName";
export const MENU_CONTAINER = "menuContainer";
export const CHANGE_NAME = "changeNameButton";
export const STATUS_CONTAINER = "statusContainer";
export const COMPARE_CONTAINER = "compareContainer";
export const COMPARE_ELEMENT = "compareElement";
export const SNACKBAR_CONNECTION_STATUS = "snackbarConnectionStatus";
export const DELETE_WORKSPACE = "deleteWorkspaceButton";
export const DELETE_STUDIES_LIST = "deleteStudiesListButton";
export const DRAWING_TOOL = "drawingTool";
export const MODAL_CLOSE_BUTTON = "modalCloseButton";
export const MODAL_BACK_BUTTON = "modalBackButton";
export const SELECT_WIDGET_TYPE = "selectWidgetType";
export const CLOSE_ICON = "closeIcon";
export const MODAL_DOWNLOAD = "modalDownload";
export const MODAL_DATA = "modalData";
export const INPUT_NAME = "input-name";
export const CLICK_NAME = "click-name";
export const CLICK_SAVE = "click-save";
export const CLICK_NEWOPERATION = "click-new-operation";
export const MODAL_NEWOPERATION = "modal-new-operation";
export const CLICK_CANCEL = "click-cancel";
export const BADGES = "badge";
export const NEW_ALARM_INPUT_NAME = "new-alarm-input-name";
export const NEW_ALARM_ADD_ALARM_BUTTOM = "new-alarm-add-alarm-buttom";
export const NEW_ALARM_INPUT_VALUE = "new-alarm-input-value";
export const NEW_ALARM_INPUT_RANGEVALUE = "new-alarm-input-rangevalue";
export const ALARM_ADD_BUTTON = "alarm-add-button";
export const ALARMS_ICO_NOT_FUND = "alarm-ico-not-fund";
export const ALARMS_TAB_ACTIVE = "alarm-tab-active";
export const ALARMS_TAB_INACTIVE = "alarm-tab-inactive";
export const RANGE_BUTTON = "type-button";
export const DDL_SELECT = "ddl-select";
export const OPTION_ITEM = "option-item";
export const ORDERS_MENU_BUTTON = "orders-menu-button";
