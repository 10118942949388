import React, { useState, useEffect } from "react";
import { setGeolocalizationError } from "ducks/account/actions";
import { useSelector, useDispatch } from "react-redux";
import { getConfirmationGeolocalization, getGeolocalization } from "utils/user";
import Confirmation from "components/Geolocalization/Confirmation";
import LockedPositionModal from "components/Geolocalization/LockedPosition";
import { keyStorage } from "constants/globals";
import TradingApi from "apis/tradingApi";
import {
  setFinishGetCurrentPosition,
  setPositionUnavailable
} from "ducks/trading/actions";

type Props = {
  user: Object
};

function Geolocalization(props: Props) {
  const { user } = props;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showGeolocalizationmodal, setShowGeolocalizationmodal] = useState(
    false
  );

  const [geolocalization, setGeolocalization] = useState(null);
  const [timeouts, setTimeouts] = useState(0);
  const [error, setError] = useState(false);
  const geolocalizationError = useSelector(state =>
    state.account ? state.account.geolocalizationError : false
  );
  const positionUnavailable = useSelector(
    state => state.trading.positionUnavailable
  );
  const dispatch = useDispatch();

  const handleErrors = (
    // $FlowFixMe
    geoError: GeolocationPositionError,
    showModal: boolean
  ) => {
    if (geoError.code === geoError.PERMISSION_DENIED) {
      dispatch(setFinishGetCurrentPosition());
      const location = JSON.stringify({
        geolocalization: false,
        latitude: null,
        longitude: null
      });
      const locationB64 = btoa(location);
      sessionStorage.setItem(keyStorage.geolocalization, locationB64);
      if (showModal) {
        setShowGeolocalizationmodal(true);
      }
    }

    if (geoError.code === geoError.TIMEOUT) {
      setTimeouts(timeouts + 1);
    }

    if (geoError.code === geoError.POSITION_UNAVAILABLE) {
      dispatch(setFinishGetCurrentPosition());
      dispatch(setPositionUnavailable(true));
      setShowGeolocalizationmodal(true);
    }
  };

  const getLocalization = (showModal: boolean) => {
    navigator.geolocation.getCurrentPosition(
      value => {
        setGeolocalization({
          geolocalization: true,
          latitude: value.coords.latitude,
          longitude: value.coords.longitude
        });
        const location = JSON.stringify({
          geolocalization: true,
          latitude: value.coords.latitude,
          longitude: value.coords.longitude
        });
        const locationB64 = btoa(location);
        sessionStorage.setItem(keyStorage.geolocalization, locationB64);
        dispatch(setFinishGetCurrentPosition());
      },
      geoError => {
        handleErrors(geoError, showModal);
      },
      { timeout: 4000 }
    );
  };

  useEffect(() => {
    if (timeouts > 0 && timeouts < 5) {
      getLocalization(false);
    }
    if (timeouts === 5) {
      dispatch(setFinishGetCurrentPosition());
      dispatch(setPositionUnavailable(true));
      setShowGeolocalizationmodal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeouts]);

  useEffect(() => {
    if (!user) {
      return;
    }

    if (getConfirmationGeolocalization() === null) {
      TradingApi.getGeolocalization()
        .then(data => {
          if (data) {
            const confirmation = btoa(data.toString());
            sessionStorage.setItem(
              keyStorage.confirmationGeolocalization,
              confirmation
            );
            if (!getGeolocalization()) {
              getLocalization(true);
            }
          } else {
            if (!getGeolocalization()) {
              getLocalization(false);
            }
            setShowConfirmationModal(true);
            const confirmation = btoa("false");
            sessionStorage.setItem(
              keyStorage.confirmationGeolocalization,
              confirmation
            );
          }
        })
        .catch(() => {
          dispatch(setGeolocalizationError(true));
          if (!getGeolocalization()) {
            getLocalization(true);
          }
        });
    } else if (!getGeolocalization() && !positionUnavailable) {
      getLocalization(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (geolocalizationError) {
      setError(true);
    }

    if (!geolocalizationError && error) {
      const confirmationB64 = getConfirmationGeolocalization();
      if (confirmationB64) {
        const confirmation = atob(confirmationB64);
        setShowConfirmationModal(confirmation === "false");
        setError(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geolocalizationError]);

  const renderModal = () => {
    if (showGeolocalizationmodal) {
      return <LockedPositionModal isModal={true} />;
    }

    if (showConfirmationModal) {
      return <Confirmation geolocalization={geolocalization} />;
    }

    return null;
  };

  return renderModal();
}

export default Geolocalization;
