import { applyMiddleware, createStore } from "redux";
import type { Action, Store } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
// $FlowFixMe
import { persistStore, persistReducer } from "redux-persist";
// $FlowFixMe
import storageSession from "redux-persist/lib/storage/session";
import combinedReducers from "./combinedReducers";
import type { ReduxState, Reducers } from "./types";

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: ["trading"]
};

const middlewares = [];
middlewares.push(thunk);

if (process.env.REACT_APP_ENABLE_MIDDLEWARE_LOGGER === "true") {
  const logger = createLogger();
  middlewares.push(logger);
}

const persistedReducer = persistReducer(persistConfig, combinedReducers);

const store: Store<ReduxState, Action<Reducers>> = createStore(
  persistedReducer,
  applyMiddleware(...middlewares)
);

export const persistor = persistStore(store);

export default store;
