import { CONNECTION_STATUS, SIDEBAR_TYPES } from "constants/globals";
import ACTION_TYPES from "./actionTypes";
import type { AccountReducer } from "./types";

const initialState = {
  isLoading: false,
  isValidFromBackend: true,
  error: "",
  connectionStatus: CONNECTION_STATUS.CONNECTING,
  isBusinessDay: false,
  businessDayError: false,
  nonWorkingDays: [],
  imageInfo: null,
  showVersion: true,
  geolocalizationError: false,
  showGeolocationLoader: false,
  equityFee: null,
  cloudFlareServer: null,
  sidebarSelected: SIDEBAR_TYPES.CLOSE,
  showTicketOperation: false,
  showDeepPostures: false
};

const reducer: AccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.START_LOADING:
      return { ...state, isLoading: true };

    case ACTION_TYPES.STOP_LOADING:
      return { ...state, isLoading: false };

    case ACTION_TYPES.LOGIN_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        isValidFromBackend: false
      };

    case ACTION_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isValidFromBackend: true,
        error: "",
        imageInfo: action.imageInfo
      };

    case ACTION_TYPES.UPDATE_IMAGE_INFO:
      return { ...state, imageInfo: action.imageInfo };

    case ACTION_TYPES.UPDATE_CONNECTION_STATUS:
      return { ...state, connectionStatus: action.connectionStatus };

    case ACTION_TYPES.SET_BUSINESS_DAY:
      return {
        ...state,
        isBusinessDay: action.isBusinessDay,
        businessDayError: action.businessDayError,
        nonWorkingDays: action.nonWorkingDays
      };
    case ACTION_TYPES.SET_SHOW_VERSION:
      return {
        ...state,
        showVersion: false
      };
    case ACTION_TYPES.SET_GEOLOCALIZATION_ERROR:
      return {
        ...state,
        geolocalizationError: action.geolocalizationError
      };
    case ACTION_TYPES.SET_SHOW_GELOCATION_LOADER:
      return {
        ...state,
        showGeolocationLoader: action.showGeolocationLoader
      };
    case ACTION_TYPES.SET_EQUITY_FEE:
      return {
        ...state,
        equityFee: action.equityFee
      };
    case ACTION_TYPES.SET_CLOUDFLARE_SERVER:
      return {
        ...state,
        cloudFlareServer: action.cloudFlareServer
      };
    case ACTION_TYPES.SET_SIDEBAR_TYPE:
      return {
        ...state,
        sidebarSelected: action.sidebarSelected
      };
    case ACTION_TYPES.SET_TICKER_OPERATION:
      return {
        ...state,
        showTicketOperation: action.showTicketOperation
      };
    case ACTION_TYPES.SET_SHOW_DEEP_POSTURES:
      return {
        ...state,
        showDeepPostures: action.showDeepPostures
      };
    default:
      return state;
  }
};

export default reducer;
