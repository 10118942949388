import { createMuiTheme } from "@material-ui/core/styles";
import palletes from "./palette";
import overrides from "./overrides";
import { fontSizes, spacings } from "./typography";

const createTheme = (config: any) => {
  const fontSize = fontSizes[config.letterSize];
  const spacing = spacings[config.spacing];
  const pallete = palletes[config.theme];
  const override = overrides[config.theme];

  const themeOptions = { ...fontSize, ...spacing, ...override, ...pallete };
  const theme = createMuiTheme(themeOptions);

  return theme;
};

export default createTheme;
