/* eslint-disable prefer-destructuring */
// @flow

import { keyStorage } from "constants/globals";
import type { User } from "constants/types";
import Token from "./token";

const jwtDecode = require("jwt-decode");

function parseJwt(token: Token) {
  return jwtDecode(JSON.stringify(token));
}

export function getCurrentUser(): User | null {
  const user = sessionStorage.getItem(keyStorage.user);
  return user ? JSON.parse(user) : null;
}

export function getSicNumber(): string {
  const user = sessionStorage.getItem(keyStorage.user);
  return user ? JSON.parse(user).sic : "";
}

export function getIfTickerVisible(): boolean {
  const user = sessionStorage.getItem(keyStorage.user);
  return user ? JSON.parse(user).tickerVisible : false;
}

export function getLSUrl(): string {
  return String(process.env.REACT_APP_LS_URL);
}

export function getApiUrl() {
  return process.env.REACT_APP_WORKSPACE_URL;
}

export function setCurrentUser(user: User, token: Token): void {
  const accessToken = user.token;
  const jwt = parseJwt(token);
  const { userDB } = jwt;

  const obj = {
    ...user,
    accessToken,
    exp: jwt.exp,
    tickerVisible: false,
    ...userDB
  };

  return sessionStorage.setItem(keyStorage.user, JSON.stringify(obj));
}

export function setCurrentUserTickerVisible(tickerVisible: boolean): void {
  const user = sessionStorage.getItem(keyStorage.user);
  let obj = {};
  if (user) {
    obj = JSON.parse(user);
    obj.tickerVisible = tickerVisible;
  }

  return sessionStorage.setItem(keyStorage.user, JSON.stringify(obj));
}

export function logoutUser(): void {
  sessionStorage.clear();
}

export function setSessionInactivity(activity: boolean): void {
  const obj = { activity };
  return sessionStorage.setItem(keyStorage.activity, JSON.stringify(obj));
}

export function getSessionInactivity(): boolean {
  const activity = sessionStorage.getItem(keyStorage.activity);
  return activity ? JSON.parse(activity).activity : false;
}

export function setDisabledSessionInactivity(disabled: boolean): void {
  const obj = { disabled };
  return sessionStorage.setItem(keyStorage.disabled, JSON.stringify(obj));
}

export function getDisabledSessionInactivity(): boolean {
  const disabled = sessionStorage.getItem(keyStorage.disabled);
  return disabled ? JSON.parse(disabled).disabled : false;
}

export function getToken() {
  const token = localStorage.getItem(keyStorage.item);
  return token;
}

export function deleteToken() {
  localStorage.removeItem(keyStorage.item);
}

export function getConfirmationGeolocalization() {
  const confirmation = sessionStorage.getItem(
    keyStorage.confirmationGeolocalization
  );
  return confirmation;
}

export function deleteConfirmationGeolocalization() {
  sessionStorage.removeItem(keyStorage.confirmationGeolocalization);
}

export function getGeolocalization() {
  const geolocalization = sessionStorage.getItem(keyStorage.geolocalization);
  return geolocalization;
}

export function deleteGeolocalization() {
  sessionStorage.removeItem(keyStorage.geolocalization);
}
