import { MarketTypes } from "constants/globals";

export const API_LEVEL_1_INSTRUMENTS =
  "1/12576/0/ACK__42__,1/12576/0/ALFAA,1/12576/0/ALPEKA,1/12576/0/ALSEAK__42__,1/12576/0/AMXL,1/12576/0/ARAK__42__,1/12576/0/ASURB,1/12576/0/AXTELCPO,1/12576/0/AZTECACPO,1/12576/0/BIMBOA,1/12576/0/BOLSAA,1/12576/0/CEMEXCPO,1/12576/0/CHDRAUIB,1/12576/0/ELEKTRAK__42__,1/12576/0/FEMSAUBD,1/12576/0/GAPB,1/12576/0/GCARSOA1,1/12576/0/GENTERAK__42__,1/12576/0/GFINBURO,1/12576/0/GFNORTEO,1/12576/0/GMEXICOB,1/12576/0/GRUMAB,1/12576/0/HOMEXK__42__,1/12576/0/BACHOCOB,1/12576/0/IENOVAK__42__,1/12576/0/KIMBERA,1/12576/0/LABB,1/12576/0/LALAB,1/12576/0/LIVEPOLCK__45__1,1/12576/0/ALEATICK__42__,1/12576/0/MFRISCOAK__45__1,1/12576/0/NEMAKA,1/12576/0/ORBIAK__42__,1/12576/0/PEK__38__OLESK__42__,1/12576/0/PINFRAK__42__,1/12576/0/SORIANAB,1/12576/0/TLEVISACPO,1/12576/0/VITROA,1/12576/0/VOLARA,1/12576/0/WALMEXK__42__";

export const API_STOCKS_FIELDS =
  "descripcion,porcentaje,precioActual,precioAnterior,variacion,volumenTotal,volumenHecho,posturaPrecioCompra,posturaVolumenCompra,posturaPrecioVenta,posturaVolumenVenta,fechaPrecioAnterior,monitorFecha,hora,precioMaximoDia,precioMinimoDia,descripcionInstrumento,isin,numeroOperaciones,importePico,importeTotal,precioApertura,emisora,serie,posturaFecha,posturaHora";

export const API_RESUMEN_FIELDS =
  ",capitalizaciónMercado,valorEmpresa,rendimientoAnual,precioMaximo,precioMinimo,volumenPromedio3m,divYield,precioObjetivo,recomendación";

export const API_CURRENCIES_FIELDS =
  "IDInstrumento,precioActual,variacion,porcentaje,precioMaximoDia,precioMinimoDia,hora,fechaprecioactual,precioAnterior,fechaPrecioAnterior,posturaPrecioCompra,posturafechacompra,posturahoracompra,posturaPrecioVenta,posturafechaventa,posturahoraventa,descripcion";

export const API_INDEXES_FIELDS =
  "IDInstrumento,precioactual,variacion,porcentaje,precioMaximoDia,precioMinimoDia,hora,fechaprecioactual,precioanterior,fechaPrecioAnterior,posturaPrecioCompra,posturafechacompra,posturahoracompra,posturaPrecioVenta,posturafechaventa,posturahoraventa,descripcion";

export const API_MARKETS_TYPES = `${MarketTypes.BMV.id},${MarketTypes.BMV_SIC.id},${MarketTypes.BIVA.id},${MarketTypes.BIVA_SIC.id},${MarketTypes.CURRENCY.id},${MarketTypes.FIXED_INCOME.id},${MarketTypes.DERIVATIVES.id},${MarketTypes.INDEX.id},${MarketTypes.GENERAL_INDICATORS.id},${MarketTypes.NYSE_ONLY.id},${MarketTypes.NASDAQ.id},${MarketTypes.AMEX.id}`;

export const LS_LEVEL_1_INSTRUMENTS = [
  "1.12576.0.CEMEXCPO",
  "1.12576.0.ALFAA",
  "1.12576.0.AMXL",
  "1.12576.0.GMEXICOB",
  "1.12576.0.GFNORTEO",
  "1.12576.0.FEMSAUBD",
  "1.12576.0.BIMBOA",
  "1.12576.0.TLEVISACPO",
  "1.12576.0.ELEKTRA*",
  "1.12576.0.GRUMAB",
  "1.12576.0.MEXCHEM*",
  "1.12576.0.PE&OLES*",
  "1.12576.0.ASURB",
  "1.12576.0.KIMBERA",
  "1.12576.0.AC*",
  "1.12576.0.GAPB",
  "1.12576.0.LIVEPOLC-1",
  "1.12576.0.WALMEX*",
  "1.12576.0.LABB",
  "1.12576.0.BOLSAA",
  "1.12576.0.ALSEA*",
  "1.12576.0.ICA*",
  "1.12576.0.ALPEKA",
  "1.12576.0.OHLMEX*",
  "1.12576.0.LALAB",
  "1.12576.0.IENOVA*",
  "1.12576.0.KOFL",
  "1.12576.0.SORIANAB",
  "1.12576.0.GFINBURO",
  "1.12576.0.GCARSOA1",
  "1.12576.0.GENTERA*",
  "1.12576.0.PINFRA*",
  "1.12576.0.AXTELCPO",
  "1.12576.0.HOMEX*",
  "1.12576.0.ARA*",
  "1.12576.0.CHDRAUIB",
  "1.12576.0.MFRISCOA-1",
  "1.12576.0.NEMAKA",
  "1.12576.0.VITROA",
  "1.12576.0.VOLARA",
  "1.12576.0.AZTECACPO"
];

export const NEWS_PARAMS =
  'services=620,604,600,608,618,615,660,614,663&words=""&filter=0&tag=""&period=0&listType=9&sourcesId=1';
export const CONFIGURATION_MAX_CHARS = 3999;
