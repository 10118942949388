// @flow

const ACTION_TYPES = {
  START_LOADING: "START_LOADING",
  STOP_LOADING: "STOP_LOADING",
  SET_NEWS_DETAILS_SUCCESS: "SET_NEWS_DETAILS_SUCCESS",
  SET_NEWS_DETAILS_FAILED: "SET_NEWS_DETAILS_FAILED",
  GET_NEWS_DETAILS_SUCCESS: "GET_NEWS_DETAILS_SUCCESS",
  GET_NEWS_DETAILS_FAILED: "GET_NEWS_DETAILS_FAILED"
};

export default ACTION_TYPES;
