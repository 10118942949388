import React, { useEffect, Suspense, lazy } from "react";
import { Router, Route, Switch } from "react-router-dom";
import numbro from "numbro";
import ReactGA from "react-ga4";
import FullStory from "react-fullstory";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import Layout from "components/Layout";

// $FlowFixMe
import useSettings from "hooks/useSettings";
// $FlowFixMe
import createTheme from "theme";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components";
import history from "./utils/browserHistory";

numbro.setDefaults({
  roundingFunction: Math.floor
});

const LoginContainer = lazy(() => import("components/Login"));
const LogoutContainer = lazy(() => import("components/Logout"));

function App() {
  const { settings } = useSettings();
  const theme = createTheme(settings);

  useEffect(() => {
    const isGoogleAnalyticsEnabled =
      process.env.REACT_APP_GOOGLE_ANALYTICS_ENABLED === "true";

    if (isGoogleAnalyticsEnabled) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
      ReactGA.pageview(window.location.pathname);
    }
  }, []);
  const isFullStoryEnabled =
    process.env.REACT_APP_FULL_STORY_ENABLED === "true";
  const fullStoryORG = process.env.REACT_APP_FULL_STORY_ORG;

  if (process.env.REACT_APP_SENTRY_ENABLED === "true") {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_KEY,
      environment: process.env.REACT_APP_ENVIRONMENT,
      release: process.env.REACT_APP_VERSION_FE,
      integrations: [new Integrations.BrowserTracing()],
      ignoreErrors: [
        "Non-Error promise rejection captured",
        "Cannot read properties of null (reading 'height')"
      ],
      tracesSampleRate: 0.25
    });
  }

  return (
    // history is not compactible with router
    // $FlowFixMe
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <Suspense fallback={<div></div>}>
            <Switch>
              <Route exact path="/login" component={LoginContainer} />
              <Route exact path="/logout" component={LogoutContainer} />
              <Route path="/" render={props => <Layout {...props} />} />
            </Switch>
          </Suspense>
          {isFullStoryEnabled ? <FullStory org={fullStoryORG} /> : null}
        </Router>
      </ThemeProvider>
    </MuiThemeProvider>
  );
}

export default App;
