import React, { useEffect, useState } from "react";
import { Router } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import AccountApi from "apis/accountApi";
import {
  logoutUser,
  setSessionInactivity,
  getSessionInactivity,
  getDisabledSessionInactivity
} from "utils/user";
import {
  SESSION_BASE_SECOND,
  SESSION_TIMEOUT,
  SESSION_TIMEOUT_PROMPT,
  SESSION_TIMEOUT_REMAINING_SEG
} from "constants/globals";
import history from "utils/browserHistory";
import IdleTimeOutModal from "./IdleModal";

type Props = {
  children: any
};

const LayoutContainer = ({ children }: Props) => {
  const [showModal, setShowModal] = useState(() => getSessionInactivity());
  const [remaining, setRemaining] = useState(0);

  const onPrompt = () => {
    setShowModal(true);
    setRemaining(SESSION_TIMEOUT_PROMPT);
  };

  const onIdle = () => {
    if (!getDisabledSessionInactivity()) {
      setSessionInactivity(true);
      setRemaining(0);
      history.push("/logout");
    }
  };

  const { getRemainingTime, isPrompted, activate } = useIdleTimer({
    element: document,
    onPrompt,
    onIdle,
    debounce: 250,
    promptTimeout: SESSION_TIMEOUT_PROMPT,
    timeout: SESSION_TIMEOUT
  });

  const hanlderContinueSession = () => {
    setShowModal(false);
    activate();
  };

  const handleLogout = () => {
    AccountApi.logout()
      .then(() => {
        setShowModal(false);
        logoutUser();
        window.location.href = process.env.REACT_APP_BANORTE_REDIRECT_URL;
      })
      .catch(() => {});
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / SESSION_BASE_SECOND));
      }
    }, SESSION_BASE_SECOND);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  return (
    <Router history={history}>
      {children}
      <IdleTimeOutModal
        isPrompted={isPrompted()}
        isCounter={remaining <= SESSION_TIMEOUT_REMAINING_SEG}
        remaining={remaining}
        showModal={showModal}
        handleLogout={handleLogout}
        hanlderContinueSession={hanlderContinueSession}
      />
    </Router>
  );
};

export default LayoutContainer;
