import type { ComponentType } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import {
  ALMOST_WHITE,
  SHARK,
  ABBEY,
  MAUVE,
  PALE_BLUE,
  BLACK,
  WHITE_SMOKE,
  PATTENS_BLUE
} from "constants/colors";

export const FloatingModal: ComponentType<{
  open: boolean
}> = styled.div`
  background-color: ${SHARK};
  width: 500px;
  opacity: ${props => (props.open ? "1" : "0")};
  z-index: 5;
  border-radius: 20px;
  transition: all 400ms;
  position: fixed;
  left: 50%;
  top: 20px;
  padding: 26px 16px;
  transform: translate(-50%, 0);
`;

export const SidebarBody: ComponentType<any> = styled.div`
  position: relative;
`;

export const SidebarHeaderButtons: ComponentType<any> = styled.div`
  top: 0;
  right: 0;
  position: absolute;
  display: flex;
  cursor: pointer;
  z-index: 4;
  &:hover {
    filter: brightness(150%);
  }
`;

export const Title: ComponentType<any> = styled.p`
  position: relative;
  margin-top: 24px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin: 0;
  color: ${WHITE_SMOKE};
`;
export const Subtitle: ComponentType<{}> = styled.p`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: ${PATTENS_BLUE};
`;

export const Counter: ComponentType<{}> = styled.span`
  font-size: 24px;
  line-height: 29px;
`;

export const ContainerCounter: ComponentType<{}> = styled.p`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: ${PATTENS_BLUE};
  text-align: center;
`;

export const AddCard: ComponentType<any> = styled.div`
  display: flex;
  padding: 0 4px 25px 24px;
`;

export const ImageButton: ComponentType<any> = styled.button`
  background-color: ${SHARK};
  cursor: pointer;
  border: 1px solid ${ABBEY};
  border-radius: 20px;
  overflow: hidden;
  padding: 0;
  margin-right: 20px;
  width: 173px;
  &:hover,
  &:focus {
    border-color: ${MAUVE};
    border-radius: 20px;
    overflow: hidden;
    background-color: ${SHARK};
  }
`;

export const MenuContainer: ComponentType<{}> = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: space-between;
  height: 220px;
  overflow-y: auto;
  padding: 0 55px 55px 55px;
`;

export const Backdrop: ComponentType<{}> = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  bottom: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 4;
  left: 0px;
`;

export const BackButton: ComponentType<{}> = styled.button`
  background-color: transparent;
  border: solid 1px transparent;
  cursor: pointer;
  padding-left: 0;
  padding-right: 1px;
  margin-left: -12px;
  vertical-align: middle;
  &:hover {
    filter: brightness(150%);
  }
`;

export const ButtonConfirm: ComponentType<any> = styled.button`
  border: 1px solid ${ALMOST_WHITE};
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  height: 37px;
  display: ${props => (props.hide ? `none` : `flex`)};
  flex-direction: row;
  align-items: center;
  color: ${BLACK};
  background-color: ${PALE_BLUE};
  padding: 9px 10px;
  ${props => (props.disabled ? `opacity: 0.4` : null)};
  &:focus {
    background-color: ${PALE_BLUE};
    border: 1px solid transparent;
    border-radius: 8px;
    cursor: pointer;
    outline: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${BLACK};
  }
  &:hover {
    filter: brightness(100%);
  }
  & p {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.25px;
    width: 100%;
  }
`;

export const ContainerButtons: ComponentType<{}> = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 8px;
  text-align: center;
  margin-top: 26px;
`;

export const useStyles = makeStyles({
  closeIcon: {
    height: 31,
    width: 31,
    top: "4px",
    cursor: "pointer"
  }
});
