// @flow

import ACTION_TYPES from "./actionTypes";
import type { NewsReducer } from "./types";

const initialState = {
  news: null,
  newsDetails: null,
  loader: false,
  sectors: "",
  startDate: null,
  endDate: null,
  page: 1,
  noMorePagination: false
};

const reducer: NewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_NEWS_SUCCESS:
      return {
        ...state,
        news: action.news,
        sectors: action.sectors,
        startDate: action.startDate,
        endDate: action.endDate,
        page: action.page,
        noMorePagination: false
      };
    case ACTION_TYPES.GET_NEWS_FAILED:
      return {
        ...state,
        news: []
      };
    case ACTION_TYPES.LOADER:
      return {
        ...state,
        loader: action.loader
      };
    case ACTION_TYPES.GET_NOT_NEWS_PAGINATION:
      return {
        ...state,
        noMorePagination: action.noMorePagination
      };
    default:
      return state;
  }
};

export default reducer;
