/* eslint-disable prefer-promise-reject-errors */
// @flow

import { HttpStatusCode, ErrorMessage } from "../constants/globals";
import {
  setCurrentUser,
  getCurrentUser,
  logoutUser,
  getSessionInactivity
} from "./user";
import { getApiWorkspaceUrl } from "./endpoints";
import history from "./browserHistory";

const PENDING_REQUESTS_DELAY = 100;

let tokenExpired = false;

const ApiUrl = getApiWorkspaceUrl();

const intervals = [];

function checkStatus(response: Response) {
  if (
    (response.status >= HttpStatusCode.OK &&
      response.status < HttpStatusCode.MultipleChoices) ||
    response.status === HttpStatusCode.Unauthorized
  ) {
    return response;
  }

  const error: Error & { response?: Response, status?: number } = new Error(
    response.statusText
  );

  error.response = response;
  error.status = response.status;

  throw error;
}

// noinspection JSUnusedGlobalSymbols
export function genericResponseHandler(): Promise<any> {
  // $FlowFixMe
  if (navigator.online) {
    return Promise.reject({ message: ErrorMessage.Default });
  }
  return Promise.reject({ message: ErrorMessage.NoConection });
}

function parseJSON(response: Response): Promise<any> {
  return response.text().then(text => (text ? JSON.parse(text) : {}));
}

/**
 * I removed api logout api call since at this point access and refresh token are invalid, so request will fail
 * It is enough to remove user from local storage and redirect to login page.
 */
function logoutUserInternal() {
  logoutUser();
  if (!window.location.href.includes("logout")) {
    history.push("/login");
  }
}

function retryRequest(url: string, options: Object): Promise<any> {
  return fetch(url, options)
    .then(checkStatus)
    .catch(error => {
      if (error.status === HttpStatusCode.Unauthorized) {
        if (!error.response.url.includes("access/decode/token")) {
          logoutUserInternal();
        }
      }
      if (error.status === HttpStatusCode.Unauthorized) {
        if (!error.response.url.includes("access/decode/token")) {
          logoutUserInternal();
        }
      }

      if (!error.response) {
        return genericResponseHandler();
      }

      return parseJSON(error.response)
        .then(data => Promise.reject(data))
        .catch(message => {
          if (message && message.error) {
            return Promise.reject(message.error);
          }

          if (message && (message.nonFieldErrors || message.detail)) {
            return Promise.reject(message);
          }

          if (message && message.error) {
            return Promise.reject(message);
          }

          const newMessage = ErrorMessage[error.status] || ErrorMessage.Default;

          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject({ nonFieldErrors: newMessage });
        });
    });
}

async function isUnauthorized(
  response: Response,
  url: string,
  options: Object
) {
  if (response.status === HttpStatusCode.Unauthorized) {
    const user = getCurrentUser();
    // eslint-disable-next-line no-param-reassign
    options.headers.Authorization = `${"Bearer "}${
      user ? user.accessToken : ""
    }`;
    // eslint-disable-next-line no-return-await
    return await retryRequest(url, options);
  }

  return response;
}

function request(url: string, options: Object): Promise<any> {
  return fetch(url, options)
    .then(checkStatus)
    .then(urlRequest => {
      return isUnauthorized(urlRequest, url, options);
    })
    .then(parseJSON)
    .then(data => data)
    .catch(error => {
      if (error.status === HttpStatusCode.Unauthorized) {
        if (!error.response.url.includes("access/decode/token")) {
          logoutUserInternal();
        }
      }

      if (!error.response) {
        return genericResponseHandler();
      }

      return parseJSON(error.response)
        .then(data => Promise.reject(data))
        .catch(message => {
          if (message && message.error) {
            return Promise.reject(message.error);
          }

          if (message && (message.nonFieldErrors || message.detail)) {
            return Promise.reject(message);
          }

          if (message && message.error) {
            return Promise.reject(message);
          }

          const newMessage = ErrorMessage[error.status] || ErrorMessage.Default;

          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject({ nonFieldErrors: newMessage });
        });
    });
}

export function getAccess(
  url: string,
  body: Object,
  newUrl?: string
): Promise<any> {
  const fullUrl = newUrl
    ? newUrl + url
    : String(process.env.REACT_APP_SSO_API_URL) + url;
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ token: body })
  };
  return request(fullUrl, options);
}

export function getToken(
  url: string,
  body: Object,
  newUrl?: string
): Promise<any> {
  const fullUrl = newUrl ? newUrl + url : String(ApiUrl) + url;
  const options = !body.refresh
    ? {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      }
    : {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${body.refresh}`
        }
      };
  return request(fullUrl, options);
}

export function getSSOToken(url: string, token: string): Promise<any> {
  const fullUrl = String(ApiUrl) + url;
  if (getSessionInactivity()) {
    return Promise.resolve();
  }

  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${"Bearer "}${token}`
    }
  };
  return request(fullUrl, options);
}

export function getMarketData(url: string): Promise<any> {
  const fullUrl = String(process.env.REACT_APP_MARKET_APP_API_URL) + url;
  const user = getCurrentUser();
  if (getSessionInactivity()) {
    return Promise.resolve();
  }

  if (!user) {
    if (!window.location.href.includes("logout")) {
      history.push("/login");
    }
    return Promise.resolve();
  }
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${"Bearer "}${user ? user.accessToken : ""}`,
      Accept: `application/json;`
    }
  };
  return request(fullUrl, options);
}

export function getManagementData(type: string, url: string): Promise<any> {
  const fullUrl = String(process.env.REACT_APP_MANAGEMENT_APP_API_URL) + url;
  if (getSessionInactivity()) {
    return Promise.resolve();
  }

  const user = getCurrentUser();
  const options = {
    method: type,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${"Bearer "}${user ? user.accessToken : ""}`,
      Accept: `application/json;`
    }
  };
  return request(fullUrl, options);
}

export function getMockMarketData(url: string): Promise<any> {
  const fullUrl = String(process.env.REACT_APP_MARKET_MOCK_APP_API_URL) + url;

  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  };
  return request(fullUrl, options);
}

// noinspection JSUnusedGlobalSymbols
export function verifyToken(url: string): Promise<any> {
  const fullUrl = String(ApiUrl) + url;
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  };

  return request(fullUrl, options);
}

/**
 * Obtains new access token and executes the same request with new access token
 * In case that refresh token is expired and getToken returns error user is logged out
 * @param fullUrl
 * @param options
 * @returns {*}
 */
function obtainNewAccessToken(fullUrl, options): Promise<any> {
  const user = getCurrentUser();

  const token = {
    refresh: user ? user.refreshToken : ""
  };
  const newUser = {
    ...user
  };

  return getToken("/refreshToken", token)
    .then(response => {
      const accessToken = response.data.token;
      // eslint-disable-next-line
      options.headers.Authorization = `${"Bearer "}${accessToken}`;
      newUser.token = accessToken;
      sessionStorage.setItem("user", JSON.stringify(newUser));
      tokenExpired = false;
      setCurrentUser(response.data, accessToken);
      return request(fullUrl, options);
    })
    .catch(() => {
      tokenExpired = false;
      // Clear intervals in case that refresh token is expired, so requests won't be sent.
      if (intervals.length) {
        intervals.forEach(interval => clearInterval(interval));
      }

      logoutUserInternal();
    });
}

export function refreshToken(): Promise<any> {
  const user = getCurrentUser();

  const token = {
    refresh: user ? user.refreshToken : ""
  };
  const newUser = {
    ...user
  };

  return getToken("/refreshToken", token)
    .then(response => {
      const accessToken = response.data.token;
      // eslint-disable-next-line
      newUser.token = accessToken;
      sessionStorage.setItem("user", JSON.stringify(newUser));
      tokenExpired = false;
      setCurrentUser(response.data, accessToken);
    })
    .catch(() => {
      tokenExpired = false;
      // Clear intervals in case that refresh token is expired, so requests won't be sent.
      if (intervals.length) {
        intervals.forEach(interval => clearInterval(interval));
      }

      logoutUserInternal();
    });
}

export function checkHAService(): Promise<any> {
  const user = getCurrentUser();
  const fullUrl = `${String(
    process.env.REACT_APP_WORKSPACE_URL
  )}/load_balancers/pools`;
  const options = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `${"Bearer "}${user ? user.accessToken : ""}`,
      Accept: `application/json;`
    }
  };
  return request(fullUrl, options);
}

export function fetchNext(
  type: string,
  url: string,
  bodyData?: Object,
  host?: string
): Promise<any> {
  let user = getCurrentUser();
  let fullUrl = "";

  if (getSessionInactivity()) {
    return Promise.resolve();
  }

  if (!user) {
    if (!window.location.href.includes("logout")) {
      history.push("/login");
    }
    return Promise.resolve();
  }

  if (host) {
    fullUrl = host + url;
  } else {
    fullUrl = ApiUrl
      ? ApiUrl + url
      : String(process.env.REACT_APP_WORKSPACE_URL) + url;
  }

  if (type === "GET" && fullUrl.includes("PE&OLES*")) {
    fullUrl = fullUrl.replace("PE&OLES*", "PE%26OLES*");
  }

  let options = {
    method: type,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${"Bearer "}${user ? user.accessToken : ""}`,
      Accept: `application/json;`
    }
  };

  if (bodyData && type !== "GET") {
    options = { ...options, body: JSON.stringify(bodyData) };
  }

  let currentTime = Date.now() / 1000;

  // Checks if access token expiration time is bigger then current time
  if (user && currentTime < user.exp) {
    return request(fullUrl, options);
  }

  // Sets interval for all request that has invalid access token. Once refresh token is completed, repeat the same
  // request with new access token
  if (tokenExpired) {
    return new Promise(resolve => {
      const interval = setInterval(() => {
        currentTime = Date.now() / 1000;
        user = getCurrentUser();
        if (user && currentTime < user.exp) {
          options.headers.Authorization = `${"Bearer "}${user.accessToken}`;
          clearInterval(interval);
          resolve(request(fullUrl, options));
        }
      }, PENDING_REQUESTS_DELAY);
      // Adding each interval to intervals list so we can clear it when user is logged out.
      intervals.push(interval);
    });
  }
  tokenExpired = true;
  return obtainNewAccessToken(fullUrl, options);
}
