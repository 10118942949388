export const title =
  "Por disposición regulatoria, necesitamos conocer su geolocalización";

export const paragraph1 =
  "Por disposición de las autoridades, a partir del 23 de julio de 2021 necesitamos conocer y resguardar la geolocalización del dispositivo desde el que realiza sus operaciones.";

export const paragraph2 =
  "Sólo así podrá seguir disfrutando de todos los servicios que ofrece BeTrading para la operación de instrumentos en el mercado de capitales y fondos de inversión.";

export const paragraph3 =
  "Todos sus datos serán tratados conforme a nuestro aviso de privacidad. Por tal motivo su información está completamente segura.";

export const paragraph4 =
  "Si tiene cualquier duda o necesita información adicional, favor de contactar a su asesor o a través del correo electrónico betrading@banorte.com";
